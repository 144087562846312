import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import { GetReferralById } from "../services/referral-service";
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import { Grid, MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import AutoCompleteTextField from "../components/AutoComplete";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import {SeverityLevel} from '@microsoft/applicationinsights-web';

const ReferralInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [referralId, setReferralId] = useState(props.referralId);
    const [clientFileId, setClientFileId] = useState(props.clientFileId);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketNumberError, setDocketNumberError] = useState(false);
    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [showDate, setShowDate] = useState(dayjs(''));
    const [upcomingCourtDateError, setUpcomingCourtDateError] = useState(false);
    const [isUpcomingCourtDateInvalid, setIsUpcomingCourtDateInvalid] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [arryOfInputValues, setArryOfInputValues] = useState([]);
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [dutyCounselType, setDutyCounselType] = useState(0);
    const [dutyCounselTypeError, setDutyCounselTypeError] = useState(false);
    const [createdOn, setCreatedOn] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Referral Info : ", trackEvents);

    useImperativeHandle(ref, () => ({
        getReferralInfo() {
            if (!docketNumber) { setDocketNumberError(true); }
            if (!upcomingCourtDate) { setUpcomingCourtDateError(true);  }
            if (!dutyCounselType) { setDutyCounselTypeError(true); }
            if (!(courtLocation && courtLocation.length > 0)) { setCourtLocationError(true); }
            return {
                "referralId": referralId,
                "clientFile": {
                    "clientFileId": clientFileId
                },
                "docketNumber": docketNumber,
                "upcomingCourtDate": new Date(upcomingCourtDate),
                "courtLocation": courtLocation,
                "dutyCounselType": {
                    "dutyCounselTypeId": dutyCounselType.dutyCounselTypeId,
                    "dutyCounselTypeCrmValue": 0,
                    "label": "",
                },
                "docketNumberValidity": (!docketNumber) ? true : docketNumberError,
                "upcomingCourtDateValidity": (!upcomingCourtDate) ? true : (upcomingCourtDateError || isUpcomingCourtDateInvalid),
                "courtLocationValidity":(!(courtLocation && courtLocation.length > 0)) ? true : courtLocationError,
                "dutyCounselTypeValidity": (!dutyCounselType) ? true : dutyCounselTypeError,
                "createdOn" : createdOn && createdOn != "" ? new Date(createdOn) : new Date(),
                "createdBy" : createdBy && createdBy != "" ? createdBy : "System",
            };
        }
    }));

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

    useEffect(() => {

        const FetchData = async (token) => {

            var data = await GetReferralById(props.referralId, token);
            if (data) {
                setReferralId(data.referralId);
                setDocketNumber(data.docketNumber);
                setUpcomingCourtDate(Date.parse(data.upcomingCourtDate));
                setShowDate(data.upcomingCourtDate != null ? new Date(data.upcomingCourtDate).toLocaleDateString() : "");
                setDutyCounselType(data.dutyCounselType);
                setCourtLocation(data.courtLocations);
                setCreatedOn(data.createdOn);         
                setCreatedBy(data.createdBy); 
                if (data.courtLocations){
                    const fecthInitialCourtLocations = [];
                   data.courtLocations.forEach((courtLocation) => {
                        fecthInitialCourtLocations.push(courtLocation.courtLocation.label);
                    });                     
                    setArryOfInputValues(fecthInitialCourtLocations);
                }
                setTrackEvents(JSON.stringify(data));
                appInsights.trackTrace({ message: JSON.stringify(data), severityLevel: SeverityLevel.Information });
            }
        }

        if (props.clientFileId && props.referralId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);

    const onDocketNumberChanged = (event) => {
        setDocketNumber(event.target.value);
        if (event.target.value.length == 0) {
            setDocketNumber(EMPTYSTRING);
            setDocketNumberError(true);
        }
        else {
            const fieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true, maxLength: 100 };
            const status = isValid(fieldModal);
            setDocketNumberError(!status);
        }
    };

    const onUpcomingCourtDateChanged = (date) => {
        setShowDate(date);
        if (date != null) {
            let today = new Date();
            if((new Date(date)).toString() === "Invalid Date" || (new Date(date) < new Date(today.setDate(today.getDate() -1)))){
                setIsUpcomingCourtDateInvalid(true);
            } else {
                setUpcomingCourtDate(Date.parse(date));           
                setUpcomingCourtDateError(false);
                setIsUpcomingCourtDateInvalid(false);
            }
        } else {
            setUpcomingCourtDate(date);
            setUpcomingCourtDateError(true);
            setIsUpcomingCourtDateInvalid(false);
        }
    };

    const onDutyCounselTypeChanged = (event) => {
        const selectedDutyCounselType = props.dutyCounselTypes.find(
            (object) => object?.label === event.target.value
        );
        setDutyCounselType(selectedDutyCounselType);
        if (selectedDutyCounselType)
            setDutyCounselTypeError(false);
    };   

    const courtLocations = props.courtLocations;

   
    const onCourtLocationsChanged=(event, newInputValues) => {
        setArryOfInputValues(newInputValues);
        const newArrayOfObjects= [];
        newInputValues.forEach( (InputValue) => {
            let seletcedOption = courtLocations.find((option) => option?.label == InputValue)
            newArrayOfObjects.push(seletcedOption);
        })
        setCourtLocation(newArrayOfObjects);
        if (newArrayOfObjects && newArrayOfObjects.length > 0 ){setCourtLocationError(false);} else {setCourtLocationError(true);};
        
    };

    function isInThePast(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    };

    return (<>
    <Grid container style={{ padding: "10px 20px 10px 20px", border: "1px solid #7F8284", margin: "10px 0px 0px 0px", borderRadius: "8px", backgroundColor: !props.isReadOnly ? "#ececec" : "" }}>
        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                        }}>Referral Information</Grid>
                         <Grid item xs={12}  sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <div style={{maxWidth: "275px"}}>
                            <TextField label="Docket Number(s)" placeholder="Docket Number" required={true}
                                multiline disabled={!props.isReadOnly}
                                error={docketNumberError ? true : null}
                                value={docketNumber}
                                handleChange={onDocketNumberChanged}
                                helperText={docketNumberError ? Constants.DOCKETNUMBERREQUIREDMESSAGE : null} /></div>
                        </Grid>
                         <Grid item xs={12} sm={6} md={4} lg={4} style={{margin: "10px 0px 10px 0px"}}>
                         <div style={{maxWidth: "275px"}}>
                             <CustomDatePicker label="Upcoming Court Date" placeholder="Upcoming Court Date" required={true} disablePast={true}
                                disabled={!props.isReadOnly}
                                error={isUpcomingCourtDateInvalid ? true : (upcomingCourtDateError ? true : null)}
                                value={showDate} 
                                handleOnChange={onUpcomingCourtDateChanged}
                                helperText={isUpcomingCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (upcomingCourtDateError ? Constants.UPCOMINGCOURTDATEREQUIREDMESSAGE : null)} />
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Duty Counsel Type" placeholder="Duty Counsel Type" required={true}
                                select disabled={!props.isReadOnly}
                                error={dutyCounselTypeError ? true : null}
                                value={dutyCounselType ? dutyCounselType?.label : ""}
                                handleChange={onDutyCounselTypeChanged}
                                helperText={dutyCounselTypeError ? Constants.DUTYCONSELTYPEREQUIREDMESSAGE : null}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                {props.dutyCounselTypes.map((option) => (
                                    <MenuItem key={option.dutyCounselTypeId} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <AutoCompleteTextField label="Court Location(s)" required={true} options={courtLocations} 
                                    multiple={true} disabled={!props.isReadOnly}
                                    value={arryOfInputValues} 
                                    onChange={onCourtLocationsChanged} 
                                    error={courtLocationError? true : null}
                                    helperText={courtLocationError ? Constants.COURTLOCATIONREQUIREDMESSAGE :  null}/>
                        </Grid>
    </Grid>
        </>
    );
})

export default ReferralInfo;