import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import * as Constants from "../helper/constants";

export function useNavigationParameters() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [backPage, setBackPage] = useState(searchParams.get(Constants.PAGEQUERYSTRINGPARAM));
    const [topmyfilesduedate, setTopmyfilesduedate] = useState(searchParams.get(Constants.TOPMYFILESDUEDATEQUERYSTRINGPARAM));
    const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get(Constants.TOPFILESTOBEASSIGNEDDUEDATEQUERYSTRINGPARAM));
    const [topassignedfilesduedate, setTopassignedfilesduedate] = useState(searchParams.get(Constants.TOASSIGNEDFILESDUEDATEQUERYSTRINGPARAM));
    const [clientFileId, setClientFileId] = useState(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM));
    const [simpleClientFileId, setSimpleClientFileId] = useState(searchParams.get(Constants.SIMPLECLIENTFILEIDQUERYSTRINGPARAM));
    const [id, setId] = useState(searchParams.get(Constants.IDQUERYSTRINGPARAM));

    const [interactionId, setInteractionId] = useState(searchParams.get(Constants.INTERACTIONIDQUERYSTRINGPARAM));
    const [relatedId, setRelatedId] = useState(searchParams.get(Constants.RELATEDIDQUERYSTRINGPARAM));
    const [clientGatewayId, setClientGatewayId] = useState(searchParams.get(Constants.CLIENTGATEWAYIDQUERYSTRINGPARAM));
    const [agencyRequestId, setAgencyRequestId] = useState(searchParams.get(Constants.AGENCYREQUESTIDQUERYSTRINGPARAM));
    const [noteTypeId, setNoteTypeId] = useState(searchParams.get(Constants.NOTETYPEIDQUERYSTRINGPARAM));

    const location = useLocation();

    useEffect(() => {
    }, []);

    const prepareNavigationParameters = (key, parameters, option, additionalParams) => {
        var pageUrl = "";
        var appendedPageUrl = "";
        var data = "";
        var data2 = "";
        if (key == '' || key == null) key = backPage;
        switch (key) {
            //Level 1
            case Constants.TOPMYFILES:
                pageUrl = (`${Constants.DASHBOARDPAGEURL}?cardKey=1&topmyfilesduedate=${topmyfilesduedate}`);
                return pageUrl;
            case Constants.TOPFILESTOASSIGN:
                pageUrl = (`${Constants.DASHBOARDPAGEURL}?cardKey=2&topfilestobeassignedduedate=${topfilestobeassignedduedate}`);
                return pageUrl;
            case Constants.TOPASSIGNEDFILES:
                pageUrl = (`${Constants.DASHBOARDPAGEURL}?cardKey=3&topassignedfilesduedate=${topassignedfilesduedate}`);
                return pageUrl;
            case Constants.SEARCHCLIENTS:
                if (parameters && parameters != null && parameters.length > 0) {
                    data = parameters[0];
                }
                else
                    data = '';

                pageUrl = (`${Constants.SEARCHRESULTSPAGEURL}?${data}`);
                appendedPageUrl = appendExistingNavigationParameters(pageUrl)


                var finalUrl = appendedPageUrl;
                var splitFromStartParam = appendedPageUrl.split("?");
                if (splitFromStartParam.length > 0) {

                    var finalUrl = splitFromStartParam[0];

                    var parameterList = splitFromStartParam[1].split("&");

                    if (parameterList.length > 0) {
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.CLIENTFILEIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== "backto"
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.CRMIDQUERYSTRINGPARAM
                        })                       
                    }
                    var count = 0;
                    parameterList.forEach(element => {
                        if (element != '') {
                            if (count == 0) {
                                finalUrl = finalUrl + "?" + element;
                                count++;
                            }
                            else {
                                finalUrl = finalUrl + "&" + element;
                            }
                        }

                    });
                }

                return finalUrl;
            case Constants.TOPDCPREVIEWDASHBOARD:
                pageUrl = (`${Constants.REVIEWDASBOARDPAGEURL}?cardKey=1`);
                return pageUrl;
            case Constants.TOPCRMREVIEWDASHBOARD:
                pageUrl = (`${Constants.REVIEWDASBOARDPAGEURL}?cardKey=2`);
                return pageUrl;
            case Constants.TOPINTREVIEWDASHBOARD:
                pageUrl = (`${Constants.REVIEWDASBOARDPAGEURL}?cardKey=3`);
                return pageUrl;

            //Level 2
            case Constants.MYFILES: //done

                if (parameters && parameters != null && parameters.length > 0) {
                    data = parameters[0];
                }
                else
                    data = topmyfilesduedate;

                pageUrl = (`${Constants.MYFILESPAGEURL}?cardKey=1&page=${Constants.TOPMYFILES}&topmyfilesduedate=${data}`);
                //appendedPageUrl = appendExistingNavigationParameters(pageUrl)
                return pageUrl;
            case Constants.FILESTOASSIGN:

                if (parameters && parameters != null && parameters.length > 0) {
                    data = parameters[0];
                }
                else
                    data = topfilestobeassignedduedate;

                pageUrl = (`${Constants.FILESTOBEASSIGNEDPAGEURL}?cardKey=2&page=${Constants.TOPFILESTOASSIGN}&topfilestobeassignedduedate=${data}`);
                //appendedPageUrl = appendExistingNavigationParameters(pageUrl)
                return pageUrl;
            case Constants.ASSIGNEDFILES:

                if (parameters && parameters != null && parameters.length > 0) {
                    data = parameters[0];
                }
                else
                    data = topassignedfilesduedate;

                pageUrl = (`${Constants.ASSIGNEDFILESPAGEURL}?cardKey=3&page=${Constants.TOPASSIGNEDFILES}&topassignedfilesduedate=${data}`);
                //appendedPageUrl = appendExistingNavigationParameters(pageUrl)
                return pageUrl;
            case Constants.DCPREVIEWDASHBOARD:
                pageUrl = (`${Constants.DCPCONFLICTEDCLIENTFILESPAGEURL}?page=${Constants.TOPDCPREVIEWDASHBOARD}`);
                return pageUrl;
            case Constants.CRMREVIEWDASHBOARD:
                pageUrl = (`${Constants.CRMCONFLICTDASHBOARDPAGEURL}?page=${Constants.TOPCRMREVIEWDASHBOARD}`);
                appendedPageUrl = appendExistingNavigationParameters(pageUrl)
                return appendedPageUrl;
            case Constants.INTREVIEWDASHBOARD:
                pageUrl = (`${Constants.INERACTIONCCONFLICTEDFILESPAGEURL}?page=${Constants.TOPINTREVIEWDASHBOARD}`);
                appendedPageUrl = appendExistingNavigationParameters(pageUrl)
                return appendedPageUrl;

            //Level 3
            case Constants.CLIENTFILEDASHBOARD:
                pageUrl = (`${Constants.CLIENTFILEDASHBOARDPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${parameters[0]}`);
                }
                else if (clientFileId != null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                var tempUrl;
                if (location.pathname == "/clientfiles/search") {
                    if (additionalParams)
                        tempUrl = pageUrl + "&" + additionalParams;
                    else
                        tempUrl = pageUrl;
                }
                else {
                    if (additionalParams) {
                        appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams);

                        tempUrl = appendedPageUrl;
                    }
                    else {
                        tempUrl = appendExistingNavigationParameters(pageUrl);
                    }
                }

                var finalUrl = tempUrl;
                var splitFromStartParam = tempUrl.split("?");
                if (splitFromStartParam.length > 0) {

                    var finalUrl = splitFromStartParam[0];

                    var parameterList = splitFromStartParam[1].split("&");

                    if (parameterList.length > 0) {
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.AGENCYREQUESTIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.CLIENTGATEWAYIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.NOTETYPEIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.RELATEDIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.IDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== "backto"
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.JOBAILOUTCOMEIDQUERYSTRINGPARAM
                        })
                    }
                    var count = 0;
                    parameterList.forEach(element => {
                        if (element != '') {
                            if (count == 0) {
                                finalUrl = finalUrl + "?" + element;
                                count++;
                            }
                            else {
                                finalUrl = finalUrl + "&" + element;
                            }
                        }

                    });
                }
                //remove unwanted params
                //agencyrequestid
                //clientGatewayId
                //noteTypeId=2&relatedId=292&&id=176
                //referal create new - error messages shown
                return finalUrl;
            case Constants.RESOLVEDCPCONFLICTS:
                if (parameters && parameters != null && parameters.length > 0) {
                    pageUrl = (`${Constants.DCPCONFLICTRESOLUTIONPAGEURL}?${Constants.SIMPLECLIENTFILEIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else
                    pageUrl = (`${Constants.DCPCONFLICTRESOLUTIONPAGEURL}?${Constants.SIMPLECLIENTFILEIDQUERYSTRINGPARAM}=${simpleClientFileId.toString().replaceAll(',','')}`);

                return pageUrl;
            case Constants.RESOLVECRMCONFLICT:
                if (parameters && parameters != null && parameters.length > 0) {
                    pageUrl = (`${Constants.CRMCONFLICTRESOLUTIONPAGEURL}?${Constants.SIMPLECLIENTFILEIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else
                    pageUrl = (`${Constants.CRMCONFLICTRESOLUTIONPAGEURL}?${Constants.SIMPLECLIENTFILEIDQUERYSTRINGPARAM}=${simpleClientFileId.toString().replaceAll(',','')}`);

                return pageUrl;
            case Constants.RESOLVEINTERACTIONCONFLICT:
                if (parameters && parameters != null && parameters.length > 0) {
                    pageUrl = (`${Constants.INERACTIONCONFLICTRESOLUTIONPAGEURL}?${Constants.INTERACTIONIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else
                    pageUrl = (`${Constants.INERACTIONCONFLICTRESOLUTIONPAGEURL}?${Constants.INTERACTIONIDQUERYSTRINGPARAM}=${interactionId.toString().replaceAll(',','')}`);

                return pageUrl;

            //Level 4

            case Constants.CLIENTFILEVIEWDETAILS:
                pageUrl = (`${Constants.CLIENTFILEVIEWDETAILSPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (clientFileId != null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
               

                var tempUrl;
                if (location.pathname == "/clientfiles/search") {
                    if (additionalParams)
                        tempUrl = pageUrl + "&" + additionalParams;
                    else
                        tempUrl = pageUrl;
                }
                else {
                    if (additionalParams) {
                        appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams);

                        tempUrl = appendedPageUrl;
                    }
                    else {
                        tempUrl = appendExistingNavigationParameters(pageUrl);
                    }
                }

                var finalUrl = tempUrl;
                var splitFromStartParam = tempUrl.split("?");
                if (splitFromStartParam.length > 0) {

                    var finalUrl = splitFromStartParam[0];

                    var parameterList = splitFromStartParam[1].split("&");

                    if (parameterList.length > 0) {
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.AGENCYREQUESTIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.CLIENTGATEWAYIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.NOTETYPEIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.RELATEDIDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.IDQUERYSTRINGPARAM
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== "backto"
                        })
                        parameterList = parameterList.filter(function (item) {
                            return item.split("=")[0] !== Constants.JOBAILOUTCOMEIDQUERYSTRINGPARAM
                        })
                    }
                    var count = 0;
                    parameterList.forEach(element => {
                        if (element != '') {
                            if (count == 0) {
                                finalUrl = finalUrl + "?" + element;
                                count++;
                            }
                            else {
                                finalUrl = finalUrl + "&" + element;
                            }
                        }

                    });
                }
                return finalUrl;

            //Level 5

            case Constants.JPBAILOUTCOME:
                pageUrl = (`${Constants.JPBAILOUTCOMEPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (id != null)
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${id.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && id == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || id != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }


            case Constants.INTERACRTION:
                pageUrl = (`${Constants.INTERACTIONPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (id != null)
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${id.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && id == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || id != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }

            case Constants.REFFERRAL:
                pageUrl = (`${Constants.REFERRALDETAILSPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (id != null)
                    pageUrl = (`${pageUrl}${Constants.IDQUERYSTRINGPARAM}=${id.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && id == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || id != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }

            case Constants.AGENCYREQUEST:
                pageUrl = (`${Constants.AGENCYREQUESTDETAILSPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.AGENCYREQUESTIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (agencyRequestId != null)
                    pageUrl = (`${pageUrl}${Constants.AGENCYREQUESTIDQUERYSTRINGPARAM}=${agencyRequestId.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && agencyRequestId == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || agencyRequestId != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }

            case Constants.SERVICEDECISION:
                pageUrl = (`${Constants.SERVICEDECISIONDETAILSPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (clientFileId != null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && clientFileId == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.RELATEDIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || clientFileId != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.RELATEDIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.RELATEDIDQUERYSTRINGPARAM}=${relatedId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.RELATEDIDQUERYSTRINGPARAM}=${relatedId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }


            case Constants.CLIENTGATEWAY:
                pageUrl = (`${Constants.CLIENTGATEWAYPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTGATEWAYIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (clientGatewayId != null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTGATEWAYIDQUERYSTRINGPARAM}=${clientGatewayId.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && clientGatewayId == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || clientGatewayId != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.CLIENTFILEIDQUERYSTRINGPARAM}=${clientFileId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }

            case Constants.NOTEDETAILS:
                pageUrl = (`${Constants.NOTEDETAILSPAGEURL}?`);
                if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null) {
                    pageUrl = (`${pageUrl}${Constants.NOTETYPEIDQUERYSTRINGPARAM}=${parameters[0].toString().replaceAll(',','')}`);
                }
                else if (noteTypeId != null)
                    pageUrl = (`${pageUrl}${Constants.NOTETYPEIDQUERYSTRINGPARAM}=${noteTypeId.toString().replaceAll(',','')}`);


                if (parameters && parameters != null && parameters.length > 1 && (parameters[0] == null && noteTypeId == null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}${Constants.RELATEDIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 1 && (parameters[0] != null || noteTypeId != null) && parameters[1] != null) {
                    pageUrl = (`${pageUrl}&${Constants.RELATEDIDQUERYSTRINGPARAM}=${parameters[1].toString().replaceAll(',','')}`);
                }
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] != null)
                    pageUrl = (`${pageUrl}&${Constants.RELATEDIDQUERYSTRINGPARAM}=${relatedId.toString().replaceAll(',','')}`);
                else if (parameters && parameters != null && parameters.length > 0 && parameters[0] == null)
                    pageUrl = (`${pageUrl}${Constants.RELATEDIDQUERYSTRINGPARAM}=${relatedId.toString().replaceAll(',','')}`);

                if (option && option == 1) {
                    if (additionalParams) return pageUrl + "&" + additionalParams;

                    return pageUrl;
                }
                else {
                    if (additionalParams) { appendedPageUrl = appendExistingNavigationParameters(pageUrl + "&" + additionalParams); return appendedPageUrl; }

                    return appendExistingNavigationParameters(pageUrl);
                }
        }
    }

    const appendExistingNavigationParameters = (url) => {
        var fullParamList = url;
        var splitFromStartParam = url.split("?");
        if (splitFromStartParam.length > 1) {
            var parameterList = splitFromStartParam[1].split("&");

            var allPageParamsSplitFromStartParam = location?.search?.split("?");
            if (allPageParamsSplitFromStartParam?.length > 1) {
                var allPageParams = allPageParamsSplitFromStartParam[1].split("&");
                if (allPageParams != null && allPageParams != '') {
                    allPageParams.forEach(pageParam => {
                        var addParamToList = true;;
                        parameterList.forEach(element => {
                            if (pageParam.split("=")[0] == element.split("=")[0]) {
                                addParamToList = false;

                            }
                        });
                        if (addParamToList) {
                            fullParamList = fullParamList + "&" + pageParam;
                        }
                    });
                }
            }
        }

        return removeDuplicatedNavigationParameters(fullParamList);
    }


    const removeDuplicatedNavigationParameters = (url) => {

        var duplicatedParamList = [];
        var splitFromStartParam = url.split("?");
        var fullParamList = splitFromStartParam[0];

        if (splitFromStartParam.length > 1) {
            fullParamList = fullParamList + "?";
            var parameterList = splitFromStartParam[1].split("&");
            parameterList.forEach(element => {
                var instancesCount = parameterList.filter(p => p == element).length;

                if (duplicatedParamList.filter(p => p == element).length == 0) {
                    if (duplicatedParamList.length == 0)
                        fullParamList = fullParamList + element;
                    else
                        fullParamList = fullParamList + "&" + element;

                    duplicatedParamList.push(element);
                }
            });
        }

        return fullParamList;
    }




    return [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters];
}