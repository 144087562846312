import React, { useState, useEffect, useRef } from "react";
import { Grid, MenuItem, InputAdornment } from "@mui/material";
import dayjs from 'dayjs';
import SubHeader from "../../../components/SubHeader";
import StyledDialog from '../../../components/Dialog';
import ScreenSideBar from "../../../components/ScreenSideBar";
import ClientBasicInfo from "../../../client-basic-info";
import ClientContactInfo from "../../../client-contact-info";
import ClientFinancialInfo from "../../../client-financial-info";
import SelectorTextField from "../../../components/SelectorTextField";
import TextField from "../../../components/TextField";
import CustomDatePicker from "../../../components/DatePicker";
import AttachmentList from "../../attachments/components/attachment-list";
import DocketTable from '../../dockets/docket-list';
import DataTable from "../../../components/DataTable";
import NoteTable from "../../../notes";
import Backdrop from '../../../components/elements/Loader';
import StyledModal from '../../../components/Modal';
import StyledButton from '../../../components/elements/StyledButton';
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetClientFileById } from "../../../services/client-file.service";
import { GetInteractionMetaData, GetInteractionById, UpdateInteraction, SubmitInteraction, MarkInteractionForReview, DeleteInteraction, UpdateCrmServiceDecisionDutyCounselTriage } from "../../../services/interaction-service";
import { tokenRequest } from "../../../auth/authConfig";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import appconfig from '../../../appConfig';
import Badge from 'react-bootstrap/Badge';
import { useAuthorization } from "../../../hooks/useAuthorization";
import * as Constants from "../../../helper/constants";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { GetClientDetailNotes } from "../../../services/note-service";
import { FetchLookupData } from "../../../services/client-file.service";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";


export default function Interaction() {
    const isAuthenticated = useIsAuthenticated();
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();
    const { instance, accounts } = useMsal();
    const currentAccount = instance.getActiveAccount();
    const account = useAccount(accounts[0] || {});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [interactionId, setInteractionId] = useState(Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)));
    const [clientFileId, setClientFileId] = useState(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)));
    const [interactionCrmId, setInteractionCrmId] = useState("");
    const [clientFileCrmId, setClientFileCrmId] = useState("");
    const [isActive, setIsActive] = useState("");
    const [isConflicted, setIsConflicted] = useState(false);
    const screenSideBarList = ['Client Basic Information', 'Client Contact Information', 'Client Financial Information', 'Lawyer Information', 'Docket Details', 'Interaction Notes'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [formStatus, setFormStatus] = useState("");
    const [formStatusError, setFormStatusError] = useState("");
    const [originalAssignedTo, setOriginalAssignedTo] = useState("");
    const [assignedOn, setAssignedOn] = useState("");
    const [assignedTo, setAssignedTo] = useState("");
    const [assignedToError, setAssignedToError] = useState(false);
    const [dutyCounselType, setDutyCounselType] = useState("");
    const [dutyCounselTypeError, setDutyCounselTypeError] = useState("");
    const [adultOrYouth, setAdultOrYouth] = useState("");
    const [adultOrYouthError, setAdultOrYouthError] = useState("");
    const [isDisclosureUploaded, setIsDisclosureUploaded] = useState("");
    const [isDisclosureUploadedError, setIsDisclosureUploadedError] = useState(false);
    const [isDisclosureOrdered, setIsDisclosureOrdered] = useState("");
    const [isDisclosureOrderedError, setIsDisclosureOrderedError] = useState(false);
    const [hasPermissionToOrderDisclosure, setHasPermissionToOrderDisclosure] = useState("");
    const [hasPermissionToOrderDisclosureError, setHasPermissionToOrderDisclosureError] = useState(false);
    const [dateOfFirstAttemptedContact, setDateOfFirstAttemptedContact] = useState("");
    const [isdateOfFirstAttemptedContactInvalid, setIsdateOfFirstAttemptedContactInvalid] = useState(false);
    const [nextActionDate, setNextActionDate] = useState("");
    const [isNextActionDateInvalid, setIsNextActionDateInvalid] = useState(false);
    const [disableDateOfFirstAttemptedContact, setDisableDateOfFirstAttemptedContact] = useState(false);
    const [firstCourtDate, setFirstCourtDate] = useState("");
    const [firstCourtDateError, setFirstCourtDateError] = useState(false);
    const [isFirstCourtDateInvalid, setIsFirstCourtDateInvalid] = useState(false);
    const [disableFirstCourtDate, setDisableFirstCourtDate] = useState("");
    const [isInCustody, setIsInCustody] = useState("");
    const [isInCustodyError, setIsInCustodyError] = useState(false);
    const [financialStatus, setFinancialStatus] = useState("");
    const [serviceDecisionNumber, setServiceDecisionNumber] = useState("");
    const [serviceDecisionNumberError, setServiceDecisionNumberError] = useState("");
    const [assesmentOutcome, setAssesmentOutcome] = useState("");
    const [notes, setNotes] = useState("");
    const [clientInteractionNotes, setClientInteractionNotes] = useState([]);
    const [assesmentOutcomeDate, setAssesmentOutcomeDate] = useState("");
    const [imprisonmentLikelihood, setImprisonmentLikelihood] = useState("");
    const [imprisonmentLikelihoodError, setImprisonmentLikelihoodError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [dockets, setDockets] = useState([]);
    const [createdOn, setCreatedOn] = useState("");
    const [createdBy, setCreatedBy] = useState("");

    const [formStatuses, setFormStatuses] = useState([]);
    const [dutyCounselTypes, setDutyCounselTypes] = useState([]);
    const [adultOrYouthStatuses, setAdultOrYouthStatuses] = useState([]);

    const [assesmentOutcomes, setAssesmentOutcomes] = useState([]);
    const [imprisonmentLikelihoods, setImprisonmentLikelihoods] = useState([]);
    const yesOrNoSelectionList = [{ selectionItemId: "0", label: "Yes", value: true }, { selectionItemId: "1", label: "No", value: false },];

    const [topmyfilesduedate, setTopmyfilesduedate] = useState(searchParams.get("topmyfilesduedate") == "true" ? true : false);
    const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate") == "true" ? true : false);
    const [topassignedfilesduedate, setTopassignedfilesduedate] = useState(searchParams.get("topassignedfilesduedate") == "true" ? true : false);
    const [myfilesduedate, setMyfilesduedate] = useState(searchParams.get("myfilesduedate") == "true" ? true : false);
    //const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate") == "true"? true: false);
    const [assignedfilesduedate, setAssignedfilesduedate] = useState(searchParams.get("assignedfilesduedate") == "true" ? true : false);
    const [backPage, setBackPage] = useState(searchParams.get("page"));


    const [assignedToList, setAssignedToList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const NAVIGATEURL = "/client-file?id=";
    const apiServerUrl = appconfig.API_SERVER_URL;
    const [contactMethods, setContactMethods] = useState([]);

    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [middleName, setMiddleName] = useState(searchParams.get("middlename"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [clientBasicInfo, setClientBasicInfo] = useState("");
    const [clientContactInfo, setClientContactInfo] = useState("");
    const [pagecount, setPagecount] = useState(searchParams.get("pagecount"));
    const [saveClicked, setSaveClicked] = useState(false);
    const [canAssignedTo, setCanAssignedTo] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isInitiallyDisabled, setIsInitiallyDisabled] = useState(false);
    const [isDokcetDisabled, setIsDokcetDisabled] = useState(false);
    const [isShowAttachments, setIsShowAttachments] = useState(true);
    const [isFcUpdated, setIsFcUpdated] = useState(false);
    const [openFEGModal, setOpenFEGModal] = useState(false);
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Interaction : ", trackEvents);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

    const LoadInitialLookUpDataData = async (token) => {

        await FetchLookupData(token).then((data) => {
            if (data)
                setContactMethods(data.contactMethods);
        })
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchInitialData = async (token) => {

            if (clientFileId && clientFileId > 0) {
                await GetClientFileById(clientFileId, token).then(data => {
                    setClientBasicInfo(data?.clientBasicInformation);
                    setClientContactInfo(data?.clientContactDetails);
                });
            };
            const metaData = await GetInteractionMetaData(token);
            var initialAssesmentOutcomes = [];
            if (metaData) {
                setFormStatuses(metaData.formStatuses);
                setFormStatus(metaData.formStatuses[0]);
                setDutyCounselTypes(metaData.dutyCounselTypes);
                setAdultOrYouthStatuses(metaData.adultOrYouthStatuses);
                //setAssesmentOutcomes([...metaData.assesmentOutcomes,{assesmentOutcomeId: 8, assesmentOutcomeCrmValue: null, label: ''}]);
                setAssesmentOutcomes(metaData.assesmentOutcomes);
                setImprisonmentLikelihoods(metaData.imprisonmentLikelihoods);
                setAssignedToList(metaData.lawyers);
                initialAssesmentOutcomes = metaData.assesmentOutcomes;
            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
                appInsights.trackException({ error: new Error('An error occurred while loading data.'), severityLevel: SeverityLevel.Error });
            };

            if (interactionId && interactionId > 0) {
                var data = await GetInteractionById(interactionId, token);
                if (initialAssesmentOutcomes && data && data?.assesmentOutcomeId != 1) { 
                    var filteredAssesmentOutcomes = initialAssesmentOutcomes.filter(assesmentOutcome => assesmentOutcome.assesmentOutcomeId != 1 );
                    setAssesmentOutcomes(filteredAssesmentOutcomes);
                }
                setInitialInteraction(data);
                setTrackEvents(JSON.stringify(data));
                appInsights.trackTrace({ message: JSON.stringify(data), severityLevel: SeverityLevel.Information });
            };
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }
        const fetchClientDetailNotes = async (token) => {
            await GetClientDetailNotes(interactionId, 3, 'false', token).then((data) => {
                setClientInteractionNotes(data);
            });
        }

        if (interactionId != null && interactionId > 0) {

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        LoadInitialLookUpDataData(response.accessToken);
                        fetchClientDetailNotes(response.accessToken);
                    }
                });
            }
        }
        setIsLoading(false);
    }, [account, instance, isAuthenticated]);

    useEffect(() => {
        if ((isActive == true) && (isSubmitted == false)) {
            if (canSave()) {
                setIsReadOnly(false);
            } else {
                setIsReadOnly(true);
            }
        } else if ((currentAccount.idTokenClaims.roles[0].toString() === 'RosterLimitedAccess')) {
            setIsShowAttachments(false);
        } else if ((isActive == false) && (isSubmitted == false)) {
            setIsReadOnly(true);
        } else if ((currentAccount.idTokenClaims.roles[0].toString() === 'DutyCounselTriageLead')) {
            setIsReadOnly(false);
        } else if ((currentAccount.idTokenClaims.roles[0].toString() === 'RosterLimitedAccess')) {
            setIsReadOnly(false);
        } else {
            setIsReadOnly(true);
        }
    }, [isSubmitted, isActive]);

    useEffect(() => {
        if (!isFcUpdated) {
            backgroundSave();
        }
    });

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();

    const setInitialInteraction = (interaction) => {
        setIsActive(interaction.isActive);
        setIsConflicted(interaction.isInteractionConflicted);
        setInteractionCrmId(interaction.interactionCrmId != null ? interaction.interactionCrmId : "");
        setClientFileCrmId(interaction.clientFileCrmId);
        if (interaction.formStatus) { setFormStatus(interaction.formStatus); }
        setAssignedTo((interaction.assignedTo != null && interaction.assignedTo != '') ? JSON.parse(interaction.assignedTo) : "");

        setOriginalAssignedTo((interaction.assignedTo != null && interaction.assignedTo != '')  ? JSON.parse(interaction.assignedTo) : "")
        setAssignedOn(interaction.assignedOn != null ? interaction.assignedOn : "")

        //setDutyCounselType(interaction.dutyCounselType);
        setAdultOrYouth(interaction.isAdultOrYouth);
        setIsDisclosureUploaded(interaction.isDisclosureUploaded === true ? yesOrNoSelectionList[0] : yesOrNoSelectionList[1]);
        setIsDisclosureOrdered(interaction.isDisclosureOrdered === true ? yesOrNoSelectionList[0] : yesOrNoSelectionList[1]);
        setHasPermissionToOrderDisclosure(interaction.hasPermissionToOrderDisclosure === true ? yesOrNoSelectionList[0] : (interaction.hasPermissionToOrderDisclosure === false ? yesOrNoSelectionList[1] : null));
        setDateOfFirstAttemptedContact(interaction.dateOfFirstAttemptedContact);
        setNextActionDate(interaction.nextActionDate);
        if (interaction.dateOfFirstAttemptedContact != null) setDisableDateOfFirstAttemptedContact(true);
        setFirstCourtDate(interaction.firstCourtDate);
        if (interaction.firstCourtDate != null) setDisableFirstCourtDate(true);
        setIsInCustody(interaction.isInCustody === true ? yesOrNoSelectionList[0] : (interaction.isInCustody === false ? yesOrNoSelectionList[1] : null));
        setFinancialStatus(interaction.financialStatus);
        setServiceDecisionNumber(interaction.serviceDecisionNumber);
        setAssesmentOutcome(interaction.assesmentOutcome != null ? interaction.assesmentOutcome : "");
        setNotes(interaction.notes);
        setAssesmentOutcomeDate(interaction.assesmentOutcomeDate != null ? interaction.assesmentOutcomeDate : "");
        setImprisonmentLikelihood(interaction.imprisonmentLikelihood);
        setIsSubmitted(interaction.isSubmitted != null ? interaction.isSubmitted : false);
        setDockets(interaction.dockets.sort((a, b) => a.docketNumber.localeCompare(b.docketNumber)));
        setCanAssignedTo((currentAccount.idTokenClaims.roles[0].toString() == 'DutyCounselTriageLead') || (currentAccount.idTokenClaims.roles[0].toString() == 'LegalServicesManager') ? false : true);
        setIsInitiallyDisabled(true);
        latestCourtDate(interaction.dockets, interaction.firstCourtDate);
        setCreatedOn(interaction.createdOn);
        setCreatedBy(interaction.createdBy);
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const validateInteractionDetails = () => {
        var status = true;
        if (!formStatus || formStatus == "") { setFormStatusError(true); status = false; }
        // if (!dutyCounselType || dutyCounselType == "") { setDutyCounselTypeError(true); status = false; }
        // if (!adultOrYouth || adultOrYouth == "") { setAdultOrYouthError(true); status = false; }
        if (!isDisclosureUploaded || isDisclosureUploaded == "") { setIsDisclosureUploadedError(true); status = false; }
        if (!isDisclosureOrdered || isDisclosureOrdered == "") { setIsDisclosureOrderedError(true); status = false; }
        if (!hasPermissionToOrderDisclosure || hasPermissionToOrderDisclosure == "") { setHasPermissionToOrderDisclosureError(true); status = false; }
        //if (!firstCourtDate || firstCourtDate == "") { setFirstCourtDateError(true); status = false; }
        // if (!isInCustody || isInCustody == "") { setIsInCustodyError(true); status = false; }
        // if (!serviceDecisionNumber || serviceDecisionNumber == "") { setServiceDecisionNumberError(true); status = false; }
        if ((assesmentOutcome && assesmentOutcome != "") && (!imprisonmentLikelihood || imprisonmentLikelihood == "")) { setImprisonmentLikelihoodError(true); status = false; }
        if ((assesmentOutcome && assesmentOutcome != "") && (!assignedTo || assignedTo == "")) { setAssignedToError(true); status = false; }
        if (isNextActionDateInvalid) { status = false; }
        return status;
    };



    const canSave = () => {
        var result = false;

        if (null != interactionId && interactionId > 0) {
            result = canUpdate("interaction", (null != assignedTo) ? assignedTo.value : null);
        }

        return result;
    };

    const canCreateAgencyRequest = () => { return ((null != interactionId) && (interactionId > 0) && canCreate("agency-request", null)); }

    const onSaveClicked = async (option) => {
        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInteractionDetails();
            if (isValidData) {
                //setSaveClicked(true);
                var interactionDetails = createInteractionObject();
                setTrackEvents(JSON.stringify(interactionDetails));
                appInsights.trackTrace({ message: JSON.stringify(interactionDetails), severityLevel: SeverityLevel.Information });
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            UpdateInteraction(interactionDetails, response.accessToken, false).then(() => {

                                setOriginalAssignedTo((interactionDetails.assignedTo != null && interactionDetails.assignedTo != '') ? JSON.parse(interactionDetails.assignedTo) : "")
                                setAssignedOn(interactionDetails.assignedOn != null ? interactionDetails.assignedOn : "")


                                if (option == 1) {
                                    navigateToPage();
                                }
                            });
                        }
                    });
                }
            }
        }
        else {
            toast.error("Cannot create an interaction. Interaction needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };

    const createInteractionObject = () => {
        var now = new Date();
        var newAssignedOn = assignedOn;
        if (!assignedTo || assignedTo == '') {
            newAssignedOn = null;
        }
        else if (assignedTo && originalAssignedTo.value != assignedTo.value) {
            newAssignedOn = now;
        }

        const interaction = {
            "createdBy": createdBy && createdBy != '' ? createdBy : "System",
            "createdOn": createdOn && createdOn != '' ? new Date(createdOn) : now,
            "modifiedBy": "System",
            "modifiedOn": now,
            "interactionId": interactionId,
            "isActive": isActive,
            "clientFileId": clientFileId,
            "formStatusId": formStatus ? formStatus?.formStatusId : null,
            "assignedTo": (assignedTo &&  assignedTo != '') ? JSON.stringify(assignedTo) : null,
            "assignedOn": newAssignedOn ? new Date(newAssignedOn) : null,
            "interactionCrmId": interactionCrmId ? interactionCrmId : null,
            //"dutyCounselTypeId": dutyCounselType ? dutyCounselType?.dutyCounselTypeId : null,
            "isAdultOrYouthId": adultOrYouth ? adultOrYouth?.adultOrYouthStatusId : null,
            "isDisclosureUploaded": isDisclosureUploaded ? isDisclosureUploaded?.value : null,
            "isDisclosureOrdered": isDisclosureOrdered ? isDisclosureOrdered?.value : null,
            "hasPermissionToOrderDisclosure": hasPermissionToOrderDisclosure ? hasPermissionToOrderDisclosure?.value : null,
            "dateOfFirstAttemptedContact": dateOfFirstAttemptedContact ? new Date(dateOfFirstAttemptedContact) : null,
            "nextActionDate": nextActionDate ? new Date(nextActionDate) : null,
            "firstCourtDate": firstCourtDate != "" ? new Date(firstCourtDate) : null,
            "isInCustody": isInCustody ? isInCustody?.value : null,
            "serviceDecisionNumber": serviceDecisionNumber ? serviceDecisionNumber : null,
            "assesmentOutcomeId": assesmentOutcome ? assesmentOutcome?.assesmentOutcomeId : null,
            "assesmentOutcomeDate": assesmentOutcomeDate ? new Date(assesmentOutcomeDate) : null,
            "imprisonmentLikelihoodId": imprisonmentLikelihood ? imprisonmentLikelihood?.imprisonmentLikelihoodId : null,
            "isSubmitted": isSubmitted,
            "isInteractionConflicted": isConflicted,
            "clientFileCrmId": clientFileCrmId
        }

        if ((interactionId != null) && (interactionId > 0)) {
            interaction.interactionId = interactionId;
        }
        return interaction;
    }



    const handleSelectChanged = (event, menuItems, setSelectedMenuItem, setSelectedMenuItemError) => {
        const selectedMenuItem = menuItems.find(
            (item) => item?.label === event.target.value
        );
        setSelectedMenuItem(selectedMenuItem);
        if (selectedMenuItem) { setSelectedMenuItemError(false); }
    };

    const onFormStatusChanged = (event) => {
        handleSelectChanged(event, formStatuses, setFormStatus);
    };

    const onAssignedToChanged = (event) => {
        // handleSelectChanged(event,assignedToList,setAssignedTo );
        const selectedMenuItem = assignedToList.find(
            (item) => item?.label === event.target.value
        );
        setAssignedTo(selectedMenuItem);
        if (selectedMenuItem && selectedMenuItem != "") setAssignedToError(false);
        // if (assesmentOutcome && assesmentOutcome != "" && assesmentOutcome.assesmentOutcomeId != 8) {
        //     setFormStatus(formStatuses[2])
        // } else 
        if (selectedMenuItem && selectedMenuItem != "" && formStatus?.formStatusId != 3) {
            setFormStatus(formStatuses[1])
        } else if (formStatus?.formStatusId != 3) {
            setFormStatus(formStatuses[0])
        }
    };

    // const onDutyCounselTypeChanged = (event) => {
    //     handleSelectChanged(event,dutyCounselTypes,setDutyCounselType );
    // }; 

    const onDisclosureUploadedChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsDisclosureUploaded, setIsDisclosureUploadedError);
    };

    const onDisclosureOrderedChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsDisclosureOrdered, setIsDisclosureOrderedError);
    };

    const onHasPermissionToOrderDisclosureChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setHasPermissionToOrderDisclosure, setHasPermissionToOrderDisclosureError);
    };

    const onDateOfFirstAttemptedContactChanged = (date) => {
        setDateOfFirstAttemptedContact(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") { //|| new Date(date) > new Date()
                setIsdateOfFirstAttemptedContactInvalid(true);
            } else {
                setDateOfFirstAttemptedContact(Date.parse(date));
                setIsdateOfFirstAttemptedContactInvalid(false);
            };
        } else {
            setDateOfFirstAttemptedContact(date);
            setIsdateOfFirstAttemptedContactInvalid(false);
        }
    };

    const onNextActionDateChanged = (date) => {
        setNextActionDate(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") { //|| new Date(date) > new Date()
                setIsNextActionDateInvalid(true);
            } else {
                setNextActionDate(Date.parse(date));
                setIsNextActionDateInvalid(false);
            };
        } else {
            setNextActionDate(date);
            setIsNextActionDateInvalid(false);
        }
    };

    const onFirstCourtDateChanged = (date) => {
        setFirstCourtDate(date);
        if (date != null) {
            setFirstCourtDateError(false);
            if ((new Date(date)).toString() === "Invalid Date") { // || new Date(date) > new Date()
                setIsFirstCourtDateInvalid(true);
            } else {
                setFirstCourtDate(Date.parse(date));
                setFirstCourtDateError(false);
                setIsFirstCourtDateInvalid(false);
            };
        } else {
            setFirstCourtDate(date);
            setFirstCourtDateError(true);
            setIsFirstCourtDateInvalid(false);
        }
    };

    const onInCustodyChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsInCustody);
    };

    const onServiceDecisionNumberChanged = (event) => {
        setServiceDecisionNumber(event.target.value);
    };

    const onViewDetailsClicked = () => {

        var otherParams = "";
        if (backPage == Constants.TOPASSIGNEDFILES || backPage == Constants.TOPMYFILES || backPage == Constants.TOPFILESTOASSIGN ||
            backPage == Constants.ASSIGNEDFILES || backPage == Constants.MYFILES || backPage == Constants.FILESTOASSIGN) {
                otherParams =  `backto=${backPage}&page=interaction&interactionId=${interactionId}`;
        }
        else {
            otherParams = `page=interaction&interactionId=${interactionId}`;
        }

        navigate(`${prepareNavigationParameters(Constants.SERVICEDECISION, new Array(clientFileId, interactionCrmId), null, otherParams)}`);
    };

    const onNotesChanged = (event) => {
        setNotes(event.target.value);
    };

    const onAssesmentOutcomeChanged = (event) => {
        const selectedMenuItem = assesmentOutcomes.find(
            (item) => item?.label === event.target.value
        );
        setAssesmentOutcome(selectedMenuItem);
        if (selectedMenuItem && selectedMenuItem != "" && selectedMenuItem.assesmentOutcomeId != 8) {
            if (assesmentOutcomeDate == "") setAssesmentOutcomeDate(new Date());          
        } else if (assignedTo && assignedTo != "") {
            setFormStatus(formStatuses[1])
        }
        else {
            setFormStatus(formStatuses[0])
        }
    };

    const onLikelihoodOfImprisonmentChanged = (event) => {
        handleSelectChanged(event, imprisonmentLikelihoods, setImprisonmentLikelihood, setImprisonmentLikelihoodError);
    };

    const onCancelClicked = () => {
        navigateToPage();
    };

    const onCreateARClicked = async (event) => {
        if (canCreate("agency-request", null)) {
            if (interactionId != null && interactionId > 0) {
                var otherParams = "";
                if (backPage == Constants.TOPASSIGNEDFILES || backPage == Constants.TOPMYFILES || backPage == Constants.TOPFILESTOASSIGN ||
                    backPage == Constants.ASSIGNEDFILES || backPage == Constants.MYFILES || backPage == Constants.FILESTOASSIGN) {
                    otherParams = `backto=${backPage}&page=interaction&interactionId=${interactionId}`;
                }
                else {
                    otherParams = `page=interaction&interactionId=${interactionId}`;
                }

                navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(null, clientFileId), null, otherParams)}`);
            } else {
                navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(null, clientFileId), null, "page=interaction&id=0")}`);
            }
        }
        else {
            toast.error("You don't have permission to add Agency Requests.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }

    function navigateToPage() {
        if (backPage == Constants.CLIENTFILEVIEWDETAILS)
            navigate(`${prepareNavigationParameters(null, null, null, "page=" + Constants.CLIENTFILEDASHBOARD)}`);
        else
            navigate(`${prepareNavigationParameters(null, null, null)}`);

    }

    const isSubmitReady = () => {
        return (canSave() && (formStatus != null && formStatus != "" && (formStatus.formStatusId == 2 || formStatus.formStatusId == 3) && assignedTo != "" && assesmentOutcome != "" && !isSubmitted))
    }

    const canDeleteInteraction = () => {
        return ((null != searchParams.get(Constants.IDQUERYSTRINGPARAM)) && (Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)) > 0) && canDelete('interaction', null));
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const onDeleteClicked = (event) => {
        setTrackEvents("Deleting Interaction By Id : " + searchParams.get(Constants.IDQUERYSTRINGPARAM));
        appInsights.trackTrace({ message: "Deleting Interaction By Id : " + searchParams.get(Constants.IDQUERYSTRINGPARAM), severityLevel: SeverityLevel.Information });
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    DeleteInteraction(Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)), response.accessToken).then(() => {
                        navigateToPage();
                    });
                }
            });
        }
    }

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);

    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };

    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        setTrackEvents("Marking Interaction as conflicted : " + searchParams.get(Constants.IDQUERYSTRINGPARAM));
        appInsights.trackTrace({ message: "Marking Interaction as conflicted : " + searchParams.get(Constants.IDQUERYSTRINGPARAM), severityLevel: SeverityLevel.Information });
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkInteractionForReview(Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)), response.accessToken).then(() => {
                        window.location.reload();
                    });
                }
            });
        }
    }

    function Save() {

        onSaveClicked(0);
    }

    function SaveAndClose() {

        onSaveClicked(1);
    }
    const onSubmitClicked = async (event) => {
        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInteractionDetails();
            if (isValidData) {
                setIsSubmitted(true);
                var interactionDetails = createInteractionObject();
                interactionDetails.isSubmitted = true;
                interactionDetails.formStatusId = 3;
                setTrackEvents(JSON.stringify(interactionDetails));
                appInsights.trackTrace({ message: JSON.stringify(interactionDetails), severityLevel: SeverityLevel.Information });
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            SubmitInteraction(interactionDetails, response.accessToken).then(() => {

                                setOriginalAssignedTo((interactionDetails.assignedTo != null && interactionDetails.assignedTo != '') ? JSON.parse(interactionDetails.assignedTo) : "")
                                setAssignedOn(interactionDetails.assignedOn != null ? interactionDetails.assignedOn : "")

                                //navigate(NAVIGATEURL + clientFileId);
                            });
                        }
                    });
                }
            }
        }
        else {
            toast.error("Cannot submit interaction. Interaction needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };

    const handleModalOpen = () => { setOpenFEGModal(true); };
    const handleModalClose = () => { setOpenFEGModal(false); };

    const filterFutureDockets = (docket) => {
        var date1 = new Date(docket.upcomingCourtDate);
        var now = new Date();

        return (date1 > now);
    }

    const compareDocketDetails = (a, b) => {
        if (Date.parse(a.upcomingCourtDate) < Date.parse(b.upcomingCourtDate)) {
            return -1;
        }
        if (Date.parse(a.upcomingCourtDate) > Date.parse(b.upcomingCourtDate)) {
            return 1;
        }
        return 0;
    }

    const latestCourtDate = (lstDockets, fcdate) => {
        if (!fcdate) {
            if (lstDockets && lstDockets.length > 0) {
                var lstDocketswithUpcomingCourtDates = [];
                lstDockets.forEach((docket) => {
                    if (null != docket.upcomingCourtDate) {
                        lstDocketswithUpcomingCourtDates.push(docket);
                    }
                });
                var sortedDocets = lstDocketswithUpcomingCourtDates.sort(compareDocketDetails);
                var upcomingCourtDate = sortedDocets.length > 0 ? sortedDocets[0].upcomingCourtDate : '';
                setFirstCourtDate(upcomingCourtDate);

            }
            else {
                setFirstCourtDate('');
            }

        }
    }

    const backgroundSave = async () => {

        if (clientFileId && clientFileId > 0 && firstCourtDate != '') {
            const isValidData = validateInteractionDetails();
            setFormStatusError(false);
            setIsDisclosureUploadedError(false);
            setIsDisclosureOrderedError(false);
            setHasPermissionToOrderDisclosureError(false);
            setImprisonmentLikelihoodError(false);
            setAssignedToError(false);
            if (isValidData) {
                //setSaveClicked(true);
                setIsFcUpdated(true);
                var interactionDetails = createInteractionObject();
                //interactionDetails.firstCourtDate = firstCourtDate;
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            UpdateInteraction(interactionDetails, response.accessToken, true).then(() => {
                                setIsFcUpdated(true);
                            });
                        }
                    });
                }
            }
        }
        else {
            //toast.error("Cannot create an interaction. Interaction needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };


    if (isLoading) {
        return (
            <Backdrop />
        );
    } else {

        if (canRead("interaction", null)) {

            return (
                <Grid container style={{ padding: "10px 20px 0px 20px", margin: "80px 0px 60px 0px" }}>
                    <SubHeader
                        title="Client Interactions"
                        menuItemOneText="Delete Interaction"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteInteraction()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText="Mark for Review"
                        onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                        isMenuItemTwoVisible={((canUpdate("interaction", null)) && (Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)) && Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)) > 0) && (!isConflicted))}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}

                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={canSave() ? (saveClicked) : true}

                        navItemTwoText="Save & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={true}
                        isNavItemTwoDisabled={canSave() ? (saveClicked) : true}

                        navItemThreeText="Submit"
                        onNavItemThreeClicked={onSubmitClicked}
                        isNavItemThreeVisible={isSubmitReady()}
                        isNavItemThreeDisabled={isReadOnly}

                        navItemFourText="New Agency Request"
                        onNavItemFourClicked={onCreateARClicked}
                        isNavItemFourVisible={canCreateAgencyRequest()}
                        isNavItemFourDisabled={false}

                        navItemFiveText="Cancel"
                        onNavItemFiveClicked={onCancelClicked}
                        isNavItemFiveVisible={true}
                        isNavItemFiveDisabled={false}

                    />
                    <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                        message={"Do you really want to mark the Interaction :" + Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)) + " for review?"}
                        onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />
                    <StyledDialog title="Delete Interaction"
                        open={openDeleteDialog}
                        onClose={onDeleteClickClose}
                        message={"Do you really want to delete the Interaction :" + Number(searchParams.get(Constants.IDQUERYSTRINGPARAM)) + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked}
                        confirmationText="Delete" />
                    {/* <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} /> */}
                    {!isActive && !isSubmitted && (<>
                        <Badge bg="danger" style={{ marginBottom: "10px" }}>** Inactive **</Badge>
                    </>)}
                    {isSubmitted && (<>
                        <Badge bg="success" style={{ marginBottom: "10px" }}>** Submitted **</Badge>
                    </>)}
                    {isConflicted && (<>
                        <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                    </>)}
                    <Grid item xs={12} md={12} >
                        <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", borderRadius: "8px", backgroundColor: "#ececec" }}>
                            <Grid item xs={12} md={12} style={{
                                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                            }}>Client Information
                            </Grid>
                            {/* <Grid item xs={6} md={6} style={{ textAlign: "right", paddingRight: "10px" }}>
                                <TextButton label="Financial Eligibility Guidelines" size="small" handleOnClick={handleModalOpen} />
                            </Grid> */}
                            <StyledModal open={openFEGModal} title="Financial Eligibility" handleOnClose={handleModalClose} />
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="First Name" placeholder="First Name" required={true} disabled={true} value={clientBasicInfo?.firstName} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="Middle Name" placeholder="Middle Name" disabled={true} value={clientBasicInfo?.middleName} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="Last Name" placeholder="Last Name" required={true} disabled={true} value={clientBasicInfo?.lastName} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <CustomDatePicker label="Date of Birth" placeholder="dd-Mmm-yyyy" required={true} disabled={true} value={clientBasicInfo?.dateOfBirth} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="Email" placeholder="Email" required={false} disabled value={clientContactInfo?.email} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="Phone Number" placeholder="Phone Number" required={false} disabled value={clientContactInfo?.phoneNumber} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <TextField label="Mobile Number" placeholder="Mobile Number" required={false} disabled value={clientContactInfo?.mobileNumber} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px", minWidth: "275px" }}>
                                <SelectorTextField label="Preferred Method of Contact" placeholder="Method of Contact" select disabled
                                    value={(null == clientContactInfo?.preferredMethodOfContactId) ? "" : clientContactInfo?.preferredMethodOfContactId}>
                                    {contactMethods?.map((contactMethod) => (
                                        <MenuItem key={contactMethod.contactMethodId} value={contactMethod.contactMethodId}>
                                            {contactMethod.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px", backgroundColor: isConflicted ? "#ececec" : null }}>
                            <NoteTable title="Interaction Notes" notes={clientInteractionNotes} clientFileId={clientFileId} relatedId={interactionId} noteTypeId={3} queryParams={"clientFileId=" + clientFileId.toString()} isDisabled={(isConflicted) ? true : false} pageId={backPage} />
                        </Grid>
                        <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", margin: "10px 0px 0px 0px", borderRadius: "8px", backgroundColor: isConflicted || isReadOnly ? "#ececec" : null }}>
                            <Grid item xs={12} md={12} style={{
                                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                            }}>Lawyer Information</Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Form Status" placeholder="Form Status" required={true} style={{ backgroundColor: "#ececec" }}
                                    select
                                    error={formStatusError ? true : null}
                                    value={formStatus ? formStatus?.label : ""}
                                    disabled={true}
                                    //handleChange={onFormStatusChanged}
                                    helperText={formStatusError ? "Form Status is required." : null}>
                                    {formStatuses.map((status) => (
                                        <MenuItem key={status.formStatusId} value={status.label}>
                                            {status.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="In-Custody?" placeholder="In-Custody?" style={{ backgroundColor: "#ececec" }} required={true}
                                    select
                                    // error={isInCustodyError ? true : null}
                                    disabled={true}
                                    value={isInCustody ? isInCustody?.label : ""}
                                //handleChange={onInCustodyChanged}
                                >
                                    {/* helperText={isInCustodyError ? "Please fill the required field." : null} */}
                                    {yesOrNoSelectionList.map((type) => (
                                        <MenuItem key={type.selectionItemId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Adult or Youth?" placeholder="Adult or Youth" style={{ backgroundColor: "#ececec" }}
                                    select
                                    // error={adultOrYouthError ? true : null}
                                    disabled={true}
                                    value={adultOrYouth ? adultOrYouth?.label : ""}>
                                    {/* helperText={adultOrYouthError ? "Please fill the required field." : null} */}
                                    {adultOrYouthStatuses.map((type) => (
                                        <MenuItem key={type.adultOrYouthStatusId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Assigned To" placeholder="Assigned to Lawyer" style={{ backgroundColor: canAssignedTo ? "#ececec" : null }}
                                    select
                                    required={(assesmentOutcome && assesmentOutcome != "") ? true : false}
                                    disabled={isConflicted || canAssignedTo || isReadOnly ? true : null}
                                    error={assignedToError ? true : null}
                                    value={assignedTo ? assignedTo?.label : ""}
                                    handleChange={onAssignedToChanged}
                                    helperText={assignedToError ? "Assigned To is required." : null}
                                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                    {assignedToList.map((lawyer) => (
                                        <MenuItem key={lawyer.value} value={lawyer.label}>
                                            {lawyer.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Service Decision Number" placeholder="Service Decision Number" style={{ backgroundColor: "#ececec" }} required={true}
                                    customwidth={"162px"}
                                    disabled={true}
                                    value={serviceDecisionNumber}
                                    handleChange={onServiceDecisionNumberChanged}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end' style={{ marginRight: "-8px" }}>
                                                <StyledButton disabled={interactionCrmId != "" ? false : true} isViewDetails variant={"outlined"} color="error" onClick={onViewDetailsClicked}>View Details</StyledButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {/* helperText={serviceDecisionNumberError ? "Service Decision Number is required" : null} */}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <div style={{ maxWidth: "275px" }}>
                                    <CustomDatePicker label="First Court Date" placeholder="First Court Date" required={false}
                                        style={{ width: "275px", backgroundColor: (isConflicted || disableFirstCourtDate || isInitiallyDisabled) ? "#ececec" : "" }}
                                        error={isFirstCourtDateInvalid ? true : (firstCourtDateError ? true : null)}
                                        disabled={(isConflicted || disableFirstCourtDate || isReadOnly || isInitiallyDisabled) ? true : null}
                                        value={firstCourtDate}
                                        handleOnChange={onFirstCourtDateChanged}
                                        // helperText={isFirstCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (firstCourtDateError ? "First Court Date is required." : null)} />
                                        helperText={isFirstCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : null} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Permission to order disclosure?" placeholder="Permission to order disclosure" required={true}
                                    select
                                    error={hasPermissionToOrderDisclosureError ? true : null}
                                    disabled={isConflicted || isReadOnly ? true : null}
                                    value={hasPermissionToOrderDisclosure ? hasPermissionToOrderDisclosure?.label : ""}
                                    handleChange={onHasPermissionToOrderDisclosureChanged}
                                    helperText={hasPermissionToOrderDisclosureError ? "Please fill the required field." : null}>
                                    {yesOrNoSelectionList.map((type) => (
                                        <MenuItem key={type.selectionItemId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Disclosure has been ordered?" placeholder="Disclosure has been ordered" required={true}
                                    select
                                    error={isDisclosureOrderedError ? true : null}
                                    disabled={isConflicted || isReadOnly ? true : null}
                                    value={isDisclosureOrdered ? isDisclosureOrdered?.label : ""}
                                    handleChange={onDisclosureOrderedChanged}
                                    helperText={isDisclosureOrderedError ? "Please fill the required field." : null}>
                                    {yesOrNoSelectionList.map((type) => (
                                        <MenuItem key={type.selectionItemId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Disclosure Uploaded?" placeholder="Disclosure Uploaded" required={true}
                                    select
                                    error={isDisclosureUploadedError ? true : null}
                                    disabled={isConflicted || isReadOnly ? true : null}
                                    value={isDisclosureUploaded ? isDisclosureUploaded?.label : ""}
                                    handleChange={onDisclosureUploadedChanged}
                                    helperText={isDisclosureUploadedError ? "Please fill the required field." : null}>
                                    {yesOrNoSelectionList.map((type) => (
                                        <MenuItem key={type.selectionItemId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <div style={{ maxWidth: "275px" }}>
                                    <CustomDatePicker label="Date of First Attempted Contact" placeholder="Date of First Attempted Contact"
                                        style={{ width: "275px", backgroundColor: (isConflicted || disableDateOfFirstAttemptedContact) ? "#ececec" : "" }}
                                        error={isdateOfFirstAttemptedContactInvalid ? true : null}
                                        disabled={(isConflicted || disableDateOfFirstAttemptedContact || isReadOnly) ? true : null}
                                        value={dateOfFirstAttemptedContact}
                                        handleOnChange={onDateOfFirstAttemptedContactChanged}
                                        helperText={isdateOfFirstAttemptedContactInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : null} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8} lg={8} style={{ margin: "10px 0px 10px 0px" }}>
                                <div style={{ maxWidth: "275px" }}>
                                    <CustomDatePicker label="Next Action Date " placeholder="Next Action Date "
                                        style={{ width: "275px", backgroundColor: (isConflicted || isReadOnly) ? "#ececec" : "" }}
                                        error={isNextActionDateInvalid ? true : null}
                                        disabled={(isConflicted || isReadOnly) ? true : null}
                                        value={nextActionDate}
                                        handleOnChange={onNextActionDateChanged}
                                        helperText={isNextActionDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : null} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Assesment Outcome" placeholder="Assesment Outcome"
                                    select
                                    disabled={isConflicted || isReadOnly ? true : null}
                                    value={assesmentOutcome ? assesmentOutcome?.label : ""}
                                    handleChange={onAssesmentOutcomeChanged}
                                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                    {assesmentOutcomes.map((assesmentOutcome) => (
                                        <MenuItem key={assesmentOutcome.assesmentOutcomeId} value={assesmentOutcome.label}>
                                            {assesmentOutcome.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <CustomDatePicker label="Assesment Outcome Date" placeholder="Assesment Outcome Date" style={{ width: "275px", backgroundColor: "#ececec" }}
                                    disabled={true}
                                    value={assesmentOutcomeDate}
                                    handleOnChange={() => { }} //onAssesmentOutcomeDateChanged
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Likelihood of Imprisonment" placeholder="Likelihood of Imprisonment" required={(assesmentOutcome && assesmentOutcome != "") ? true : false}
                                    select
                                    error={imprisonmentLikelihoodError ? true : null}
                                    disabled={isConflicted || isReadOnly ? true : null}
                                    value={imprisonmentLikelihood ? imprisonmentLikelihood?.label : ""}
                                    handleChange={onLikelihoodOfImprisonmentChanged}
                                    helperText={imprisonmentLikelihoodError ? "Likelihood of Imprisonment is required" : null}>
                                    {imprisonmentLikelihoods.map((imprisonmentLikelihood) => (
                                        <MenuItem key={imprisonmentLikelihood.imprisonmentLikelihoodId} value={imprisonmentLikelihood.label}>
                                            {imprisonmentLikelihood.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px", backgroundColor: isDokcetDisabled || isReadOnly ? "#ececec" : null }}>
                            <DocketTable title="Docket Details" dockets={dockets} clientFileId={clientFileId} isDisabled={(isDokcetDisabled || isReadOnly) ? true : false} />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px", padding: "5px", border: "1px solid #7F8284", borderRadius: "6px", display: isShowAttachments ? "flex" : "none" }}>
                            <AttachmentList containerName="interactions" recordId={interactionId} title="Attachments (Disclosure provided by court, etc)" isDisabled={(isConflicted || isReadOnly) ? true : false} />
                        </Grid>
                    </Grid>
                </Grid>
            );

        } else {
            return (
                <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                    <Grid item xs={12} md={12} style={{
                        textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                    }}>Client Interaction
                    </Grid>
                    <Grid item xs={12} md={12} style={{
                        textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1",
                    }}>** You are not authorized to view these details **
                    </Grid>
                </Grid>
            );
        }
    };

};