import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import appconfig from '../appConfig'
import { useValidation } from "../hooks/useValidation";
import * as Constants from "../helper/constants";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import { Grid,MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import SelectorTextField from "../components/SelectorTextField";
import { GetClientContactInformation } from "../services/client-file.service";


const ClientContactInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [clientContactInformationId, setClientContactInformationId] = useState(0);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showPhoneNumber, setShowPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [showMobileNumber, setShowMobileNumber] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [preferredMethodOfContact, setPreferredMethodOfContact] = useState(2);

    useImperativeHandle(ref, () => ({
        getClientContactInfo() {
            return {
                "clientContactInfoId": clientContactInformationId,
                "email": email? email : null,
                "phoneNumber": phoneNumber? phoneNumber : null,
                "mobileNumber": mobileNumber? mobileNumber : null,
                "emailValidity": emailError,
                "phoneNumberValidity": phoneNumberError,
                "mobileNumberValidity": mobileNumberError,
                "preferredMethodOfContact": {
                    "contactMethodId": preferredMethodOfContact,
                    "contactMethodCrmValue": 0,
                    "label": "string"
                }
            };
        }
    }));

    useEffect(() => {
        
        if (!props.clientFile && props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        LoadClientContactInfromation(response.accessToken, props.clientFileId);
                    }
                });
            }
        }
        else if (props.clientFile) {
            setMainInformation(props.clientFile.clientContactDetails);
        };
    }, [account, instance]);

    const LoadClientContactInfromation = async (token, clientFileId) => { 

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await GetClientContactInformation(token, clientFileId).then((data) => {
                        if (data)
                            setMainInformation(data);
                    })
                }
            });
        }
    }

    function setMainInformation(data) {
        setClientContactInformationId(data.clientContactInfoId);
        setEmail(data.email);
        setPhoneNumber(data.phoneNumber);
        setShowPhoneNumber(data.phoneNumber);
        setMobileNumber(data.mobileNumber);
        setShowMobileNumber(data.mobileNumber);
        if (null != data.preferredMethodOfContactId && "" != data.preferredMethodOfContactId && 0 != data.preferredMethodOfContactId) {
            setPreferredMethodOfContact(data.preferredMethodOfContactId);
        } else {
            setPreferredMethodOfContact(data.preferredMethodOfContact?.contactMethodId);
        }
    }

    function checkEmail(event) {
        if (event.target.value.length == 0) {
            setEmail(EMPTYSTRING);
            setEmailError(false);
        }
        else {
            const emailFieldModal = { name: Constants.EMAIL, value: event.target.value, maxLength: 100 };
            setEmailError(!isValid(emailFieldModal));
        }
    }

    function checkPhoneNumber(event, phoneType) {
        const phoneFieldModal = { name: Constants.PHONENUMBER, value: event.target.value, minLength: 10, maxLength: 11 };
        const phoneStatus = isValid(phoneFieldModal);

        switch (phoneType) {
            case 1:
                setPhoneNumberError(!phoneStatus);
                break;
            case 2:
                setMobileNumberError(!phoneStatus);
                break;
            default:
                break;
        }
    }

    const onEmailChanged = (event) => {
        setEmail(event.target.value);
        checkEmail(event);
    };

    const onNumberChanged = (eventValue,setShowNumber,setNumberError,setNumber ) => {
        let showEventValue= eventValue.replace(/ /g,'').replace('-','').replace('(','').replace(')','').replace('+','');
        setShowNumber(showEventValue);
        let inputArray = [...showEventValue];
        let formattedInputArray = inputArray.map(char => (char === ' ' || char === '+') ? "n": char);
        if (formattedInputArray.length === 10 || formattedInputArray.length === 11 ){
            if (isNaN(formattedInputArray.join('')))  {
                setNumberError(true);
            }else {
                var formattedNumber= "";
                if (formattedInputArray.length == 10){
                    formattedNumber = "(" + formattedInputArray.slice(0,3).join('') + ") " + formattedInputArray.slice(3,6).join('') + "-" + formattedInputArray.slice(6,10).join('');
                } else if (formattedInputArray.length == 11) {
                    formattedNumber = "+" + formattedInputArray.slice(0,1).join('') + " (" + formattedInputArray.slice(1,4).join('') + ") " + formattedInputArray.slice(4,7).join('') + "-" + formattedInputArray.slice(7,11).join('');
                } 
                setNumber(formattedNumber);
                setNumberError(false);
            }   
        } else if (formattedInputArray.length === 0) {
            setNumberError(false);
            setNumber("");
        } else {
            setNumberError(true);
        }  
    };

    const onPhoneNumberChanged = (event) => {
        onNumberChanged(event.target.value,setShowPhoneNumber,setPhoneNumberError,setPhoneNumber);     
    };
    const onMobileNumberChanged = (event) => {
        onNumberChanged(event.target.value,setShowMobileNumber,setMobileNumberError,setMobileNumber);     
    };

    const onPreferredMethodOfContactChanged = (event) => {
        setPreferredMethodOfContact(event.target.value);
    };
    const contactMethods = props.contactMethods;

    return (
        <Grid container style={{padding: "10px 20px 0px 20px", marginBottom:props.isReadOnly ? "1px": null, backgroundColor: props.isReadOnly ? "#ececec" : null , border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius:"8px",  margin: "0px 0px 10px 0px" }}>
        {!props.fromViewDeatils && <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 17px/22px Franklin Gothic Medium",
          letterSpacing: "0px", color:" #000000", opacity: "1", marginBottom:"10px"}}>Client Contact Information</Grid>}
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={4}style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <TextField label="Email" placeholder="Email" required={false} customwidth={props.fromViewDeatils ? "217px" : "247px"}
                error={emailError ? true : null}
                disabled={props.isReadOnly ? true : null}
                value={email} 
                handleChange={onEmailChanged}
                helperText= {emailError ? "Please enter a valid email." : null}/>
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={4} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
            <div style={{maxWidth:"275px"}}>
                <TextField label="Phone Number" placeholder="Phone Number" required={false} customwidth={props.fromViewDeatils ? "217px" : "247px"}
                error={phoneNumberError? true : null}
                disabled={props.isReadOnly? true : null}
                value={showPhoneNumber} 
                handleChange={onPhoneNumberChanged}
                helperText= {phoneNumberError ? "The number must be 10 digits or 11 with the international dial code." : null}/></div>
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={4} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
            <div style={{maxWidth:"275px"}}>
                <TextField label="Mobile Number" placeholder="Mobile Number" required={false} customwidth={props.fromViewDeatils ? "217px" : "247px"}
                error={mobileNumberError? true : null}
                disabled={props.isReadOnly? true : null}
                value={showMobileNumber} 
                handleChange={onMobileNumberChanged}
                helperText= {mobileNumberError ? "The number must be 10 digits or 11 with the international dial code." : null}/></div>
            </Grid>
            <Grid item xs={12} sm={6} md={props.isResolveCrmConflicts ? 12 : 4} lg={4} style={{margin: props.isResolveCrmConflicts ? "10px 20px 10px 0px" : "10px 0px 10px 0px", minWidth: props.fromViewDeatils ? "245px" : "275px" }}>
                <SelectorTextField label="Preferred Method of Contact" placeholder="Preferred Method of Contact" required={false} customwidth={props.fromViewDeatils ? "199px" : "229px"}
                select
                disabled={props.isReadOnly? true : null}
                value={preferredMethodOfContact} 
                handleChange={onPreferredMethodOfContactChanged} >
                    {contactMethods?.map((contactMethod) => (
                    <MenuItem key={contactMethod.contactMethodId} value={contactMethod.contactMethodId}>
                        {contactMethod.label}
                    </MenuItem>))}
            </SelectorTextField>
            </Grid>
        </Grid>
    );
})

export default ClientContactInfo;