import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


export const AutoCompleteStyledTextField = styled(TextField)(({disabled}) => ({
  "& label.Mui-focused": {
    color: "#e25928",
    transform: "translate(16px, -10px) scale(1)",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-root.Mui-disabled" : {
    color: "#454748",
  },
  "& .MuiInputLabel-root": {
    zIndex: "0",
    color: "#585454",
    font: "normal normal bold 14px/16px Franklin Gothic Medium",
    transform: "translate(16px, -10px) scale(1)",
  },
  "& .MuiChip-label": {
    color: disabled ? "#575c5c" : "#7F8284",
    font: "normal normal bold 14px/15px Franklin Gothic Medium",
  },
  "& .MuiInputBase-input": {
    width: "262px",
    textAlign: "left",
    font: "normal normal bold 14px/15px Franklin Gothic Medium",
    letterSpacing: "0px",
    color: "#6c6869",
    opacity: "1",
  },
  "& .MuiFormLabel-asterisk": {
    color: "#d32f2f",
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f",
    font: "normal normal bold 14px/15px Segoe UI",
  },
  "& .MuiOutlinedInput-root": {
    width: "275px",
    "& fieldset": {
      border: "1px solid #C4C4C4",
      "& legend": {
        minWidth: "fit-content",
        padding: "0px 15px 0px 5px",
      },
    },
    "&:hover fieldset": {
      border: "1px solid #e25928",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #e25928",
      "& legend": {
        minWidth: "fit-content",
        padding: "0px 15px 0px 5px",
      },
    },
    "&.Mui-disabled fieldset": {
      border: "1px solid #C4C4C4",
    },
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "#6c6869",
  },
  "& .MuiChip-root.Mui-disabled" :{
    opacity: "1"
  },
}));
  