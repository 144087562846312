import React from 'react';
import {PageNotFoundContainer, AccesDeniedTextContainer,NotFoundContainer} from './styled/PageNotFound.styled';
import CustomButton from './Button';
import { Grid } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { MdDesktopAccessDisabled } from "react-icons/md";


export default function AccesDenied() {
    const { instance } = useMsal();

    const handleSignOut = (logoutType) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    return (
        <PageNotFoundContainer>
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <NotFoundContainer> 
                    <MdDesktopAccessDisabled style={{ margin: "0px 15px 5px 0px " , color: "#d41f44" }} size={40} />
                    Access Denied 
                </NotFoundContainer>
                <AccesDeniedTextContainer> 
                    <Grid item xs={12} md={12}>
                        Your account has been authenticated, but there is no role assigned to your account. 
                        Please contact your system administrator to get a valid role assigned to you.
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                        <CustomButton variant="contained" label="Go Back" size="small" onClick={() => handleSignOut("redirect")} />
                    </Grid>
                </AccesDeniedTextContainer>
            </Grid>
        </PageNotFoundContainer>
    );
};