import React from "react";
import { SelectorStyledTextField, SelectorStyledDashBoardTextField } from "./styled/SelectorTextFiels.styled";

export default function SelectorTextField({
  label,
  required,
  placeholder,
  handleChange,
  value,
  disabled,
  error,
  isDashBoard,
  customwidth,
  labelColor,
  ...props
}) {
  return (
    <>
      {isDashBoard ? (
        <SelectorStyledDashBoardTextField
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          label={label}
          value={value}
          onChange={handleChange}
          InputLabelProps={{ shrink: disabled ? true : false }}
          size="small"
          inputProps={{ autoComplete: "off" }}
          labelColor={labelColor} // Pass labelColor prop
          {...props}
        />
      ) : (
        <SelectorStyledTextField
          customwidth={customwidth}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          label={label}
          value={value}
          onChange={handleChange}
          InputLabelProps={{ shrink: disabled ? true : false }}
          size="small"
          inputProps={{ autoComplete: "off" }}
          labelColor={labelColor} // Pass labelColor prop
          {...props}
        />
      )}
    </>
  );
}
