import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "ClientFileService";
const LOOKUPDATAURL = '/GetClientFileLookupData';
const BASICINFORMATION = '/GetClientBasicInformation';
const CONTACTINFORMATION = '/GetClientContactInformation';
const DELETECLIENTFILE = '/DeleteClientFile';
const FINANCIALNFORMATION = '/GetClientFinancialInformation';
const CLIENTFILES = '/GetClientFiles?onlyLastTwoYears=false';

const FILTER = "/filter";
const ACTIVITIES = "/activities";
const COUNT = "counts";
const ASSIGNEDFILES = "assignedfiles";

const INDEXPARAM = "index";
const TAKEPARAM = "take";

const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const GETFAILED = 'Error Occurred.';
const MARKCONFLICTEDSUCCESS = "Client File successfully marked as Conflicted.";
const MARKCONFLICTEDFAILED = 'Failed to Mark Client File as Conflicted.';

const MARKCRMCONFLICTEDSUCCESS = "Client File successfully marked as CRM Conflicted.";
const MARKCRMCONFLICTEDFAILED = 'Failed to Mark Client File as CRM Conflicted.';
const MARKCRMUNCONFLICTEDSUCCESS = "Client File successfully marked as CRM Un-Conflicted.";
const MARKCRMUNCONFLICTEDFAILED = 'Failed to Mark Client File as CRM Un-Conflicted.';
const MARKDCPCONFLICTEDSUCCESS = "Client File successfully marked as Portal Conflicted.";
const MARKDCPCONFLICTEDFAILED = 'Failed to Mark Client File as Portal Conflicted.';
const MARKDCPUNCONFLICTEDSUCCESS = "Client File successfully marked as Portal Un-Conflicted.";
const MARKDCPUNCONFLICTEDFAILED = 'Failed to Mark Client File as Portal Un-Conflicted.';

const CREATECLIENTFILESUCCESS = "Client File successfully Created.";
const CREATECLIENTFILEFAILED = 'Failed to Create Client File.';
const UPDATECLIENTFILESUCCESS = "Client File successfully Updated.";
const UPDATECLIENTFILEFAILED = 'Failed to Update Client File.';
const LINKSUCCESS = "Client File successfully Linked.";
const LINKFAILED = 'Failed to Link Client File.';
const RELINKSUCCESS = "Client File successfully Re-Linked.";
const RELINKFAILED = 'Failed to Re-Link Client File.';
const UNLINKSUCCESS = "Client File successfully Unlinked.";
const UNLINKFAILED = 'Failed to Unlink Client File.';

export async function FilterClientFiles(token, searchBy) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + FILTER;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(searchBy)

    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            return response.json();
        } else {
            toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
            // alert(GETFAILED);
        }
    }).catch(error => {
        toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //alert(GETFAILED); 
        //console.log(error);
    });

    return result;
}

export async function GetMergedClientFile(clientFileId, clientFileCrmId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/GetMergedClientFile?clientFileId=" + clientFileId + "&clientFileCrmId=" + clientFileCrmId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetClientFileFromCrm(crmId,searchByDcp, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/GetCrmClientFile?crmId=" + crmId+"&searchByDcp="+searchByDcp;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetClientFileById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/GetClientFile?id=" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function UpdateClientFile(clientFile, token) {

    var result = false;

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/UpdateClientFile";

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(clientFile)
    }
   
    try{
        const response = await fetch(serviceUrl, options);
        const data = await response.text(); 
    
        if(data)
        {
            toast.success(UPDATECLIENTFILESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return true;
        }
        else
        {
            toast.error(UPDATECLIENTFILEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
            return false;
        }    
    }
    catch(error)
    {
        toast.error(UPDATECLIENTFILEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        return false;
    }  
}

export async function CreateClientFile(clientFile, token) {

    var result = 0;

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/CreateClientFile";

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(clientFile)
    }
    const response = await fetch(serviceUrl, options);
    const data = await response.text();

    if (response.ok) {
        toast.success(CREATECLIENTFILESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(CREATECLIENTFILESUCCESS);
        return data;
    } else {
        toast.error(CREATECLIENTFILEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(CREATECLIENTFILEFAILED);
    }

    return null;
}

export async function MarkClientFileAsDcpConflicted(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/SetDcpConflicted?clientFileId=" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(MARKDCPCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKDCPCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKDCPCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function MarkClientFileAsConflicted(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/SetConflicted?clientFileId=" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(MARKCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function MarkClientFileAsCrmConflicted(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/SetCrmConflicted?clientFileId=" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(MARKCRMCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKCRMCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKCRMCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function ReLinkClientFile(clientFileId, clientFileCrmId, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/Link?clientFileId=" + clientFileId + "&clientFileCrmId=" + clientFileCrmId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            alert(RELINKSUCCESS);
            return response.json();
        } else {
            alert(RELINKFAILED);
        }
    }).catch(error => { alert(RELINKFAILED); 
});

    return result;
}

export async function UnLinkClientFile(clientFileId, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/Link?clientFileId=" + clientFileId + "&clientFileCrmId=";

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            alert(UNLINKSUCCESS);
            return response.json();
        } else {
            alert(UNLINKFAILED);
        }
    }).catch(error => { alert(UNLINKFAILED); 
 });

    return result;
}

export async function LinkClientFile(clientFileId, clientFileCrmId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/Link?clientFileId=" + clientFileId + "&clientFileCrmId=" + clientFileCrmId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(LINKSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            
            return response.json();
        } else {
            toast.error(LINKFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
           
        }
    }).catch(error => {
        toast.error(LINKFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        
    });

    return result;
}

export async function FetchLookupData(token) {

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'GET',
        headers: headers,
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + LOOKUPDATAURL;

    return await fetch(uri, options)
        .then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        })
        .catch(error => {
            //console.error('There was an error!', error);
        });
}

export async function SearchClients(firstName, middleName, lastName, dateOfBirth, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/Search?firstName=" + firstName + "&middleName=" + middleName + "&lastName=" + lastName + "&dateOfBirth=" + dateOfBirth ;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }  

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) 
            { return data }
            // check for error response
            else {    
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText; 
                return Promise.reject(error);
            }
        })
        .catch(error => {  
                if (error?.message == "Failed to fetch"){
                    var data = {
                        "crmErrorStatus": false,
                        "dcpErrorStatus": true,
                        "clientFileSearchResults": []
                    };
                    return data ;
                }      
        });
}

export async function OpenClient(dcpOnlyClientFileId, crmOnlyClientFileCrmId, linkedDcpClientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/OpenClient?dcpOnlyClientFileId=" + dcpOnlyClientFileId + "&crmOnlyClientFileCrmId=" + crmOnlyClientFileCrmId + "&linkedDcpClientFileId=" + linkedDcpClientFileId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            if (response.ok){
            var data = await response.json();
            
             return data;
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = response.error;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            toast.error(error, { position: "top-center", autoClose: 5000, theme: "colored" });
        });
}

export async function ClearDcpConflict(clientFileId, clientFileCrmId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + `/ClearDcpConflict?clientFileId=${clientFileId}`;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ clientFileCrmId: clientFileCrmId })
    }

    var result = await fetch(serviceUrl, options).then(response => {
        if (response.ok) {
            toast.success(MARKDCPUNCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKDCPUNCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKDCPUNCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function ClearCrmConflict(clientFileId, clientFileCrmId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + `/ClearCrmConflict?clientFileId=${clientFileId}`;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ clientFileCrmId: clientFileCrmId })
    }

    var result = await fetch(serviceUrl, options).then(response => {
        if (response.ok) {
            toast.success(MARKCRMUNCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKCRMUNCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKCRMUNCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function CheckDuplicateClients(firstName, middleName, lastName, dateOfBirth, dcpClientId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }

    firstName = (null == firstName) ? "" : firstName;
    middleName = (null == middleName) ? "" : middleName;
    lastName = (null == lastName) ? "" : lastName;

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/HasDuplicate?firstName=" + firstName + "&middleName=" + middleName + "&lastName=" + lastName + "&dateOfBirth=" + (dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : "") + "&clientFileId=" + dcpClientId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetClientFileActivity(token, clientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + ACTIVITIES + `?clientFileId=${clientFileId}&dueDate=${dateValue}`;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}



export async function GetClientBasicInformation(token, clientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + BASICINFORMATION + '?clientFileId=' + clientFileId;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetClientContactInformation(token, clientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CONTACTINFORMATION + '?clientFileId=' + clientFileId;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function DeleteClientFile(token, id) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + DELETECLIENTFILE + '?id='+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) {                
                return data;
            }
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        })
        .then()
        .catch(error => {
            toast.error("Delete Client File was not succesful.", { position: "top-center", autoClose: 5000, theme: "colored" });           
    });
}


export async function GetClientFinancialInformation(token, clientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + FINANCIALNFORMATION + '?clientFileId=' + clientFileId;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}


export async function GetClientFiles(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CLIENTFILES;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}