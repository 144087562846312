import React, { useEffect, useState, useRef } from 'react';
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useValidation } from "../../../hooks/useValidation";
import * as Constants from "../../../helper/constants";
import {Grid} from '@mui/material';
import DataTable from '../../../components/DataTable';
import { useDateFormat } from "../../../hooks/useDateFormat";
import { IoWarning } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";


function AgencyRequestList(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const [isValid] = useValidation();
    const [formatDate] = useDateFormat();
    const navigate = useNavigate();

    const columnHeaders =  [{field:'id', width: 100, headerName:'ID', renderCell: true, editable: false, hide: true }, 
                            {field: 'DocketNumber', width: 150, headerName:'Docket Number', editable: false }, 
                            {field: 'UpcomingCourtDate', width: 160, headerName:'Next Court Date', renderWarning: true, editable: false}, 
                            {field: 'Requestor', width: 180, headerName:'Requestor', editable: false}, 
                            {field: 'AgencyAssignedto', width: 150, headerName:'Agency Assigned to', editable: false}, 
                            {field: 'CourtLocation', width: 280, headerName:'Court Location', editable: false},   
                            {field: 'CourtRoom', width: 100, headerName:'Court Room', editable: false},
                            {field: 'OutcomeStatus', width: 320, headerName: 'Outcome Status', editable: false },
                            {field: 'IsActionRequired', width: 120, headerName: 'Is Action Required', editable: false, hide: true }
                            ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {   field: columnHeader.field, 
                renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderCell ? <> <a href={`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(params.value.split("-")[1] ,props.clientFileId) , null, "page=clientfile")}`}>{params.value}</a> </> :
                    (columnHeader.renderWarning ? (params.row.IsActionRequired == true) ? <> {params.value} {params.value != null && <IoWarning title="Outdated Record" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> } </> : <>{params.value}</> : null)),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide:columnHeader.hide
            },
        );
    });
    
    const rows = [];
    if (props.agencyRequests && props.agencyRequests.length > 0) {
        props.agencyRequests.forEach((agencyRequest) => {
            // if (canRead("agency-request", null)) {
                rows.push(
                    {   id: `AR-${agencyRequest.agencyRequestId}`,
                        DocketNumber: agencyRequest.docketNumber,
                        UpcomingCourtDate: formatDate(agencyRequest.upcomingCourtDate),
                        Requestor: agencyRequest.requestor ? JSON.parse(agencyRequest.requestor).label : null,
                        AgencyAssignedto: agencyRequest.assignedTo ? JSON.parse(agencyRequest.assignedTo).label : null,
                        CourtLocation:agencyRequest.courtLocation ? agencyRequest.courtLocation.label : null,
                        CourtRoom: agencyRequest.courtRoom ? agencyRequest.courtRoom.label : null,
                        OutcomeStatus: agencyRequest.outcomeStatusDetails ? agencyRequest.outcomeStatusDetails.label : "",
                        IsActionRequired: agencyRequest.isActionRequired
                    },
                );
            // } else {
            //     return (<table><tbody><tr><td colSpan={8}>You are not authorized to read this record.</td></tr></tbody></table>);
            // };
        });
    };

    const onRowClicked = (item) => {
        navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(item.row.id.split("-")[1] ,props.clientFileId) , null, "page=clientfile")}`); 
        
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <Grid container style={{ padding: "10px 20px 10px 20px", border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius: "8px" , backgroundColor: (canCreate("agency-request", null)) ? null : "#ececec"}}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
                }}>
                    {props.title}
                    {(canCreate("agency-request", null)) ? (
                        <Button
                            style={{font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "40px"}}
                            size="sm"
                            variant={(canCreate("agency-request", null)) ? "primary" : "secondary"}
                            disabled={(canCreate("agency-request", null)) ? "" : "disabled"}
                            className="ml-auto"
                            href={`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(null ,props.clientFileId) , null, "page=clientfile&id=0")}` }
                        >
                            New Agency Request
                        </Button>
                    ) : null}
                </Grid>
                <DataTable columns={columns} rows={rows} onRowDoubleClick={(canCreate("agency-request", null)) ? onRowClicked : undefined} style={{ backgroundColor: (canCreate("agency-request", null)) ? null : "#ececec" }}/>
            </Grid>
        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                        }}>{props.title}
                        </Grid>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1",
                        }}> ** Save the new Client File to add new Agency Request **
                        </Grid>            
            </Grid>
        );
    }
}

export default AgencyRequestList;
