import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import Backdrop from '../../../components/elements/Loader';
import SubHeader from '../../../components/SubHeader';
import ScreenSideBar from '../../../components/ScreenSideBar';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import { GetServiceDecisionDetailsById } from '../../../services/service-decision-service';
import { tokenRequest } from "../../../auth/authConfig";
import { general, certificateAdminstrationDeatils, docketsAndLeaglIssues, relatedParties, dutyCounselTriage, changeofCounsel, offer, certificate } from './data-grids-column-headers';
import { format } from 'date-fns';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";
import * as Constants from "../../../helper/constants";

function CreateDataGrid({ columnHeaders, dataGridRows, ...props }) {

    let dataGridcolumns = [];
    columnHeaders.forEach((columnHeader) => {
        dataGridcolumns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: columnHeader.renderDate ? (params) => <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null,
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide,
            },
        );
    });

    return (
        <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", backgroundColor: props.isReadOnly ? "#ececec" : null, borderRadius: "8px" }}>
            <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
            }}>{props.title}
            </Grid>
            <DataTable columns={dataGridcolumns} rows={dataGridRows != null ? dataGridRows : []} />
        </Grid>
    );

};


export default function ServiceDecisionDetails(props) {
    const navigate = useNavigate();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();


    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientFileId, setClientFileId] = useState(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)));
    const [interactionId, setIteractionId] = useState(Number(searchParams.get(Constants.INTERACTIONIDQUERYSTRINGPARAM)));
    const [generalDetailsRows, setGeneralDetailsRows] = useState([]);
    const [relatedPartiesRows, setRelatedPartiesRows] = useState([]);
    const [legalIssuesRows, setLegalIssuesRows] = useState([]);
    const [certicateDetailsRows, setCerticateDetailsRows] = useState([]);
    const [changeOfCounselsRows, setChangeOfCounselsRows] = useState([]);
    const [offersRows, setOffersRows] = useState([]);
    const [certificatesRows, setCertificatesRows] = useState([]);
    const screenSideBarList = ['General', 'Certificate Adminstration Details', 'Dockets and Legal Issues', 'Related Parties', 'Change of Counsel', 'Offer', 'Certificate']; //'Duty Counsel Triage',
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [backPage, setBackPage] = useState(searchParams.get("page"));

    useEffect(() => {
        window.scrollTo(0, 0);
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    var serviceDecisionId = searchParams.get(Constants.RELATEDIDQUERYSTRINGPARAM);
                    var clientId = searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM);
                    if ((serviceDecisionId && serviceDecisionId != "") && (clientId && clientId > 0)) {
                        GetServiceDecisionDetailsById(serviceDecisionId, response.accessToken).then((data) => {
                            if (data != null) {
                                const generalDetails = [];
                                generalDetails.push(
                                    {
                                        id: serviceDecisionId,
                                        areaOfLaw: data?.areaOfLaw,
                                        stage: data?.stage,
                                        isUrgent: data?.isUrgent,
                                        matterNumber: data?.matterNumber,
                                        matterType: data?.matterType,
                                        preferredLawyer: data?.preferredLawyer,
                                        serviceDecisionsNumber: data?.serviceDecisionsNumber,
                                        specialization: data?.specialization
                                    }
                                );
                                setGeneralDetailsRows(generalDetails);
                            }
                            setCerticateDetailsRows(data?.certicateDetails);
                            setLegalIssuesRows(data?.legalIssues);
                            setRelatedPartiesRows(data?.relatedParties);
                            if (data?.changeOfCounsels && data.changeOfCounsels != null) {
                                const changeOfCounsels = [];
                                let changeOfCounselsData = data.changeOfCounsels;
                                changeOfCounselsData["id"] = serviceDecisionId;
                                changeOfCounsels.push(changeOfCounselsData);
                                setChangeOfCounselsRows(changeOfCounsels);
                            }
                            setOffersRows(data?.offers);
                            setCertificatesRows(data?.certificates);
                        });
                    }
                }
            }).then(() => { setIsDataReady(true) });
        }
    }, []);

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onCancelClicked = () => {
        if (searchParams.get("backto") && backPage == Constants.INTERACRTION)
            navigate(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(interactionId, clientFileId), null, "page=" + searchParams.get("backto"))}`);
        else if (backPage == Constants.INTERACRTION && (interactionId != null || interactionId != "") && interactionId > 0)
            navigate(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(interactionId, clientFileId), null, "page=" + Constants.CLIENTFILEVIEWDETAILS)}`);
        else if (backPage == Constants.CLIENTFILEDASHBOARD) {
            navigate(`${prepareNavigationParameters(Constants.CLIENTFILEDASHBOARD, new Array(clientFileId.toLocaleString()), null, "page=" + Constants.CLIENTFILEDASHBOARD)}`);
        }
        else {
            navigate(`${prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS, new Array(clientFileId.toLocaleString()), null, "page=" + Constants.CLIENTFILEDASHBOARD)}`);
        }
    };

    if (isDataReady) {
        return (<>
            <Grid container style={{ padding: "5px 20px 0px 20px", margin: "80px 0px 20px 215px" }}>
                <SubHeader
                    title="Service Decision Details"
                    menuItemOneText=""
                    onMenuItemOneClicked={() => { }}
                    isMenuItemOneVisible={false}
                    isMenuItemOneDisabled={false}
                    menuItemTwoText=""
                    onMenuItemTwoClicked={() => { }}
                    isMenuItemTwoVisible={false}
                    menuItemTwoType=""
                    isMenuItemTwoDisabled={false}
                    navItemOneText="Cancel"
                    onNavItemOneClicked={onCancelClicked}
                    isNavItemOneVisible={true}
                    isNavItemOneDisabled={false}
                    navItemTwoText=""
                    onNavItemTwoClicked={() => { }}
                    isNavItemTwoVisible={false}
                    isNavItemTwoDisabled={false}
                    navItemThreeText=""
                    onNavItemThreeClicked={() => { }}
                    isNavItemThreeVisible={false}
                    isNavItemThreeDisabled={false}
                    navItemFourText=""
                    onNavItemFourClicked={() => { }}
                    isNavItemFourVisible={false}
                    isNavItemFourDisabled={false} />
                <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} />
                <Grid item xs={12} md={12}>
                    <div id="scrollByClickedItem1" style={{ scrollMarginTop: "168px" }}>
                        <CreateDataGrid title="General" columnHeaders={general} dataGridRows={generalDetailsRows} />
                    </div>
                    <div id="scrollByClickedItem2" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Certificate Adminstration Details" columnHeaders={certificateAdminstrationDeatils} dataGridRows={certicateDetailsRows} />
                    </div>
                    <div id="scrollByClickedItem3" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Dockets and Legal Issues" columnHeaders={docketsAndLeaglIssues} dataGridRows={legalIssuesRows} />
                    </div>
                    <div id="scrollByClickedItem4" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Related Parties" columnHeaders={relatedParties} dataGridRows={relatedPartiesRows} />
                    </div>
                    {/* <div id="scrollByClickedItem5" style={{ scrollMarginTop: "161px" }}>
                    <CreateDataGrid title="Duty Counsel Triage" columnHeaders={dutyCounselTriage} dataGridRows={[]}/>
                </div> */}
                    <div id="scrollByClickedItem5" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Change of Counsel" columnHeaders={changeofCounsel} dataGridRows={changeOfCounselsRows} />
                    </div>
                    <div id="scrollByClickedItem6" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Offer" columnHeaders={offer} dataGridRows={offersRows} />
                    </div>
                    <div id="scrollByClickedItem7" style={{ scrollMarginTop: "166px" }}>
                        <CreateDataGrid title="Certificate" columnHeaders={certificate} dataGridRows={certificatesRows} />
                    </div>
                </Grid>
            </Grid>
        </>
        );
    } else {
        return (
            <Backdrop />
        );
    }
};


