import React, { useEffect, useState, useHistory } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../auth/authConfig";
import { GetClientFileById } from "../../services/client-file.service";
import * as Constants from "../../helper/constants";
import { MdNavigateNext } from "react-icons/md";
import { useNavigationParameters } from "../../hooks/useNavigationParameters";

function CustomBreadcrumb() {

  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));
  const [backPage, setBackPage] = useState(searchParams.get("page"));
  const [rootPage, setRootPage] = useState(searchParams.get("root"));

  const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
  const [clientName, setClientName] = useState("");

  const [firstLevel, setFirstLevel] = useState(false);
  const [secondLevel, setSecondLevel] = useState(false);
  const [thirdLevel, setThirdLevel] = useState(false);
  const [fourthLevel, setFourthLevel] = useState(false);
  const [fifthLevel, setFifthLevel] = useState(false);

  const [firstLevelDisplayText, setFirstLevelDisplayText] = useState("");
  const [secondLevelDisplayText, setSecondLevelDisplayText] = useState("");
  const [thirdLevelDisplayText, setThirdLevelDisplayText] = useState("");
  const [fourthLevelDisplayText, setFourthLevelDisplayText] = useState("");
  const [fifthLevelDisplayText, setFifthLevelDisplayText] = useState("");

  const [firstLevelUrl, setFirstLevelUrl] = useState("");
  const [secondLevelUrl, setSecondLevelUrl] = useState("");
  const [thirdLevelUrl, setThirdLevelUrl] = useState("");
  const [fourthLevelUrl, setFourthLevelUrl] = useState("");

  //First Level Display Text
  const DASHBOARDDISPLAYTEXT = "Dashboard";
  const SEARCHCLIENTDISPLAYTEXT = "Search Client";
  const REVIEWDASHBOARDDISPLAYTEXT = "Review Dashboard";

  //Second Level Display Text
  const MYFILESDISPLAYTEXT = "My Files";
  const FILESTOASSIGNDISPLAYTEXT = " Files to Assign";
  const ASSIGNEDFILESDISPLAYTEXT = " Assigned Files";
  const REVIEWPORTALCLIENTFILESDISPLAYTEXT = "Review Portal Client Files";
  const REVIEWCRMCLIENTFILESDISPLAYTEXT = "Review CRM Client Files";
  const REVIEWALLINTERACTIONSDISPLAYTEXT = " Review All Interactions";


  //Third Level Display Text
  const RECENTACTIVITIESDISPLAYTEXT = "Recent Activities:";
  const RESOLVEPORTALCLIENTFILESDISPLAYTEXT = "Review Duplicate Portal File";
  const RESOLVECRMCLIENTFILESDISPLAYTEXT = "Review CRM-Portal Linking";
  const RESOLVEINTERACTIONSDISPLAYTEXT = " Review Interactions";

  //Fourth Level Page Display Text
  const VIEWDETAILSDISPLAYTEXT = "Client Details";

  //Fifth Level Page Display Text
  const NOTEDETAILS = "Note";
  const REFERRALDETAILS = "Referral";
  const AGENCYREQUESTDETAILS = "Agency Request";
  const JPBAILOUTCOMEDETAILS = "JP Bail Outcome";
  const INTERACTIONDETAILS = "Interaction";
  const SERVICEDECISIONDETAILS = "Service Decision Details";
  const CLIENTGATEWAY = "Client Gateway";

  const location = useLocation();

  useEffect(() => {

    GetClientNameById();
    SetRootPageDetails(null);
    SetLevel2PageDetails();
   
    var isThirdLevel = SetLevel3PageDetails();
    if (isThirdLevel) {
      SetRootPageDetails(isThirdLevel);
    }

    var isFourthLevel = SetLevel4PageDetails();
    if (isFourthLevel && clientFileId) {
      SetLevel3PageDetails(Constants.CLIENTFILEDASHBOARD);
    }

    var isFifthLevel = GetLevel5PageDetails();
    
    if (isFifthLevel) {
      SetRootPageDetails(Constants.SEARCHCLIENTS);
      SetLevel3PageDetails(Constants.CLIENTFILEDASHBOARD);
      SetLevel4PageDetails(Constants.CLIENTFILEVIEWDETAILS);
    }


  }, [clientName]);

  function handleClick(event) {
  }

  function GetClientNameById() {
    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then(async (response) => {
        if (response) {
          if (clientFileId > 0) {
            await GetClientFileById(clientFileId, response.accessToken).then(data => {
              setClientName(data.clientBasicInformation.lastName + ", " + data.clientBasicInformation.firstName);
            });
          }
        }
      });
    }

  }

  //New Changes Methods

  function SetRootPageDetails(selectedPage) {

    if (backPage == Constants.TOPMYFILES || selectedPage == Constants.TOPMYFILES || backPage == Constants.MYFILES || location.pathname == Constants.MYFILESPAGEURL || rootPage == Constants.TOPMYFILES) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPMYFILES))
      setFirstLevelDisplayText(DASHBOARDDISPLAYTEXT);
    }
    else if (backPage == Constants.TOPFILESTOASSIGN || selectedPage == Constants.TOPFILESTOASSIGN || backPage == Constants.FILESTOASSIGN || location.pathname == Constants.FILESTOBEASSIGNEDPAGEURL  || rootPage == Constants.TOPFILESTOASSIGN) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPFILESTOASSIGN))
      setFirstLevelDisplayText(DASHBOARDDISPLAYTEXT);
    }
    else if (backPage == Constants.TOPASSIGNEDFILES || selectedPage == Constants.TOPASSIGNEDFILES || backPage == Constants.ASSIGNEDFILES || location.pathname == Constants.ASSIGNEDFILESPAGEURL  || rootPage == Constants.TOPASSIGNEDFILES) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPASSIGNEDFILES))
      setFirstLevelDisplayText(DASHBOARDDISPLAYTEXT);
    }
    else if (backPage == Constants.SEARCHCLIENTS || selectedPage == Constants.SEARCHCLIENTS) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.SEARCHCLIENTS))
      setFirstLevelDisplayText(SEARCHCLIENTDISPLAYTEXT);      
    }
    else if (backPage == Constants.TOPDCPREVIEWDASHBOARD || selectedPage == Constants.TOPDCPREVIEWDASHBOARD || backPage == Constants.DCPREVIEWDASHBOARD || location.pathname == Constants.DCPCONFLICTEDCLIENTFILESPAGEURL) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPDCPREVIEWDASHBOARD))
      setFirstLevelDisplayText(REVIEWDASHBOARDDISPLAYTEXT);
    }
    else if (backPage == Constants.TOPCRMREVIEWDASHBOARD || selectedPage == Constants.TOPCRMREVIEWDASHBOARD || backPage == Constants.CRMREVIEWDASHBOARD || location.pathname == Constants.CRMCONFLICTDASHBOARDPAGEURL) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPCRMREVIEWDASHBOARD))
      setFirstLevelDisplayText(REVIEWDASHBOARDDISPLAYTEXT);
    }
    else if (backPage == Constants.TOPINTREVIEWDASHBOARD || selectedPage == Constants.TOPINTREVIEWDASHBOARD || backPage == Constants.INTREVIEWDASHBOARD || location.pathname == Constants.INERACTIONCCONFLICTEDFILESPAGEURL) {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.TOPINTREVIEWDASHBOARD))
      setFirstLevelDisplayText(REVIEWDASHBOARDDISPLAYTEXT);
    }
    else {
      setFirstLevel(true);
      setFirstLevelUrl(prepareNavigationParameters(Constants.SEARCHCLIENTS))
      setFirstLevelDisplayText(SEARCHCLIENTDISPLAYTEXT);      
    }  
  }

  function SetLevel2PageDetails() {
    if (backPage == Constants.MYFILES || location.pathname == Constants.MYFILESPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.MYFILES, null))
      setSecondLevelDisplayText(MYFILESDISPLAYTEXT);
    }
    if (backPage == Constants.FILESTOASSIGN || location.pathname == Constants.FILESTOBEASSIGNEDPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.FILESTOASSIGN, null))
      setSecondLevelDisplayText(FILESTOASSIGNDISPLAYTEXT);
    }
    if (backPage == Constants.ASSIGNEDFILES || location.pathname == Constants.ASSIGNEDFILESPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.ASSIGNEDFILES, null))
      setSecondLevelDisplayText(ASSIGNEDFILESDISPLAYTEXT);
    }
    if (backPage == Constants.DCPREVIEWDASHBOARD || location.pathname == Constants.DCPCONFLICTEDCLIENTFILESPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.DCPREVIEWDASHBOARD, null))
      setSecondLevelDisplayText(REVIEWPORTALCLIENTFILESDISPLAYTEXT);
    }
    if (backPage == Constants.CRMREVIEWDASHBOARD || location.pathname == Constants.CRMCONFLICTDASHBOARDPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.CRMREVIEWDASHBOARD, null));
      setSecondLevelDisplayText(REVIEWCRMCLIENTFILESDISPLAYTEXT);
    }
    if (backPage == Constants.INTREVIEWDASHBOARD || location.pathname == Constants.INERACTIONCCONFLICTEDFILESPAGEURL) {
      setSecondLevel(true);
      setSecondLevelUrl(prepareNavigationParameters(Constants.INTREVIEWDASHBOARD, null))
      setSecondLevelDisplayText(REVIEWALLINTERACTIONSDISPLAYTEXT);
    }

  }

  function SetLevel3PageDetails(passedParam) {
    if (passedParam == Constants.CLIENTFILEDASHBOARD || backPage == Constants.CLIENTFILEDASHBOARD || location.pathname == Constants.CLIENTFILEDASHBOARDPAGEURL) {
      setThirdLevel(true);
      setThirdLevelUrl(prepareNavigationParameters(Constants.CLIENTFILEDASHBOARD))
      setThirdLevelDisplayText(`${RECENTACTIVITIESDISPLAYTEXT} ${clientName}`);
      return Constants.SEARCHCLIENTS;
    }
    if (backPage == Constants.RESOLVEDCPCONFLICTS || location.pathname == Constants.DCPCONFLICTRESOLUTIONPAGEURL) {

      setThirdLevel(true);
      setThirdLevelUrl(prepareNavigationParameters(Constants.RESOLVEDCPCONFLICTS))
      setThirdLevelDisplayText(RESOLVEPORTALCLIENTFILESDISPLAYTEXT);
      return Constants.TOPDCPREVIEWDASHBOARD;
    }
    if (backPage == Constants.RESOLVECRMCONFLICT || location.pathname == Constants.CRMCONFLICTRESOLUTIONPAGEURL) {

      setThirdLevel(true);
      setThirdLevelUrl(prepareNavigationParameters(Constants.RESOLVECRMCONFLICT))
      setThirdLevelDisplayText(RESOLVECRMCLIENTFILESDISPLAYTEXT);
      return Constants.TOPCRMREVIEWDASHBOARD;
    }
    if (backPage == Constants.RESOLVEINTERACTIONCONFLICT || location.pathname == Constants.INERACTIONCONFLICTRESOLUTIONPAGEURL) {

      setThirdLevel(true);
      setThirdLevelUrl(prepareNavigationParameters(Constants.RESOLVEINTERACTIONCONFLICT))
      setThirdLevelDisplayText(RESOLVEINTERACTIONSDISPLAYTEXT);
      return Constants.TOPINTREVIEWDASHBOARD;
    }
    return null;
  }

  function SetLevel4PageDetails(passedParam) {
    if (passedParam == Constants.CLIENTFILEVIEWDETAILS || backPage == Constants.CLIENTFILEVIEWDETAILS || location.pathname == Constants.CLIENTFILEVIEWDETAILSPAGEURL) {
      setFourthLevel(true);
      setFourthLevelUrl(prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS))
      setFourthLevelDisplayText(VIEWDETAILSDISPLAYTEXT);

      return true;
    }
  }

  function GetLevel5PageDetails() {

    switch (location.pathname) {

      case Constants.NOTEDETAILSPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(NOTEDETAILS);
        return true;
      case Constants.REFERRALDETAILSPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(REFERRALDETAILS);
        return true;
      case Constants.JPBAILOUTCOMEPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(JPBAILOUTCOMEDETAILS);
        return true;
      case Constants.AGENCYREQUESTDETAILSPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(AGENCYREQUESTDETAILS);
        return true;
      case Constants.INTERACTIONPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(INTERACTIONDETAILS);
        return true;
      case Constants.SERVICEDECISIONDETAILSPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(SERVICEDECISIONDETAILS);
        return true;
      case Constants.CLIENTGATEWAYPAGEURL:
        setFifthLevel(true);
        setFifthLevelDisplayText(CLIENTGATEWAY);
        return true;
      default:
        return false;
    }
  }

  return (
    <div align="center">


      <Breadcrumbs separator={<MdNavigateNext size={16} style={{ margin: "0px -10px 0px -8px" }} />} style={{ font: "normal normal normal 12px/14px Franklin Gothic Medium" }} onClick={handleClick}>

        {firstLevel &&
          <Link underline="hover" color="inherit" href={firstLevelUrl} >
            {firstLevelDisplayText}
          </Link>}
        {secondLevel &&
          <Link underline="hover" color="inherit" href={secondLevelUrl} >
            {secondLevelDisplayText}
          </Link>}
        {thirdLevel &&
          <Link underline="hover" color="inherit" href={thirdLevelUrl} >
            {thirdLevelDisplayText}
          </Link>}
        {fourthLevel &&
          <Link underline="hover" color="inherit" href={fourthLevelUrl}>
            {fourthLevelDisplayText}
          </Link>}
        {fifthLevel &&
          <Link underline="hover" color="inherit" >
            {fifthLevelDisplayText}
          </Link>}

      </Breadcrumbs>

    </div>
  );
}



export default CustomBreadcrumb;