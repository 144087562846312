import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {device} from '../../utils/deviceBreakpoints';

export const ButtonStyled = styled(Button)({
  height: '34px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  background:  '#D41F44 0% 0% no-repeat padding-box;',
  borderColor: '#D41F44',
  [`@media ${device.mobileS}`] : {
    font: "normal normal bold 14px/16px Franklin Gothic Medium"
  },
  [`@media ${device.mobileM}`] : {
    font: "normal normal bold 14px/16px Franklin Gothic Medium"
  },
  [`@media ${device.mobileL}`] : {
    font: "normal normal bold 14px/16px Franklin Gothic Medium"
  },
  [`@media ${device.tablet}`]  :{
    font: "normal normal bold 15px/17px Franklin Gothic Medium"
  },
  [`@media ${device.laptop}`] : {
    font: "normal normal bold 16px/18px Franklin Gothic Medium"
  },
  '&:hover': {
    background:  '#D41F44 0% 0% no-repeat padding-box;',
    borderColor: '#D41F44',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    background:  '#D41F44 0% 0% no-repeat padding-box;',
    borderColor: '#D41F44',
  },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(212, 31, 68, 1)',
  // },
});

export const TextButtonStyled = styled(Button)({
  
  textTransform: 'none',
  color: '#D41F44',
  font: 'normal normal normal 14px/17px Franklin Gothic Medium',
});