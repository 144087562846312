import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TbDisabled2 } from 'react-icons/tb';

const SwitchStyled = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
  '& .Mui-focusVisible' : {
    color: "#e25928 !important",
},
}));

export default function StyledSwitch({checked,onChange,style, disabled, testId}) {
  return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography style={style ? style : {textAlign: "left",  font: "normal normal normal 14px/25px Franklin Gothic Medium",letterSpacing: "0px", color: " #585454", opacity: "1"}}>No</Typography>
        <SwitchStyled checked={checked} onChange={onChange} inputProps={{ 'aria-label': 'ant design', 'data-testid': testId }} disabled={disabled} />
        <Typography style={style ? style : {textAlign: "left",  font: "normal normal normal 14px/25px Franklin Gothic Medium",letterSpacing: "0px", color: " #585454", opacity: "1"}} >Yes</Typography>
      </Stack>
  );
}