import {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import {PageNotFoundContainer, TextContainer,NotFoundContainer} from './styled/PageNotFound.styled';
import { useNavigate, useLocation } from "react-router-dom";
import CustomButton from './Button';
import { Grid } from '@mui/material';
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

export default function PageNotFound() {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const [userRole, setUserRole] = useState(null); // Initialize with null or default value

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            if (currentAccount.idTokenClaims.roles) {
                //var displayRole =  displayNames.filter((user) => user.role == currentAccount.idTokenClaims.roles[0].toString());
                setUserRole(currentAccount.idTokenClaims.roles[0].toString());
            } else {
                setUserRole("No Role");
                navigate("/accessdenied");
            }
        }
    }, [instance, navigate]);

    const handleOnClick = () => {
        const from = location.state?.from || '/';
        navigate(from);
    }
    
    return (
        <PageNotFoundContainer>
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <NotFoundContainer> 
                    <MdOutlineDoNotDisturbAlt style={{ margin: "0px 15px 8px 0px " , color: "#d41f44" }} size={42} />
                    Page Not Found 
                </NotFoundContainer>
                <TextContainer> WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND. 
                    <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                        <CustomButton variant="contained" label="Go Back" size="small" onClick={handleOnClick} />
                    </Grid>
                </TextContainer>
            </Grid>
        </PageNotFoundContainer>
    );
};