import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/reviewdashboard/interactions";
const REVIEWEDCLIENTFILENAME = "/clientfiles";
const RESOLVE = "/resolve";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const GETFAILED = 'Error Occurred.';
const CRMCLIENTFILEDOESNOTEXISTS = 'CRM Client File does not exists.';


export async function RetrieveConflictedInteractions(token, index, take) {

    var dateValue = new Date().toISOString();    

    const PAGINATION = `?index=${index}&take=${take}&dueDate=${dateValue}`;

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + PAGINATION;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            return response.json();
        } else {
            toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert(GETFAILED);
        }
    }).catch(error => {
        toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //alert(GETFAILED); 
        //console.log(error);
    });

    return result;
}

export async function GetConflictedInteractionById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    var dateValue = new Date().toISOString();    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id+`?dueDate=${dateValue}`;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetDcpUnConflictedClientFiles(token, interactionId) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

      
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + REVIEWEDCLIENTFILENAME + `?interactionId=${interactionId}`;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers

    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(CRMCLIENTFILEDOESNOTEXISTS, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(CRMCLIENTFILEDOESNOTEXISTS, { position: "top-center", autoClose: 5000, theme: "colored" });

    });

    return result;
}

export async function ResolveConflictedInteraction(token, interactionId, clientId, status) {

    const queryParam = `?interactionId=${interactionId}&clientId=${clientId}&status=${status}`;
    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + RESOLVE + queryParam;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            return response.json();
        } else {
            toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert(GETFAILED);
        }
    }).catch(error => {
        toast.error(GETFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //alert(GETFAILED);
        //console.log(error);
    });

    return result;
}