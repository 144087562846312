import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import StyledButton from '../../../components/elements/StyledButton';
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import Backdrop from '../../../components/elements/Loader';
import { tokenRequest } from "../../../auth/authConfig";
import { RetrieveConflictedInteractions } from "../../../services/interaction-conflict-dashboard.service";
import SubHeader from '../../../components/SubHeader';
import * as Constants from "../../../helper/constants";
import {format} from 'date-fns';
import { IoWarning } from "react-icons/io5";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

function ConflictedInteractions() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isValid] = useValidation();
    const navigate = useNavigate();

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [isDataReady, setIsDataReady] = useState(false);
    const interactionConflictResolutionPage =Constants.INERACTIONCONFLICTRESOLUTIONPAGEURL+ "?page=conflictedinteractions";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        PerformSearchClickDetails();
        setInitialGridColumns();
    }, [instance]);

    const setInitialGridColumns = () => {
        const columns = [];

        const columnHeaders = [{ headerName: 'Interaction ID', width: 90, field: 'id',  editable: false },
        { headerName: 'Docket number', width: 250, field: 'docketNumber', editable: false },
        { headerName: 'Court location', width: 250, field: 'courtLocation', editable: false },
        { headerName: 'Upcoming court date',type: 'date', width: 150, field: 'upcomingCourtDate',renderDate: true, editable: false, renderWarning: true },
        { headerName: 'Court room', width: 180, field: 'courtRoom',editable: false },
        { headerName: 'Assigned To', width: 180, field: 'assignedTo',editable: false },
        { headerName: 'Outcome Status', width: 180, field: 'outcomeStatus',editable: false },
        { headerName: 'Review Interaction', width: 180, field: 'button', renderButton: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => (columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={(event) => {
                        navigate(`${prepareNavigationParameters(Constants.RESOLVEINTERACTIONCONFLICT, new Array(params.row.id.split("-")[1]), null)}&page=${Constants.INTREVIEWDASHBOARD}`);}}>Review</StyledButton>: 
                        (columnHeader.renderWarning ? (params.row.isOutdated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} {params.value != null && <IoWarning title="Outdated Record" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> } </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }
    //#endregion

    async function PerformSearchClickDetails() {

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await RetrieveConflictedInteractions(response.accessToken, 0, 0).then((rowData) => {                       
                        if (rowData) {
                            setGridRows(rowData);
                        }
                    }).then(() => {setIsDataReady(true)});
                }
            });

        }      

    }

    const onCancelClicked = () => {
        navigate(`${prepareNavigationParameters(Constants.TOPINTREVIEWDASHBOARD, null, null)}`);
    };

    if (isDataReady) {
    return (
        <>
            {isAuthenticated && (canRead("interaction-conflict-dashboard", null)) &&
                <Grid container style={{ padding: "20px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                    <SubHeader
                        title="Review Interactions"
                        menuItemOneText=""
                        onMenuItemOneClicked={false}
                        isMenuItemOneVisible={false}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible={false}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}
                        navItemOneText="Cancel"
                        onNavItemOneClicked={onCancelClicked}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={false}
                        navItemTwoText=""
                        onNavItemTwoClicked=""
                        isNavItemTwoVisible={false}
                        isNavItemTwoDisabled={false}
                        navItemThreeText=""
                        onNavItemThreeClicked=""
                        isNavItemThreeVisible={false}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />

                    <DataTable columns={gridColumns} rows={gridRows} />

                    <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
                </Grid>
            }</>
    );
    } else {
    return (
        <Backdrop />
    );
}

}

export default ConflictedInteractions;