import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "NoteService";
const GETCLIENTDETAILNOTES = "/GetClientDetailNotes";
const UPDATENOTE = "/UpdateNote";
const DELETENOTE = "/DeleteNote";
const CREATENOTE = "/CreateNote";
const GETNOTE = "/GetNote";
const GETNOTELOOKUPDATA = "/GetNoteLookupData"
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const CREATESUCCESS = "Note successfully created.";
const UPDATESUCCESS = "Note successfully updated.";

export async function GetClientDetailNotes(agencyRequestId, noteType, onlyLastTwoYears, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETCLIENTDETAILNOTES + '?recordId=' + agencyRequestId + "&noteType=" + noteType + '&onlyLastTwoYears=' + onlyLastTwoYears;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 

            var data =await response.json();
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}


export async function UpdateClientFileNotes(note, token) {

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(note)
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + UPDATENOTE;

    return await fetch(uri, options)
        .then(response => {
            if (response.ok) {
                toast.success(UPDATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});               
                
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then()
        .catch(error => {
            toast.error("Update Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"});
            //appInsights.trackException({ error: new Error("Update Note was not succesful."), severityLevel: SeverityLevel.Error });
        });
}

export async function DeleteClientFileNotes(noteId, token) {

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'DELETE',
        headers: headers
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + DELETENOTE + "?id=" + noteId;

    return await fetch(uri, options)
        .then(async response => {
            var data =await response.json(); ;
            if (response.ok) {
                return data;
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then()
        .catch(error => {
            toast.error("Delete Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"});
            //appInsights.trackException({ error: new Error("Delete Note was not succesful."), severityLevel: SeverityLevel.Error });
        });
            //alert("Delete Note was not succesful."));
}

export async function CreateClientFileNotes(note, token) {

    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(note)
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + CREATENOTE;
    return await fetch(uri, options)
        .then(async response => {
            if (response.ok) {
                var data = await response.json();
                toast.success(CREATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});               
                return data;
            } else {
                throw new Error('Something went wrong ...');
            }
        })
        .then()
        .catch(error => {
            toast.error("Create Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"});
            //appInsights.trackException({ error: new Error("Create Note was not succesful."), severityLevel: SeverityLevel.Error });
        });
            //alert("Create Note was not succesful."));
}

export async function GetNote(noteId, token) {
    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'GET',
        headers: headers,
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + GETNOTE +"?id=" + noteId;

    return await fetch(uri, options)
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        })
        .catch(error => {
            //console.error('There was an error!', error);
            //appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
        });

}

export async function GetNoteLookupData(token) {
    const headers = new Headers();
    const bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');

    const options = {
        method: 'GET',
        headers: headers,
    }

    const uri = appconfig.API_SERVER_URL + SERVICENAME + GETNOTELOOKUPDATA;

    return await fetch(uri, options)
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }     
            
            return data;
        })
        .catch(error => {
            //console.error('There was an error!', error);
            //appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
        });
}