import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Backdrop from '../../../components/elements/Loader';
import TextField from "../../../components/TextField";
import CustomDatePicker from '../../../components/DatePicker';
import StyledButton from '../../../components/elements/StyledButton';
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import { tokenRequest } from "../../../auth/authConfig";
import { RetrieveConflictedClientFiles } from "../../../services/crm-conflict-dashboard.service";
import SubHeader from '../../../components/SubHeader';
import * as Constants from "../../../helper/constants";
import {format} from 'date-fns';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

function CrmConflictedClientFiles() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isValid] = useValidation();
    const navigate = useNavigate();

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [cardKey, setCardKey] = useState(searchParams.get("cardKey")?searchParams.get("cardKey"):"1");
    const [isDataReady, setIsDataReady] = useState(false);
    const crmConflictResolutionPage = Constants.CRMCONFLICTRESOLUTIONPAGEURL+"?page=crmconflictedclientfiles";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        PerformSearchClickDetails();
        setInitialGridColumns();

    }, [instance]);

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 90, field: 'id', editable: false },
        { headerName: 'First Name', width: 250, field: 'firstName', editable: false },
        { headerName: 'Middle Name', width: 250, field: 'middleName', editable: false },
        { headerName: 'Last Name', width: 250, field: 'lastName', editable: false },
        { headerName: 'Date of Birth',type: 'date', width: 110, field: 'dateOfBirth', renderDate: true, editable: false },
        { headerName: 'Review Client File', width: 180, field: 'button', renderButton: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => ((columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={(event) => {
                        navigate(`${prepareNavigationParameters(Constants.RESOLVECRMCONFLICT, new Array(params.row.id.split("-")[1]), null)}&page=${Constants.CRMREVIEWDASHBOARD}`);}}>Review</StyledButton> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);

    }



    function GetEmptySearchBy() {
        const firstNameSearch = { columnName: "FirstName", searchValue: '' };
        const middleNameSearch = { columnName: "MiddleName", searchValue: '' };
        const lastNameSearch = { columnName: "LastName", searchValue: '' };
        const dateOfBirthSearch = { columnName: "DateOfBirth", searchValue: "" };

        return [firstNameSearch, middleNameSearch, lastNameSearch, dateOfBirthSearch];
    }
    //#endregion




    async function PerformSearchClickDetails() {

        const searchBy = GetEmptySearchBy();


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {

                    await RetrieveConflictedClientFiles(response.accessToken, GetEmptySearchBy(), 0, 0).then((rowData) => {
                        setGridRows(rowData);
                    }).then(() => {setIsDataReady(true)});

                }
            });

        }
        setInitialGridColumns();

    }

    const onCancelClicked = () => {
        navigate(`${prepareNavigationParameters(Constants.TOPINTREVIEWDASHBOARD, null, null)}`);
    };




    
    if (isDataReady) {
    return (
        <>
            {isAuthenticated && (canRead("crm-conflict-dashboard", null)) &&

                <Grid container style={{ padding: "20px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                    <SubHeader
                        title="Review CRM Client Files"
                        menuItemOneText=""
                        onMenuItemOneClicked={false}
                        isMenuItemOneVisible={false}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible={false}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}
                        navItemOneText="Cancel"
                        onNavItemOneClicked={onCancelClicked}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={false}
                        navItemTwoText=""
                        onNavItemTwoClicked=""
                        isNavItemTwoVisible={false}
                        isNavItemTwoDisabled={false}
                        navItemThreeText=""
                        onNavItemThreeClicked=""
                        isNavItemThreeVisible={false}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />   

                    <DataTable columns={gridColumns} rows={gridRows} />

                    <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
                </Grid>
            }</>
    );
    } else {
    return (
        <Backdrop />
    );
}

}

export default CrmConflictedClientFiles;