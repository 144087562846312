import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/agencyrequests";
const CREATEREQUESTNAME = "/create";

const GETMETADATAREQUESTNAME = "/metadata";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";

const CREATEFAILED = 'Create Agency Request Failed.';
const CREATESUCCESS = "Agency Request is successfully created.";
const CREATESUCCESSWITHNOTIFICATIONSUCCESS = "Agency Request is successfully created and notification sent.";
const CREATESUCCESSWITHNOTIFICATIONFAILED = "Agency Request is successfully created and notification was not sent.";

const UPDATESUCCESS = "Agency Request is successfully updated.";
const UPDATEFAILED = 'Update Agency Request Failed.';
const UPDATESUCCESSWITHNOTIFICATIONSUCCESS = "Agency Request is successfully updated and notification sent.";
const UPDATESUCCESSWITHNOTIFICATIONFAILED = "Agency Request is successfully updated and notification was not sent.";





export async function CreateAgencyRequest(agencyRequest, token) {
    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(agencyRequest)
    }

    const response = await fetch(serviceUrl, options);
    const data = await response.text();   

    var id = data.split(",")[0]
    var status = data.split(",")[1]

    if (response.ok) {
        if (parseInt(status) == 0) {
            toast.error(CREATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });            
        }
        else if (parseInt(status) == 1) {
            toast.success(CREATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });           
        }
        else if (parseInt(status) == 2) {
            toast.success(CREATESUCCESSWITHNOTIFICATIONSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });           
        }
        else {
            toast.error(CREATESUCCESSWITHNOTIFICATIONFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });           
        }

        return id;
    } else {
        toast.error(CREATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });       
    }

}


export async function UpdateAgencyRequest(agencyRequest, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + agencyRequest.agencyRequestId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(agencyRequest)
    }

    var result = await fetch(serviceUrl, options).then(async response => {
        var data = await response.json();
        if (response.ok) {
            if (data == 0) {
                toast.error(UPDATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert(UPDATEFAILED);
            }
            else if (data == 1) {
                toast.success(UPDATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert(UPDATESUCCESS);
            }
            else if (data == 5 || data == 4 || data == 3 || data == 2) {
                toast.success(UPDATESUCCESSWITHNOTIFICATIONSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert(UPDATESUCCESSWITHNOTIFICATIONSUCCESS);
            }
            else {
                toast.error(CREATESUCCESSWITHNOTIFICATIONFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
                // alert(CREATESUCCESSWITHNOTIFICATIONFAILED);
            }
            return true;
        } else {
            toast.error(UPDATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert(UPDATEFAILED);
        }
    }).catch(error => {
        toast.error(UPDATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //alert(UPDATEFAILED); 
        //console.log(error);
    });

    return result;
}

export async function GetAgencyRequestById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetAllAgencyRequest(clientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "?clientFileId=" + clientFileId + '&onlyLastTwoYears=true';

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.log(error);
        });
}

export async function GetAgencyRequestMetaData(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETMETADATAREQUESTNAME;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function DeleteAgencyRequestById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            if (response.ok) {
                return response.json();
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}
