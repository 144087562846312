import styled from "styled-components";
import Button from "@mui/material/Button";
import {device} from '../../utils/deviceBreakpoints';

export const HeaderBar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  z-index:51;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
  @media ${device.mobileS} {
    top: 20px;
    width: 200px;
    height: 34px;
  }
  @media ${device.mobileM} {
    top: 20px;
    width: 200px;
    height: 34px;
  }
  @media ${device.mobileL} {
    top: 20px;
    width: 200px;
    height: 34px;
  }
  @media ${device.tablet} {
    top: 17px;
    width: 220px;
    height: 36px;
  }
  @media ${device.laptop} {
    width: 237px;
    height: 40px;
  }
  opacity: 1;
  cursor: pointer;
`;

export const NavItem = styled.div`
  padding: 6px 0px 6px 0px;
  color: #848484;
  font: normal normal 600 1vw/1.1vw Franklin Gothic Medium;
  @media ${device.mobileS} {
    margin-right: 10px;
  }
  @media ${device.mobileM} {
    margin-right: 10px;
  }
  @media ${device.mobileL} {
    margin-right: 10px;
  }
  @media ${device.tablet} {
    margin-right: 15px;
  }
  @media ${device.laptop} {
    margin-right: 20px;
  }
`;

export const NavContainer = styled.div`
  position: absolute;
  right: 10px;
  width: fit-content;
  margin: auto;
  height: fit-content;
  text-align: center;
  display: flex;  
  @media ${device.mobileS} {
    bottom: ${props => props.$primary ? "5px" : "20px"};
  }
  @media ${device.mobileM} {
    bottom: ${props => props.$primary ? "5px" : "20px"};
  }
  @media ${device.mobileL} {
    bottom: ${props => props.$primary ? "5px" : "20px"};
  }
  @media ${device.tablet} {
    bottom: ${props => props.$primary ? "5px" : "20px"};
  }
  @media ${device.laptop} {
    bottom: 20px;
  }
`;

export const UserDetailContainer = styled.div`
  padding: 4px 0px 4px 4px;
  height: fit-content;
  margin-left: 5px;
  font: normal normal normal 17px/19px Franklin Gothic Medium;
  color: #7F8284;
  transform: translateY(40%);
`;
export const TitleContainer = styled.div`
margin-left: auto;
margin-right: auto !important;
height: fit-content;
width: 50%;
@media ${device.mobileS} {
  font: normal normal bold 17px/19px Franklin Gothic Medium;
  transform: translateY(85%);
}
@media ${device.mobileM} {
  font: normal normal bold 19px/22px Franklin Gothic Medium;
  transform: translateY(80%);
}
@media ${device.mobileL} {
  font: normal normal bold 22px/25px Franklin Gothic Medium;
  transform: translateY(76%);
}
@media ${device.tablet} {
  font: normal normal bold 26px/29px Franklin Gothic Medium;
  transform: translateY(60%);
}
@media ${device.laptop} {
  font: normal normal bold 32px/37px Franklin Gothic Medium;
  transform: translateY(40%);
}
color: #846444;
opacity: 1;
text-align: center;
`;

export const CRMDownBar = styled.div`
position: absolute;
top:60px;
left: 0px;
width: 100%;
height: 20px;
background: #e25928 0% 0% no-repeat padding-box;
opacity: 1;
z-index:51;
font: normal normal bold 15px/17px Franklin Gothic Medium;
color: #d41f44;
text-align: left;
`;
export const CRMDownBarText = styled.div`
margin-top: 2px;
margin-left: auto;
margin-right: auto !important;
color: #ffffff;
text-align: center;
@media ${device.mobileL} {
  font: normal normal 500 11px/15px Helvetica Neue;
}
@media ${device.tablet} and (orientation: portrait) {
  font: normal normal 500 11px/15px Helvetica Neue;
}
@media ${device.tablet} and (orientation: landscape) {
  font: normal normal 500 14px/17px Helvetica Neue;
}
@media ${device.laptop} {
  font: normal normal 500 14px/17px Helvetica Neue;
}
`;
export const UserRole = styled.div`
text-align: left;
height: fit-content;
font: normal normal bold 12px/14px Helvetica Neue;
color: #7F8284;
`;