import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

import Select from 'react-select';
import { GetUserDetailsByRole, GetAssignedFilesMetaData } from "../../../services/graph-service";
import _ from "lodash";
import { format } from 'date-fns';
import { GetAssignedFileRecords, SearchAssignedFileRecords } from "../../../services/dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import { Grid, Stack } from '@mui/material';
import DataTable from '../../../components/DataTable';
import TextField from "../../../components/TextField";
import CustomDatePicker from '../../../components/DatePicker';
import StyledButton from '../../../components/elements/StyledButton';
import AutoCompleteTextField from '../../../components/AutoComplete';
import { IoWarning } from "react-icons/io5";
import SubHeader from '../../../components/SubHeader';
import Backdrop from '../../../components/elements/Loader';
import * as Constants from "../../../helper/constants";
import StyledSwitch from '../../../components/StyledSwitch';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

function AssignedFiles() {


    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();

    const [isValid] = useValidation();
    const navigate = useNavigate();
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [lsms, setLsms] = useState([]);
    const [selectedLSMs, setSelectedLSMs] = useState([]);
    const [userManagers, setUserManagers] = useState([]);
    const [allLawyers, setAllLawyers] = useState([]);
    const [lawyers, setLawyers] = useState([]);
    const [selectedLawyers, setSelectedLawyers] = useState([]);
    const [isReadOnlyDetail, setReadOnlyDetail] = useState(false);
    const [inputValuesOfLSMs, setInputValuesOfLSMs] = useState([]);
    const [inputValuesOfLawyers, setInputValuesOfLawyers] = useState([]);
    //region ShowMore
    const [maximumPageRecordCount, setMaximumPageRecordCount] = useState(5);
    const [maximumBackendPageRecordCount, setMaximumBackendPageRecordCount] = useState(10);
    const [showMore, setShowMore] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [frontendPageIndex, setFrontendPageIndex] = useState(0);
    const [frontendPageTake, setFrontendPageTake] = useState(maximumPageRecordCount);
    const [backendPageIndex, setBackendPageIndex] = useState(0);
    const [backendPageTake, setBackendPageTake] = useState(maximumBackendPageRecordCount);
    const [onHandRowData, setonHandRowData] = useState([]);
    //#endregion

    const [searchParams, setSearchParams] = useSearchParams();
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [filterByDueDate, setFilterByDueDate] = useState(searchParams.get("assignedfilesduedate") == "true" ? true : false);
    const [isDataReady, setIsDataReady] = useState(false);

    // #region PageLoad
    useEffect(() => {
        window.scrollTo(0, 0);
        setInitialGridColumns();
        setIsDataReady(false);
    }, []);

    useEffect(() => {
        setIsDataReady(false);
        LoadInitialData(filterByDueDate);
    }, [instance]);

    function LoadInitialData(dueDate) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await GetAssignedFileRecords(response.accessToken, dueDate, 0, 0).then((rowData) => {
                        setInitialGridColumns();
                        if (rowData) {
                            setGridRows(rowData);
                        }
                        else {
                            setGridRows(null);
                        }
                    })

                    await GetAssignedFilesMetaData(response.accessToken).then((metaData) => {
                        if (metaData) {
                            if (metaData.lsms)
                                setLsms(metaData.lsms);
                            if (metaData.usersWithManagers)
                                setUserManagers(metaData.usersWithManagers);
                            if (metaData.lawyers) {
                                setLawyers(metaData.lawyers);
                                setAllLawyers(metaData.lawyers);
                            }
                        }
                    }).then(() => { setIsDataReady(true) });
                }
            });
        }
    }

    function LinkComponent(props) {
        var linkDetails = "#";
        if (props.data) {
            linkDetails = "/agencyrequest?agencyrequestid=" + props.data.id + "&clientFileId=" + props.data.clientFileId;
        }

        return (
            <a href={linkDetails}>
                {props.value}
            </a>
        );
    }
    const onFilterChanged = (event) => {

        setFilterByDueDate(event.target.checked);
        LoadInitialData(event.target.checked);

    };
    const setInitialGridColumns = () => {

        setGridColumns([{ field: 'idText', width: 100, headerName: 'ID', renderCell: true, editable: false, hide: true },
        { field: 'requestType', width: 150, headerName: 'Request Type', editable: false },
        { field: 'requestor', width: 150, headerName: 'Assigned Lawyer', editable: false },
        { field: 'assignedOn', type: 'date', width: 150, headerName: 'Date Assigned', renderDate: true, editable: false },
        { field: 'upcomingCourtDate', type: 'date', width: 150, headerName: 'Next Court Date', renderWarning: true, editable: false },
        { field: 'modifiedOn', type: 'date', width: 150, headerName: 'Last Updated Date', renderDate: true, editable: false },
        { field: 'assignedTo', width: 180, headerName: 'Agency Assigned', editable: false },
        { field: 'hasDisclosureOrdered', width: 220, headerName: 'Disclosure has been Ordered?', editable: false },
        { field: 'disclosureUploaded', width: 160, headerName: 'Disclosure Uploaded?', editable: false },
        { field: 'firstName', width: 180, headerName: 'First Name', editable: false },
        { field: 'lastName', width: 180, headerName: 'Last Name', editable: false },
        { field: 'isOutDated', width: 250, headerName: 'Outdated?', editable: false, hide: true },
        { field: 'clientFileId', width: 120, headerName: 'client File ID', editable: false, hide: true }]);

    }
    const columns = [];
   

    function onRowDoubleClicked(item) {
        var pageValue = item.row.idText.split('-')[0];
        var itemId = item.row.idText.split('-')[1];
        switch (pageValue) {
            case 'AR':
                 var params =`page=assignedfiles&assignedfilesduedate=${filterByDueDate}`;
                navigate (removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(itemId, item.row.clientFileId), null, params)}`));  

                break;
            case 'I':
                var params =`page=assignedfiles&assignedfilesduedate=${filterByDueDate}`;
                navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(itemId, item.row.clientFileId), null, params)}`));                 
                break;         
        }
    }

    gridColumns.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (
                    (columnHeader.renderWarning ? (((new Date() - new Date(params.row.upcomingCourtDate)) / (1000 * 3600 * 24) > 7) && params.row.isOutDated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} {params.value != null && <IoWarning title="Outdated Record" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} />} </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null))),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    //#endregion    

    const onSelectedLSMsChanged = (value) => {

        setSelectedLSMs(value);

        if (value.length == 0) {
            setLawyers(null);
            setLawyers(allLawyers);
            setSelectedLawyers(null);
        }
        else {
            var searchedLawyers = [];

            value.forEach(element => {
                const usersWithManager = _.filter(userManagers, { 'manager': element.value });
                usersWithManager.forEach(user => {
                    const searchedUser = _.filter(allLawyers, { 'value': user.value });
                    searchedLawyers = searchedLawyers.concat(searchedUser);
                });
            });

            setSelectedLSMs(value);
            setLawyers(null);
            setLawyers(searchedLawyers);
        }

    };

    const createSelectedArrayOfObjects = (inputValues, options) => {
        const arrayOfObjects = [];
        inputValues.forEach((InputValue) => {
            let seletcedOption = options.find((option) => option?.label == InputValue)
            arrayOfObjects.push(seletcedOption);
        })
        return arrayOfObjects;
    };

    const onSelectedLegalServiceManagersChanged = (event, newInputValues) => {
        setInputValuesOfLSMs(newInputValues);
        const newArrayOfLSMs = createSelectedArrayOfObjects(newInputValues, lsms);
        setSelectedLSMs(newArrayOfLSMs);

        if (newArrayOfLSMs.length == 0) {
            setLawyers(null);
            setLawyers(allLawyers);
            setSelectedLawyers(null);
        }
        else {
            var searchedLawyers = [];

            newArrayOfLSMs.forEach(element => {
                const usersWithManager = _.filter(userManagers, { 'manager': element.value });
                usersWithManager.forEach(user => {
                    const searchedUser = _.filter(allLawyers, { 'value': user.value });
                    searchedLawyers = searchedLawyers.concat(searchedUser);
                });
            });

            setSelectedLSMs(newArrayOfLSMs);
            setLawyers(null);
            setLawyers(searchedLawyers);
        }

    };

    const onSelectedLawyersChanged = (value) => {
        setSelectedLawyers(value);
    };

    const onSelectedAgencyAssignedLawyersChanged = (event, newInputValues) => {
        setInputValuesOfLawyers(newInputValues);
        setSelectedLawyers(createSelectedArrayOfObjects(newInputValues, lawyers));
    };




    //#region ShowMore    
    const setInitialShowMoreToGrid = async (rowData) => {
        setonHandRowData(rowData);
        if (rowData && rowData.length > 0) {
            if (rowData.length > 0) {
                const arrayData = rowData.slice(0, frontendPageTake)
                setGridRows(arrayData);
            }
            if (rowData.length > frontendPageTake) {
                setShowMore(true);
            }
            var indexValue = frontendPageIndex + frontendPageTake;
            var pageTakeValue = frontendPageTake + frontendPageTake;
            setFrontendPageIndex(indexValue);
            setFrontendPageTake(pageTakeValue);

        }
    }

    const setInitialSesrchShowMoreToGrid = async (rowData) => {
        setGridRows(rowData);
    }

    function setInitialPageIndexValues() {

        setFrontendPageIndex(0);
        setBackendPageIndex(0);
        setFrontendPageTake(maximumPageRecordCount);
        setBackendPageTake(maximumBackendPageRecordCount);
    }

    const GetSearchText = (searchArray) => {
        var lawyersSearchText = "";

        for (var i = 0; i < searchArray.length; i++) {
            var selectedLawyer = searchArray[i];
            if (i == (searchArray.length - 1)) {
                lawyersSearchText += "'" + JSON.stringify(selectedLawyer) + "'";
            } else {
                lawyersSearchText += "'" + JSON.stringify(selectedLawyer) + "',";
            }
        }
        return lawyersSearchText;
    }
    function GetSearchBy() {
        var lawyersSearchText = "";
        var searchedLawyers = [];

        if (selectedLSMs && (selectedLSMs != null || selectedLSMs != '') && selectedLSMs.length > 0 && (selectedLawyers == null || selectedLawyers == '')) {
            selectedLSMs.forEach(element => {
                const usersWithManager = _.filter(userManagers, { 'manager': element.value });
                usersWithManager.forEach(user => {
                    const searchedUser = _.filter(allLawyers, { 'value': user.value });
                    searchedLawyers = searchedLawyers.concat(searchedUser);
                });
            });

            lawyersSearchText = GetSearchText(searchedLawyers);
        }
        else if (selectedLawyers) {
            lawyersSearchText = GetSearchText(selectedLawyers);
        }

        const lawyersSearch = { columnName: "AssignedTo", searchValue: lawyersSearchText };

        return [lawyersSearch];
    }

    function LoadInitialDataAfterSearch() {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await GetAssignedFileRecords(response.accessToken, backendPageIndex, backendPageTake).then((rowData) => {
                        setInitialGridColumns();
                        setInitialShowMoreToGrid(rowData);
                    });

                    
                }
            });
        }
    }
    const onSearchClicked = () => {
        setIsDataReady(false);
        setHasSearched(true);

        const searchBy = GetSearchBy();

        if (searchBy) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        //Search Value
                        SearchAssignedFileRecords(response.accessToken, searchBy, filterByDueDate, 0, 0).then((data) => {
                            if (data) {
                                setGridRows(data);
                                setInitialSesrchShowMoreToGrid(data);
                                setIsDataReady(true);
                            }
                        });

                    }
                });
            }
        }
        else {
            LoadInitialDataAfterSearch();
            setHasSearched(false);
            setIsDataReady(true);
        }

    };

    const onCancelClicked = () => {

        navigateToPage();
    };

    function navigateToPage() {
        navigate(`${prepareNavigationParameters(null, null)}`);
    }

    if (canRead("assigned-files-dashboard", null)) { //ToDo - Change this role
        if (isDataReady) {
        return (
            <Grid container style={{ padding: "20px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                <SubHeader
                    title="Assigned Files"
                    menuItemOneText=""
                    onMenuItemOneClicked={false}
                    isMenuItemOneVisible={false}
                    isMenuItemOneDisabled={false}
                    menuItemTwoText=""
                    onMenuItemTwoClicked=""
                    isMenuItemTwoVisible={false}
                    menuItemTwoType="Warning"
                    isMenuItemTwoDisabled={false}
                    navItemOneText="Cancel"
                    onNavItemOneClicked={onCancelClicked}
                    isNavItemOneVisible={true}
                    isNavItemOneDisabled={false}
                    navItemTwoText=""
                    onNavItemTwoClicked=""
                    isNavItemTwoVisible={false}
                    isNavItemTwoDisabled={false}
                    navItemThreeText=""
                    onNavItemThreeClicked=""
                    isNavItemThreeVisible={false}
                    isNavItemThreeDisabled={false}
                    navItemFourText=""
                    onNavItemFourClicked=""
                    isNavItemFourVisible={false}
                    isNavItemFourDisabled={false} />
                <Grid item xs={12} md={4} style={{ margin: "10px 0px 20px 0px" }}>
                    <AutoCompleteTextField label="Legal Services Managers (LSMs)" options={lsms} multiple={true}
                        value={inputValuesOfLSMs} onChange={onSelectedLegalServiceManagersChanged} />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "10px 0px 20px 0px" }}>
                    <AutoCompleteTextField label="Assigned Lawyers" options={lawyers} multiple={true}
                        value={inputValuesOfLawyers} onChange={onSelectedAgencyAssignedLawyersChanged} />
                </Grid>
                <Grid item xs={12} md={4} align="center" style={{ margin: "10px 0px 20px 0px" }}>
                    <StyledButton variant={"outlined"} color="error" onClick={onSearchClicked} >Search</StyledButton>
                </Grid>
                <Grid item xs={12} md={12} style={{ font: "normal normal normal 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }}>
                    <Stack direction="row"> <div div style={{ marginRight: "10px" }}>Due Today: </div> <StyledSwitch style={{ textAlign: "left", font: "normal normal bold 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }} checked={filterByDueDate} onChange={onFilterChanged} /> </Stack>
                </Grid>
                <DataTable columns={columns} rows={gridRows} onRowDoubleClick={onRowDoubleClicked} />
                <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
            </Grid>);
         } else {
            return (
                <Backdrop />
            );
        }

    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Assigned Files
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default AssignedFiles;