import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/jpbailoutcomes";
const CREATEREQUESTNAME = "/create";
const GETMETADATAREQUESTNAME = "/metadata";
const GETFORCLIENTIDREQUESTNAME = "/forclient";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const CREATEFAILED = 'Create JP Bail Outcome Failed.';
const CREATESUCCESS = "JP Bail Outcome successfully created.";
const UPDATESUCCESS ="JP Bail Outcome successfully updated.";
const UPDATEFAILED = 'Update JP Bail Outcome Failed.';
const DELETESUCCESS = "JP Bail Outcome successfully deleted.";
const DELETEFAILED = 'Delete JP Bail Outcome Failed.';

export async function CreateJPBailOutcome(jpBailOutcome, token) {
    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(jpBailOutcome)
    }
    

    var result = await fetch(serviceUrl, options).then(async response => {

        if (response.ok) {
            toast.success(CREATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(CREATESUCCESS);
            return await response.json();
        } else {
            toast.error(CREATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
           // alert(CREATEFAILED);
        }
    }).catch(error => { toast.error(CREATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(CREATEFAILED); 
    //console.log(error); 
});

    return result;
}


export async function UpdateJPBailOutcome(jpBailOutcome, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + jpBailOutcome.jpBailOutcomeId;  

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(jpBailOutcome)
    }   

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(UPDATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(UPDATESUCCESS);
            return response.json();
        } else {
            toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(UPDATEFAILED);
        }
    }).catch(error => { toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(UPDATEFAILED); 
    //console.log(error); 
});

    return result;
}

export async function GetJPBailOutcomeById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetAllJPBailOutcomes(clientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETFORCLIENTIDREQUESTNAME + "?clientFileId=" + clientFileId + '&onlyLastTwoYears=true';   
    
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetJPBailOutcomeMetaData( token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+GETMETADATAREQUESTNAME;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {             
            var data = await response.json();           
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function DeleteJPBailOutcome(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if (response.ok) {
                toast.success(DELETESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
                //alert(DELETESUCCESS)
                return data;
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                toast.error(DELETEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
                //alert(DELETEFAILED)
                return Promise.reject(error);
            }
             
        })
      .catch(error => {
            //console.error('Error occurred.', error);
        });
}
