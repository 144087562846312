import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetMyFiles } from "../../../services/dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import StyledButton from '../../../components/elements/StyledButton';
import DataTable2 from '../../../components/DataTable2';
import { IoWarning } from "react-icons/io5";
import { Grid, Stack } from '@mui/material';
import SelectorTextField from "../../../components/SelectorTextField";
import StyledSwitch from '../../../components/StyledSwitch';
import Backdrop from '../../../components/elements/Loader';
import {format} from 'date-fns';
import * as Constants from "../../../helper/constants";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

function TopMyFiles(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterByDueDate, setFilterByDueDate] = useState(searchParams.get("topmyfilesduedate") == "true"? true: false);
    const [showViewAll, setShowViewAll] = useState(true);
    const [isDataReady, setIsDataReady] = useState(false);
    
    // #region PageLoad
    useEffect(() => {
        window.scrollTo(0, 0);
        setInitialGridColumns();
    }, []);

    useEffect(() => {
        LoadInitialData(filterByDueDate);
    }, [instance]);

    function LoadInitialData(dueDate) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken, dueDate);
                }
            });
        }
    }
    const setInitialGridData = async (token, dueDate) => {
        setInitialGridColumns();
        await GetMyFiles(token, dueDate, 0, 5).then((rowData) => {

            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        }).then(() => {setIsDataReady(true)});
    }


    const columns = [];
    const setInitialGridColumns = () => {

        setGridColumns([{ field: 'idText', minWidth: 100, headerName: 'ID', renderCell: true, editable: false, hide :true},   
        { field: 'firstName', minWidth: 80, flex: 1, maxWidth: 130, headerName: 'First Name', editable: false },
        { field: 'lastName', minWidth: 80, flex: 1, maxWidth: 130, headerName: 'Last Name', editable: false },
        { field: 'dateOfBirth', type: 'date', minWidth: 95, flex: 1, maxWidth: 150, headerName: 'Date of Birth', renderDate: true, editable: false },
        { field: 'courtLocation', minWidth: 130, flex: 2, maxWidth: 230, headerName: 'Court Location', editable: false },
        { field: 'upcomingCourtDate', type: 'date', minWidth: 125, flex: 1, maxWidth: 150, headerName: 'Next Court Date', renderWarning: true, editable: false },
        { field: 'nextActionDate', minWidth: 95, flex: 0.8, maxWidth: 130, headerName: 'Next Action Date', renderDate: true, editable: false },
        { field: 'hasDisclosureOrdered', minWidth: 115, flex: 1.25, maxWidth: 210, headerName: 'Disclosure has been ordered?', editable: false },
        { field: 'disclosureUploaded', minWidth: 85, flex: 1, maxWidth: 160, headerName: 'Disclosure uploaded?', editable: false },
        { field: 'dateOfFirstAttemptedContact', minWidth: 160, flex: 1.25, maxWidth: 230, headerName: 'Date of First Attempted Contact',renderDate: true, editable: false },
        { field: "modifiedOn",minWidth: 100, flex: 1, maxWidth: 140, type: 'date', headerName: "Last Updated Date", renderDate: true, sortable: true, filter: true },
        { field: 'requestType', minWidth: 95, flex: 1, maxWidth: 130, headerName: 'Request Type', editable: false },
        { field: 'assignedTo', minWidth: 120, flex: 1, maxWidth: 140, headerName: 'Assigned Lawyer', editable: false },
        { field: 'assignedOn', type: 'date', flex: 1, minWidth: 95, maxWidth: 130, headerName: 'Date Assigned', renderDate: true, editable: false },
        { field: 'isOutDated', minWidth: 250, headerName: 'Outdated?',  editable: false, hide: true },
        { field: 'clientFileId', minWidth: 120, headerName: 'client File Id', editable: false, hide: true }]);
    } 

    function onRowDoubleClicked(item) {
        var pageValue = item.row.idText.split('-')[0];
        var itemId = item.row.idText.split('-')[1];
        var navigatedUrl="";
        switch (pageValue) {
            case 'AR':
                navigate (removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(itemId, item.row.clientFileId), 1)}&page=topmyfiles&topmyfilesduedate=${filterByDueDate}&root=topmyfiles`)); 

                break;
            case 'I':
                navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(itemId, item.row.clientFileId), 1)}&page=topmyfiles&topmyfilesduedate=${filterByDueDate}&root=topmyfiles`));                
                break;
        }
    }

    gridColumns.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (
                    (columnHeader.renderWarning ? (((new Date() - new Date(params.row.upcomingCourtDate) ) / (1000*3600*24) >7) && params.row.isOutDated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} {params.value != null && <IoWarning title="Outdated Record" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> } </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> :
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                minWidth: columnHeader.minWidth,
                maxWidth: columnHeader.maxWidth,
                flex: columnHeader.flex,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    const onFilterChanged = (event) => {

        setFilterByDueDate(event.target.checked);
        LoadInitialData(event.target.checked);

    };

    //#endregion   
    if (canRead("my-files-dashboard", null)) { //ToDo - Change this role
        if (isDataReady) {
            return (
            <Grid container>
                <Grid item xs={12} md={12} style={{ font: "normal normal normal 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }}>
                    <Stack direction="row"> <div div style={{ marginRight: "10px" }}>Due Today: </div> <StyledSwitch style={{ textAlign: "left", font: "normal normal bold 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: " #585454", opacity: "1" }} checked={filterByDueDate} onChange={onFilterChanged} /> </Stack>
                </Grid>
                <DataTable2 columns={columns} rows={gridRows} onRowDoubleClick={onRowDoubleClicked} totalCount={props.totalCount} />
                {showViewAll && <Grid item xs={12} md={12} style={{ textAlign: "center", margin: "5px 0px 20px 0px" }}>
                    <StyledButton variant={"outlined"} color="error" onClick={() => navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.MYFILES, new Array(filterByDueDate))}&page=topmyfiles&root=topmyfiles`))} >View All</StyledButton>
                </Grid>
                }
            </Grid>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>My Files
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default TopMyFiles;