import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {format} from 'date-fns';
import { GetFilesToBeAssigned } from "../../../services/dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import StyledButton from '../../../components/elements/StyledButton';
import DataTable from '../../../components/DataTable';
import Backdrop from '../../../components/elements/Loader';
import { IoWarning } from "react-icons/io5";
import { Grid, Stack } from '@mui/material';
import StyledSwitch from '../../../components/StyledSwitch';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";
import * as Constants from "../../../helper/constants";

function TopFilesToBeAssigned(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [topFilterByDueDate, setTopMyFilesFilterByDueDate] = useState(searchParams.get("topfilestobeassignedduedate") == "true"? true: false);
    const [showViewAll, setShowViewAll] = useState(true);
    const [isDataReady, setIsDataReady] = useState(false);

    // #region PageLoad
    useEffect(() => {
        window.scrollTo(0, 0);
        setInitialGridColumns();
    }, []);

    useEffect(() => {
        LoadInitialData(topFilterByDueDate);
    }, [instance]);

    function LoadInitialData(dueDate) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken, dueDate);
                }
            });
        }
    }
    const setInitialGridData = async (token, dueDate) => {

        await GetFilesToBeAssigned(token, dueDate, 0, 5).then((rowData) => {
            setInitialGridColumns();
            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        }).then(() => {setIsDataReady(true)});
    }


    const setInitialGridColumns = () => {

        setGridColumns([{ field: 'idText', width: 100, headerName: 'ID', renderCell: true, editable: false, hide : true },
        { field: 'requestType', width: 180, headerName: 'Request Type', editable: false },
        { field: 'firstName', width: 150, headerName: 'First Name', editable: false },
        { field: 'lastName', width: 150, headerName: 'Last Name', editable: false },
        { field: 'dateOfBirth', type: 'date', width: 150, headerName: 'Date of Birth',  renderDate: true, editable: false },
        { field: 'modifiedOn', type: 'date', width: 150, headerName: 'File Received Date', renderDate: true, editable: false },
        { field: 'upcomingCourtDate',type: 'date', width: 150, headerName: 'Next Court Date', renderWarning: true, editable: false },
        { field: 'courtLocation', width: 220, headerName: 'Court Location', editable: false },
        { field: 'isOutDated', width: 250, headerName: 'Outdated?', editable: false, hide: true},
        { field: 'clientFileId', width: 120, headerName: 'client File ID', editable: false, hide: true }]);

    }

    const columns = [];
  

    function onRowDoubleClicked(item) {
        var pageValue = item.row.idText.split('-')[0];
        var itemId = item.row.idText.split('-')[1];
        switch (pageValue) {
            case 'AR':
                navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(itemId, item.row.clientFileId), 1)}&root=topfilestobeassigned&topfilestobeassignedduedate=${topFilterByDueDate}&page=topfilestobeassigned`));
                break;
            case 'I':
                navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(itemId, item.row.clientFileId), 1)}&root=topfilestobeassigned&topfilestobeassignedduedate=${topFilterByDueDate}&page=topfilestobeassigned`));
                break;
        }
    }

    gridColumns.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (
                    (columnHeader.renderWarning ? (((new Date() - new Date(params.row.upcomingCourtDate)) / (1000 * 3600 * 24) > 7) && params.row.isOutDated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} {params.value != null && <IoWarning title="Outdated Record" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> } </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });
    const onFilterChanged = (event) => {

        setTopMyFilesFilterByDueDate(event.target.checked);
        LoadInitialData(event.target.checked);

    };
    //#endregion     

    if (canRead("files-to-be-assigned-dashboard", null)) { //ToDo - Change this role
        if (isDataReady) {
            return (
            <Grid container>
                <Grid item xs={12} md={12} style={{ font: "normal normal normal 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }}>
                    <Stack direction="row"> <div style={{ marginRight: "10px" }}>Due Today: </div> <StyledSwitch style={{ textAlign: "left", font: "normal normal bold 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: " #585454", opacity: "1" }} checked={topFilterByDueDate} onChange={onFilterChanged} /> </Stack>
                </Grid>
                <DataTable columns={columns} rows={gridRows} totalCount={props.totalCount} onRowDoubleClick={onRowDoubleClicked}/>
                {showViewAll &&
                    <Grid item xs={12} md={12} style={{ textAlign: "center", margin: "5px 0px 20px 0px" }}>
                        <StyledButton variant={"outlined"} color="error" onClick={() => navigate(removeDuplicatedNavigationParameters(`${prepareNavigationParameters(Constants.FILESTOASSIGN, new Array(topFilterByDueDate))}&page=topfilestobeassigned&root=topfilestobeassigned`) )} >View All</StyledButton>
                    </Grid>
                }
            </Grid>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Files to Assign
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default TopFilesToBeAssigned;