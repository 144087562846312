import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, MenuItem } from "@mui/material";
import SubHeader from '../components/SubHeader';
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import { GetClientFileById, GetClientFileActivity, MarkClientFileAsConflicted, CheckDuplicateClients, GetMergedClientFile } from "../services/client-file.service";
import NoteTable from "../notes";
import CustomButton from "../components/Button";
import DataTable from '../components/DataTable';
import Backdrop from '../components/elements/Loader';
import { TextButton } from "../components/Button";
import StyledModal from '../components/Modal';
import { format } from 'date-fns';
import Badge from 'react-bootstrap/Badge';
import StyledDialog from '../components/Dialog';
import { GetClientDetailNotes } from "../services/note-service";
import { FetchLookupData } from "../services/client-file.service";
import { useNavigationParameters } from "../hooks/useNavigationParameters";
import * as Constants from "../helper/constants";

function ClientFileDashboard() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [rootPage, setRootPage] = useState(searchParams.get("root"));
    const screenSideBarList = ['Client Basic Information', 'Client Notes', 'Client Details'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [indigenousAncestry, setIndigenousAncestry] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [preferredMethodOfContact, setPreferredMethodOfContact] = useState(2);
    const [financiallyEligible, setFinanciallyEligible] = useState('');
    const [lastAssessmentDate, setLastAssessmentDate] = useState("");
    const [financialInformationRequired, setFinancialInformationRequired] = useState(true);
    const [familySize, setFamilySize] = useState('');
    const [last30DaysIncome, setLast30DaysIncome] = useState('');
    const [last12MonthsIncome, setLast12MonthsIncome] = useState('');
    const [totalEquityValue, setTotalEquityValue] = useState('');
    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [clientDetailNotes, setClientDetailNotes] = useState([]);
    const [rows, setRows] = useState([]);

    const [isDataReady, setIsDataReady] = useState(false);
    const apiServerUrl = appconfig.API_SERVER_URL;
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [middleNameFromSearchParams, setMiddleNameFromSearchParams] = useState(searchParams.get("middlename"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));
    const [openModal, setOpenModal] = useState(false);
    const [isCrmConflicted, setIsCrmConflicted] = useState(false);
    const [isDcpConflicted, setIsDcpConflicted] = useState(false);
    const [hasDcpDuplicateClientFiles, setHasDcpDuplicateClientFiles] = useState(false);
    const [isLinkedClient, setIsLinkedClient] = useState(false);
    const [clientFileCrmId, setClientFileCrmId] = useState('');

    useEffect(() => {
        setIsDataReady(false);

        const fetchClientDetailNotes = async (token) => {
            await GetClientDetailNotes(Number(searchParams.get("clientFileId")), 2, 'true', token).then((data) => {
                setClientDetailNotes(data);
            });
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    LoadInitialLookUpDataData(response.accessToken);
                    GetClientFileById(clientFileId, response.accessToken).then(data => {
                        setMainInformation(data);

                        if (data.clientFileCrmId && data.clientFileCrmId != "") {
                            // GetMergedClientFile(clientFileId, data.clientFileCrmId, response.accessToken).then(data => {
                            //     setMainInformation(data);

                            CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                                setHasDcpDuplicateClientFiles(data2);
                            });
                            //});
                        }
                    });

                    fetchClientDetailNotes(response.accessToken);
                    await GetClientFileActivity(response.accessToken, clientFileId).then(data => {
                        setRows(data);
                    }).then(() => { setIsDataReady(true) });

                }
            });
        }
    }, []);

    const LoadInitialLookUpDataData = async (token) => {

        await FetchLookupData(token).then((data) => {
            if (data)
                setContactMethods(data.contactMethods);
            setIndigenousAncestries(data.indigenousAncestries);
        })
    }

    function setMainInformation(data) {
        setFirstName(data.clientBasicInformation.firstName);
        setMiddleName(data.clientBasicInformation.middleName);
        setLastName(data.clientBasicInformation.lastName);
        setDateOfBirth((null == data.clientBasicInformation.dateOfBirth || "" == data.clientBasicInformation.dateOfBirth) ? "" : new Date(data.clientBasicInformation.dateOfBirth).toLocaleDateString());
        setIndigenousAncestry((null == data.clientBasicInformation.indigenousAncestryId || "" == data.clientBasicInformation.indigenousAncestryId) ? "" : data.clientBasicInformation.indigenousAncestryId);
        setEmail(data.clientContactDetails.email);
        setPhoneNumber(data.clientContactDetails.phoneNumber);
        setMobileNumber(data.clientContactDetails.mobileNumber);
        setPreferredMethodOfContact((null == data.clientContactDetails.preferredMethodOfContactId || "" == data.clientContactDetails.preferredMethodOfContactId) ? "" : data.clientContactDetails.preferredMethodOfContactId);
        setFinanciallyEligible(data?.clientFinancialInformation.financiallyEligible != null ? Number(data.clientFinancialInformation.financiallyEligible) : "");
        setLastAssessmentDate((null == data.clientFinancialInformation.lastAssessmentDate || "" == data.clientFinancialInformation.lastAssessmentDate) ? "" : new Date(data.clientFinancialInformation.lastAssessmentDate).toLocaleDateString());
        setFinancialInformationRequired(data?.clientFinancialInformation.mostRecentAssessmentOver24Months != null ? data.clientFinancialInformation.mostRecentAssessmentOver24Months : true);
        setFamilySize(data.clientFinancialInformation.familySize);
        setLast30DaysIncome(data?.clientFinancialInformation.last30DaysIncome != null ? data.clientFinancialInformation.last30DaysIncome.toLocaleString("en-US") : "");
        setLast12MonthsIncome(data?.clientFinancialInformation.last12MonthsIncome != null ? data.clientFinancialInformation.last12MonthsIncome.toLocaleString("en-US") : "");
        setTotalEquityValue(data?.clientFinancialInformation.totalEquityValue != null ? data.clientFinancialInformation.totalEquityValue.toLocaleString("en-US") : "");
        setIsCrmConflicted(data.isCrmConflicted);
        setIsDcpConflicted(data.isDcpConflicted);
        setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
        setClientFileCrmId(data.clientFileCrmId);
    }

    const columnHeaders = [{ field: 'id', width: 80, headerName: 'ID', editable: false, hide: true },
    { field: 'createdDate', type: 'date', width: 120, headerName: 'Created On', editable: false, renderDate: true },
    { field: 'activity', width: 150, headerName: 'Activity', editable: false },
    { field: 'status', width: 150, headerName: 'Status', editable: false },
    { field: 'nextCourtDate', type: 'date', width: 150, headerName: 'Next Court Date', editable: false, renderDate: true },
    { field: 'assignedTo', width: 180, headerName: 'Assigned To', editable: false },
    { field: 'outcome', width: 350, headerName: 'Outcome', editable: false },
    { field: 'dockets', width: 700, headerName: 'Dockets', editable: false },
    ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderDate && params.value != null ? <>{format(new Date(params.value), 'dd-MMM-yyyy')}</> : null),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide,
            },
        );
    });



    const onDateOfBirthChanged = (date) => {
        setDateOfBirth(date);
    };
    const onLastAssessmentDateChanged = (date) => {
        setLastAssessmentDate(date);
    };
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    };

    function getParams() {
        var queryParams = "&page=clientfiledashboard";
        if (crmId || firstNameFromSearchParams || middleNameFromSearchParams || lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if (crmId != null) {
                queryParams = queryParams + `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (middleNameFromSearchParams) {
                queryParams = queryParams + `&middlename=${middleNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    const onViewDetailsClicked = () => navigate(`${prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS, new Array(clientFileId), null, "page=clientfiledashboard")}`);

    function onRowClicked(item) {
        var pageValue = item.row.id.split('-')[0];
        var itemId = item.row.id.split('-')[1];
        var sdRelatedId = item.row.id.split('-').slice(1).join('-');
        switch (pageValue) {

            case 'AR':
                navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(itemId, clientFileId), null, "page=clientfiledashboard")}`);
                break;
            case 'JPB':
                navigate(`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(itemId, clientFileId), null, "page=clientfiledashboard")}`);
                break;
            case 'REF':
                navigate(`${prepareNavigationParameters(Constants.REFFERRAL, new Array(itemId, clientFileId), null, "page=clientfiledashboard")}`);
                break;
            case 'IN':
                navigate(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(itemId, clientFileId), null, "page=clientfiledashboard&backto=clientfiledashboard")}`);
                break;
            case 'CG':
                navigate(`${prepareNavigationParameters(Constants.CLIENTGATEWAY, new Array(itemId, clientFileId), null, "page=clientfiledashboard")}`);
                break;
            case 'SD':
                navigate(`${prepareNavigationParameters(Constants.SERVICEDECISION, new Array(clientFileId, sdRelatedId), null, "page=clientfiledashboard&backto=clientfiledashboard")}`);
                break;
            case 'CE':
                navigate(`${prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS, new Array(clientFileId), null, "page=clientfiledashboard&from=crmce")}`);
                break;
            default:
                navigate(`${prepareNavigationParameters(Constants.CLIENTFILEVIEWDETAILS, new Array(clientFileId), null, "page=clientfiledashboard")}`);
        }

    }


    const onCancelClicked = (event) => {

        navigateUrl();
    };

    function navigateUrl() {
        switch (rootPage) {
            case Constants.TOPASSIGNEDFILES:
                navigate(`${prepareNavigationParameters(Constants.TOPASSIGNEDFILES, null, null)}`);
                break;
            case Constants.TOPFILESTOASSIGN:
                navigate(`${prepareNavigationParameters(Constants.TOPFILESTOASSIGN, null, null)}`);
                break;
            case Constants.TOPMYFILES:
                navigate(`${prepareNavigationParameters(Constants.TOPMYFILES, null, null)}`);
                break;
            default:
                navigate(`${prepareNavigationParameters(Constants.SEARCHCLIENTS, null, null)}`);
                break;
        }
    }

    const createNewNote = () => navigate(`${prepareNavigationParameters(Constants.NOTEDETAILS, new Array(2, clientFileId), null, "page=clientfiledashboard")}`);

    const createNewAgencyRequest = () => navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(null, clientFileId), null, "page=clientfiledashboard")}`);

    const createNewClientGateway = () => navigate(`${prepareNavigationParameters(Constants.CLIENTGATEWAY, new Array(null, clientFileId), null, "page=clientfiledashboard")}`);
    //navigate(`/client-gateway?clientFileId=${clientFileId}${getParams()}`);
    //window.open("https://laa-testportal.powerappsportals.com/client-gateway", "blank");
    const createNewJPBailOutcome = () => navigate(`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(null, clientFileId), null, "page=clientfiledashboard")}`);

    const createNewReferral = () => navigate(`${prepareNavigationParameters(Constants.REFFERRAL, new Array(null, clientFileId), null, "page=clientfiledashboard")}`);

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);
    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };
    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkClientFileAsConflicted(Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)), response.accessToken).then(() => {
                        navigate("/");
                    });
                }
            });
        }
    }

    const canMarkAsConflicted = () => {
        var result = false;

        if ((canUpdate("client-file", null)) && (Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) && Number(searchParams.get(Constants.CLIENTFILEIDQUERYSTRINGPARAM)) > 0)) {
            if (isLinkedClient) {
                result = true;
            }
            else if (hasDcpDuplicateClientFiles) {
                result = true;
            }
        }

        if (isCrmConflicted || isDcpConflicted)
            result = false;
        return result;
    }


    if (canRead("client-file", null)) {
        if (isDataReady) {
            return (
                <>
                    <Grid container style={{ padding: "10px 20px 0px 10px", margin: "80px 0px 0px 0px" }}>
                        <SubHeader
                            title="Client File"
                            menuItemOneText="Delete Client File"
                            onMenuItemOneClicked={() => { }}
                            isMenuItemOneVisible={false}
                            isMenuItemOneDisabled={false}
                            menuItemTwoText="Mark for Review"
                            onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                            isMenuItemTwoVisible={canMarkAsConflicted()}
                            menuItemTwoType="Warning"
                            isMenuItemTwoDisabled={false}
                            navItemOneText="View Details"
                            onNavItemOneClicked={onViewDetailsClicked}
                            isNavItemOneVisible={true}
                            isNavItemOneDisabled={false}
                            navItemTwoText="Cancel"
                            onNavItemTwoClicked={onCancelClicked}
                            isNavItemTwoVisible={true}
                            isNavItemTwoDisabled={false}
                            isCreateNewVisible={true}
                            newItemOneVisible={canCreate("client-note", null)}
                            newItemOneText="Client Note"
                            onNewItemOneClicked={createNewNote}
                            newItemTwoVisible={canCreate("agency-request", null)}
                            newItemTwoText="Agency Request"
                            onNewItemTwoClicked={createNewAgencyRequest}
                            newItemThreeVisible={canCreate("client-gateway", null)}
                            newItemThreeText="Client Gateway"
                            onNewItemThreeClicked={createNewClientGateway}
                            newItemFourVisible={canCreate("jp-bail-outcome", null)}
                            newItemFourText="JP Bail Outcome"
                            onNewItemFourClicked={createNewJPBailOutcome}
                            newItemFiveVisible={canCreate("client-referral", null)}
                            newItemFiveText="Client Referral"
                            onNewItemFiveClicked={createNewReferral}
                        />
                        <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                            message={"Do you really want to mark the CF-" + Number(searchParams.get("clientFileId")) + " as conficted?"}
                            onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />

                        {(isCrmConflicted || isDcpConflicted) && (<>
                            <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                        </>)}
                        <Grid container style={{ backgroundColor: "#ececec", border: "1px solid #7F8284", borderRadius: "8px", margin: "0px" }}>
                            <Grid container style={{ padding: "5px 5px 0px 10px", borderRadius: "8px" }}>
                                <Grid item xs={6} md={6} style={{
                                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                                }}>Client Information</Grid>
                                <Grid item xs={6} md={6} style={{ textAlign: "right", paddingRight: "10px" }}><TextButton label="Financial Eligibility Guidelines" size="small" handleOnClick={handleModalOpen} /></Grid>
                                <StyledModal open={openModal} title="Financial Eligibility" handleOnClose={handleModalClose} />
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="First Name" placeholder="First Name" required={true} disabled value={firstName} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Middle Name" placeholder="Middle Name" required={true} disabled value={middleName} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Last Name" placeholder="Last Name" required={true} disabled value={lastName} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <CustomDatePicker isDashBoard label="Date of Birth" placeholder="Date of Birth" required={true} disableFuture={true} disabled value={dateOfBirth} handleChange={onDateOfBirthChanged} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField isDashBoard label="Indigenous Ancestry" placeholder="Indigenous Ancestry" select disabled value={indigenousAncestry} >
                                        {indigenousAncestries?.map((indigenousAncestry) => (
                                            <MenuItem key={indigenousAncestry.indigenousAncestryId} value={indigenousAncestry.indigenousAncestryId}>
                                                {indigenousAncestry.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Email" placeholder="Email" required={false} disabled value={email} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Phone Number" placeholder="Phone Number" required={false} disabled value={phoneNumber} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Mobile Number" placeholder="Mobile Number" required={false} disabled value={mobileNumber} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField isDashBoard label="Preferred Method of Contact" placeholder="Method of Contact" select disabled value={preferredMethodOfContact}>
                                        {contactMethods?.map((contactMethod) => (
                                            <MenuItem key={contactMethod.contactMethodId} value={contactMethod.contactMethodId}>
                                                {contactMethod.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField isDashBoard label=" Financially Eligible " placeholder=" Financially Eligible " disabled select value={financiallyEligible}  >
                                        <MenuItem key="-1" value=""></MenuItem>
                                        <MenuItem key="1" value={1}>Yes</MenuItem>
                                        <MenuItem key="0" value={0}>No</MenuItem>
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <CustomDatePicker isDashBoard label="Last Assessment Date" placeholder="Last Assessment Date" disableFuture={true} disabled value={lastAssessmentDate} handleOnChange={onLastAssessmentDateChanged} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField
                                        isDashBoard
                                        label="Financial Information Required?"
                                        placeholder="Financial Information Required"
                                        disabled
                                        select
                                        value={financialInformationRequired}
                                        labelColor="#4f868e"
                                    >
                                        <MenuItem key="-1" value=""></MenuItem>
                                        <MenuItem key="true" value={true}>Yes</MenuItem>
                                        <MenuItem key="false" value={false}>No</MenuItem>
                                    </SelectorTextField>
                                    </Grid>

                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Family Size" placeholder="Family Size" disabled value={familySize} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Last 30 Days Income ($)" placeholder="Last 30 Days Income" disabled value={last30DaysIncome} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Last 12 Month Income($)" placeholder="Last 12 Months Income" disabled value={last12MonthsIncome} />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Total Equity Value ($)" placeholder="Total Equity Value" disabled value={totalEquityValue} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <NoteTable
                            title="Client Notes"
                            notes={clientDetailNotes}
                            clientFileId={Number(clientFileId)}
                            relatedId={Number(clientFileId)}
                            noteTypeId={2}
                            isDisabled={false}
                            hideHeader={false}
                            hideTotalCount={true}
                            pageParams={`${getParams()}`}
                            pageId={"clientfiledashboard"}
                        />
                        <Grid container style={{ padding: "3px 20px 0px 10px", border: "1px solid #7F8284", margin: "10px 0px 25px 0px", borderRadius: "8px" }}>
                            <DataTable columns={columns} rows={rows} onRowDoubleClick={onRowClicked} dataGridtitle="Recent Portal Activities" hideTotalCount={true} />
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Client File Dashboard
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default ClientFileDashboard;