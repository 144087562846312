import { Button } from "react-bootstrap";
import React, { useEffect, useState, useRef } from 'react';
import {Grid} from '@mui/material';
import { tokenRequest } from "../../../auth/authConfig";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import DataTable2 from '../../../components/DataTable2';
import StyledDialog from '../../../components/Dialog';
import {
    GridRowModes,
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridEditSingleSelectCell,
    useGridApiContext,
  } from '@mui/x-data-grid';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useValidation } from "../../../hooks/useValidation";
import { CreateDocket, DeleteDocket, GetDocketById, GetDocketMetadata, UpdateDocket } from "../../../services/docket-service";
import {format} from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import _ from "lodash";
import { toast } from "react-toastify";
import * as Constants from "../../../helper/constants";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import {SeverityLevel} from '@microsoft/applicationinsights-web';

function DocketTable(props) {
    const [isValid] = useValidation();
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const [interactionId, setInteractionId] = useState(searchParams.get("id"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const [courtLocation, setCourtLocation] = useState("");
    const [courtLocations, setCourtLocations] = useState([]);
    const [courtRooms, setCourtRooms] = useState([]);
    const [allCourtRooms, setAllCourtRooms] = useState([]);
    const [courtRoomsLocationMapping, setCourtRoomsLocationMapping] = useState([]);
    const [docketToBeDeleted, setDocketToBeDeleted] = useState("");
    const [docketNumberErrors, setDocketNumberErrors] = useState([]);
    const [courtRoomErrors, setCourtRoomErrors] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [propsDockets, setPropsDockets] = useState([[]]);
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Docket : ", trackEvents);

    useEffect(() => {
        const fetchInitialData = async (token) => {

            const metaData = await GetDocketMetadata(token);

            if (metaData) {
                setCourtLocations(metaData.courtLocations);
                setAllCourtRooms(metaData.courtRooms);
                setCourtRoomsLocationMapping(metaData.courtRoomAndLocationMapping);

            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
                appInsights.trackException({ error: new Error('An error occurred while loading data.'), severityLevel: SeverityLevel.Error });
            };
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }

    }, [isAuthenticated]);

    useEffect(() => {
        setPropsDockets(props.dockets);
        const initialRows = [];
        if (props.dockets && props.dockets.length > 0) {
            props.dockets.forEach((docket) => {
                let legalIssuesOnDocket = [];
                if (docket.docketLegalIssues && docket.docketLegalIssues.length > 0) {
                  docket.docketLegalIssues.forEach((legalIssue) => {
                    if (legalIssue.code != null) {
                      legalIssuesOnDocket.push(legalIssue.code);
                    }
                  });
                }
                initialRows.push(
                    {
                        docketNumber: docket.docketNumber,
                        upcomingCourtDate: docket.upcomingCourtDate,
                        courtLocation: docket.courtLocation ? docket.courtLocation.label : null,
                        courtRoom: docket.courtRoom ? docket.courtRoom.label : null,
                        id: docket.docketId,
                        legalIssues: legalIssuesOnDocket.join('; ')
                    },
                );
            })
        };
        setRows(initialRows);
    },[props.dockets]);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

     function CustomTypeEditComponent(props) {
        const apiRef = useGridApiContext();
      
        const handleValueChange = async () => {
          await apiRef.current.setEditCellValue({
            id: props.id,
            field: 'courtRoom',
            value: '',
          });
        };
      
        return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
      }


    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };
    
      const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };
      
      const handleSaveClick = (id) => () => {
        if (courtRoomErrors?.find((error) => error.docketId == id)){
            toast.error("Please fill out the Court Room on Docket Details correctly before saving.", { position: "top-center", autoClose: 5000, theme: "colored" });   
        } else if (docketNumberErrors?.find((error) => error.docketId == id)){
            toast.error("Please fill out the Docket Number on Docket Details correctly before saving.", { position: "top-center", autoClose: 5000, theme: "colored" });   
        } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
      };
    
      const handleDeleteClick = (id) => () => {
        setOpenDeleteDialog(true);
        setDocketToBeDeleted(id);
      };

    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
        setDocketToBeDeleted("");
    };


    const onDeleteClicked = async () => {
        if ((docketToBeDeleted != null || docketToBeDeleted != "") && docketToBeDeleted > 0) {
            setTrackEvents("Deleting Docket By Id : " + docketToBeDeleted.toString());
            appInsights.trackTrace({ message: "Deleting Docket By Id : " + docketToBeDeleted.toString(), severityLevel: SeverityLevel.Information });
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteDocket(docketToBeDeleted, response.accessToken);
                     }
                 });
             }
        }
        setOpenDeleteDialog(false);
        setRows(rows.filter((row) => row.id !== docketToBeDeleted));
        setDocketToBeDeleted("");
    };
    
      const handleCancelClick = (id) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
          setRows(rows.filter((row) => row.id !== id));
        }
      };
    
      const handleCourtRoomsBasedOnLocation = (courtLocation) => {
        let courtRoomsNeedsToAdd = [];
        let selectedCourtRooms = [];
        let courtLocationBasedOnId = _.filter(courtLocations, { 'label': courtLocation});

        if (courtLocationBasedOnId[0].courtLocationId > 0) {
            courtRoomsNeedsToAdd = _.filter(courtRoomsLocationMapping, { 'value': courtLocationBasedOnId[0].courtLocationId.toString() });
            courtRoomsNeedsToAdd.forEach(element => {
                let courtRoom = _.find(allCourtRooms, { 'courtRoomId': parseInt(element.label) });
                if (courtRoom) selectedCourtRooms.push(courtRoom);
            });
        }
         
        return selectedCourtRooms;
    };

      const processRowUpdate = (newRow) => {
        
        let docketToBeUpdated = propsDockets?.find((docket) => docket.docketId == newRow.id);
        let courtLocation = courtLocations?.find((location) => location.label == newRow.courtLocation);
        let filteredCourtRooms = handleCourtRoomsBasedOnLocation(courtLocation?.label);    
        let courtRoom = filteredCourtRooms?.find((courtRoom) => courtRoom.label == newRow.courtRoom);

        const createDocketDetailsObject = () => {
            var now = new Date();
            const docketDetails = {
                "docketId": (docketToBeUpdated.docketId != null) ? docketToBeUpdated.docketId : 0,
                "docketNumber": (newRow.docketNumber != null && newRow.docketNumber != "") ? newRow.docketNumber : "",
                "createdBy": docketToBeUpdated.createdBy && docketToBeUpdated.createdBy !=''? docketToBeUpdated.createdBy: "System",
                "createdOn":docketToBeUpdated.createdOn && docketToBeUpdated.createdOn !=''?  new Date(docketToBeUpdated.createdOn): now,
                "modifiedBy": "System",
                "modifiedOn": now,
                "interactionId": interactionId,
                "clientFileId": clientFileId,
                "upcomingCourtDate": newRow.upcomingCourtDate ? new Date(newRow.upcomingCourtDate) : null,
                "courtLocationId": parseInt(courtLocation?.courtLocationId),
                "courtRoomId": parseInt(courtRoom?.courtRoomId),
                "legalIssues":docketToBeUpdated?.legalIssues
             }
             return docketDetails;
        };

        var DocketDetails = createDocketDetailsObject();
        setTrackEvents(JSON.stringify(DocketDetails));
        appInsights.trackTrace({ message: JSON.stringify(DocketDetails), severityLevel: SeverityLevel.Information });
        if ((null != docketToBeUpdated?.docketId) && (docketToBeUpdated?.docketId > 0)) {
            if (account) {
                instance.acquireTokenSilent(tokenRequest
                    ).then(async (response) => {
                        if (response) {
                                await UpdateDocket(DocketDetails, response.accessToken);
                        }
                });
            }
        }

        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      };
    
      const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
      };
    
    const handleCourtRoomOptions = ({ row }) => {
                if (!row) {
                    return _.uniq(_.map(allCourtRooms,'label')); // return [...new Set(allCourtRooms?.map(courtRoom => courtRoom.label))];
                }

                if (row.courtLocation != null) {
                  let filteredCourtRooms = handleCourtRoomsBasedOnLocation(row.courtLocation);
                  return _.uniq(_.map(filteredCourtRooms,'label')); //return [...new Set(selectedCourtRooms?.map(courtRoom => courtRoom.label))];
                } else {
                  return [];
                }
    };

    const handleDocketNumberPreProcessEditCellProps = (params) => {
        const hasError = params.otherFieldsProps.courtLocation.value && !params.props.value;
        if (docketNumberErrors?.filter((error) => error.docketId != params.id) && hasError){
            setDocketNumberErrors([...docketNumberErrors, {"docketId" : params.id , "hasError" : hasError}]);
        } else if (docketNumberErrors?.find((error) => error.docketId == params.id) && !hasError) {
            setDocketNumberErrors(docketNumberErrors?.filter((error) => error.docketId !== params.id));
        }
        
        return { ...params.props, error: hasError };
    };

    const handleCourtRoomPreProcessEditCellProps = (params) => {
        const hasError = params.otherFieldsProps.courtLocation.value && !params.props.value;
        if (courtRoomErrors?.filter((error) => error.docketId != params.id) && hasError){
            setCourtRoomErrors([...courtRoomErrors, {"docketId" : params.id , "hasError" : hasError}]);
        } else if (courtRoomErrors?.find((error) => error.docketId == params.id) && !hasError) {
            setCourtRoomErrors(courtRoomErrors?.filter((error) => error.docketId !== params.id));
        }
        
        return { ...params.props, error: hasError };
    };

    const canDeleteDocket = () => {
        return canDelete('docket', null);
    };

    const handleGetActions = ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={props.isDisabled}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={props.isDisabled}
            />,
          ];
        }

        return canDeleteDocket() ? [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={props.isDisabled}
          />,
           <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={props.isDisabled}
          />,
        ] : [<GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={props.isDisabled}
          />];
      };

    const columns = [   { field: 'id', width: 100, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>DocketID</div>), editable: false, hide: true },
                        { field: 'docketNumber', width: 150, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Docket Number</div>), renderCell: false, 
                        editable: props.isDisabled ? false : true , preProcessEditCellProps: handleDocketNumberPreProcessEditCellProps},
                        { field: 'upcomingCourtDate', width: 200, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Upcoming Court Date</div>), type: 'date',
                        renderCell: (params) => (<>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> ),editable: props.isDisabled ? false : true },
                        { field: 'courtLocation', width: 250, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Court Location</div>), type: 'singleSelect',
                        valueOptions: _.uniq(_.map(courtLocations,'label')), editable: props.isDisabled ? false : true , renderEditCell: (params) =>  <CustomTypeEditComponent {...params} />},
                        { field: 'courtRoom', width: 150, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Court Room</div>), type: 'singleSelect',
                        valueOptions: handleCourtRoomOptions, editable: props.isDisabled ? false : true , preProcessEditCellProps: handleCourtRoomPreProcessEditCellProps},
                        { field: 'legalIssues', width: 250, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Legal Issues</div>), editable: false, cellClassName: 'data-cell-disabled-bg-style',},
                        { field: 'actions', width: 100, renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>Actions</div>),type: 'actions',cellClassName: 'actions',
                        getActions: handleGetActions }  
                        ];    

    return (
        <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
            <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "0px"
            }}>{props.title}
            </Grid>
            <StyledDialog title="Delete Docket" open={openDeleteDialog} onClose={onDeleteClickClose}
                    message={"Do you really want to delete the Docket? This process cannot be undone."}
                    onConfirmed={onDeleteClicked} confirmationText="Delete" />
            <DataTable2 columns={columns} rows={rows} 
             rowModesModel={rowModesModel}
             onRowModesModelChange={handleRowModesModelChange}
             onRowEditStop={handleRowEditStop}
             processRowUpdate={processRowUpdate}
            />
        </Grid>
    );
   
}

export default DocketTable;
