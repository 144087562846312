import styled from "styled-components";
import ListItemText from '@mui/material/ListItemText';

export const ScreenSideBarContainer = styled.div`
  position: fixed;
  top: 160px;
  left: 50px;
  width: ${props => props.primary ? "150px" : "215px"};
  height: 100vh;
  background: #EFEFEF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  opacity: 1;
  z-index:49;
`;

export const StyledListItemText = styled(ListItemText)({
    '& .MuiTypography-root' :{
        font: 'normal normal normal 14px/16px Franklin Gothic Medium',  
    }, 
});