import { Button } from "react-bootstrap";
import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from "../hooks/useAuthorization";
import {Grid} from '@mui/material';
import DataTable from '../components/DataTable';
import CustomButton from "../components/Button";
import StyledDialog from '../components/Dialog';
import { useNavigate, useSearchParams } from "react-router-dom";
import {format} from 'date-fns';
import { useMsal, useAccount } from "@azure/msal-react";
import { GetUserDetailsById } from '../services/graph-service'
import { tokenRequest } from "../auth/authConfig";
import _ from 'lodash';
import { useNavigationParameters } from "../hooks/useNavigationParameters";
import * as Constants from "../helper/constants";

function NoteTable(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const account = useAccount(accounts[0] || {});    
    const [agencyRequests, setAgencyRequests] = useState([]);
    const [graphAPIuser, setGraphAPIuser] = useState([]);

    useEffect(() => {

    var userIDs = [];
    props.notes.forEach(element => {
        userIDs.push(element.createdBy);
        userIDs.push(element.modifiedBy);
    });

    var uniqUserIDs = _.uniqBy(userIDs);    

        if (uniqUserIDs && uniqUserIDs.length > 0){
            const FetchData = (token) => {
                var userDet = [];
                uniqUserIDs.forEach(userID => {
                    const userDetails = GetUserDetailsById(userID, token).then(response => {
                        userDet.push({id : userID, displayName : response.displayName});                          
                        if(userDet.length == uniqUserIDs.length) {
                            setGraphAPIuser(userDet);
                            return;
                        }          
                    })                
                });
            }
        
        
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }
    }
    }, [props.notes]);


    const columnHeaders =   [{field:'id', width: 80, headerName:'ID', renderCell: true, editable: false, hide :true }, 
                            {field: 'noteText', width: 800, headerName:'Note Text', editable: false }, 
                            {field: 'createdOn', width: 120, headerName:'Created On', editable: false, type: 'date', renderDate: true  }, 
                            {field: 'createdBy', width: 200, headerName:'Created By', editable: false },                             
                            ];                    
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {   field: columnHeader.field, 
                renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                //renderCell: columnHeader.renderCell ? (params) => (<a href={'/note?id=' + params.value.split("-")[1] + '&isDisabled='+(props.isDisabled?props.isDisabled: false)+'&relatedId=' + props.relatedId + '&noteTypeId=' + props.noteTypeId +'&clientFileId='+props.clientFileId+'&page='+props.pageId}>{params.value}</a>) : null,

                
                renderCell: (params) => ((columnHeader.renderCell ? <a href={`${prepareNavigationParameters(Constants.NOTEDETAILS, new Array(props.noteTypeId ,props.relatedId) , null, "id="+params.value.split("-")[1]+'&isDisabled='+(props.isDisabled?props.isDisabled: false)+'&clientFileId='+props.clientFileId+(props.pageId ? '&page=' + props.pageId : ""))}`}>{params.value}</a>: 
                (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                
                width: columnHeader.width,
                editable : columnHeader.editable,
                hide : columnHeader.hide
            },
        );
    });
    
    const rows = [];
    if (props.notes && props.notes.length > 0) {
        props.notes.forEach((note) => {
            var createdBy = "";
            graphAPIuser.forEach(element => {
                if(element.id == note.createdBy) {
                    createdBy = element.displayName;                    
                }
            });
            
            if (canRead("client-note", note.createdBy.email)) {  
            rows.push(
                { id: `CN-${note.noteId}`, 
                noteText: (((note.noteText != null) && (note.noteText.length > 100)) ? note.noteText.substring(0, 100) : note.noteText), 
                createdOn: note.createdOn, 
                createdBy: createdBy,},
            );
        } else {
            return (<table><tbody><tr><td>You are not authorized to read this record.</td></tr></tbody></table>);
        };
        });
    };

    const onNotesRowDoubleClick = (item, event) => {
        navigate(`${prepareNavigationParameters(Constants.NOTEDETAILS, new Array(props.noteTypeId ,props.relatedId) , null, "id="+item.row.id.split("-")[1]+'&clientFileId='+props.clientFileId+(props.pageParams ? '&' + props.pageParams : '')+(props.pageId ? '&page=' + props.pageId : ""))}`);
        
    };

    const onOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };
    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirmClicked = () => {
        navigate(`${prepareNavigationParameters(Constants.NOTEDETAILS, new Array(props.noteTypeId ,props.relatedId) , null, 'id=0&clientFileId='+props.clientFileId+(props.queryParams ? '&' + props.queryParams : '')+(props.pageParams ? '&' + props.pageParams : '')+(props.pageId ? '&page=' + props.pageId : ""))}`);    
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <>
                <StyledDialog title="Saved Changes?"
                            open={openConfirmDialog}
                            onClose={onCloseConfirmDialog}
                            message={"Any unsaved changes will be lost if you try to add a new note without saving your changes. Are you sure you want to proceed?"}
                            onConfirmed={onConfirmClicked} confirmationText="Continue" isCancelRequired={true}
                />
                <Grid container style={{ padding: props.hideBordfer ? "5px" : "5px 20px 5px 20px", border: props.hideBorder ? "" : "1px solid #7F8284", margin: "10px 0px 0px 0px", borderRadius: "8px" }}>
                    {!props.hideHeader
                        && <>
                                <Grid item xs={8} md={8} style={{ textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium", letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px" }}
                                >
                                    {props.title}
                                </Grid>
                                <Grid item xs={4} md={4} style={{ textAlign: "end", minWidth: "97px"}}>
                                    <CustomButton
                                        variant="contained"
                                        label="New Note"
                                        size="small"
                                        disabled={ props.isDisabled ? true : ((canCreate("client-note", null)) ? false : true) }
                                        onClick={onOpenConfirmDialog}
                                    />
                                </Grid>
                            </>
                    }
                    <DataTable columns={columns} rows={rows} onRowDoubleClick={onNotesRowDoubleClick} dataGridtitle={props.dataGridtitle ? props.dataGridtitle : ""} hideTotalCount={props.hideTotalCount ? true : false}/>
                </Grid>
            </>
        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium", letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px" }}>
                    {props.title}
                </Grid>

                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium", letterSpacing: "0px", color: " #000000", opacity: "1", }}>
                    ** Save the new Client File to add new Notes **
                </Grid>            
            </Grid>
        );
    }
}

export default NoteTable;
