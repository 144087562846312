import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const SelectorStyledTextField = styled(TextField)(({ customwidth, labelColor }) => ({
  "& label.Mui-focused": {
    color: labelColor || "#e25928",
    transform: "translate(16px, -10px) scale(1)",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: labelColor || "#454748",
  },
  "& .MuiInputLabel-root": {
    zIndex: "0",
    color: labelColor || "#585454",
    font: "normal normal normal 14px/15px Franklin Gothic Medium",
    transform: "translate(16px, -10px) scale(1)",
  },
  "& .MuiInputBase-input": {
    width: customwidth ? customwidth : "229px",
    textAlign: "left",
    font: "normal normal normal 14px/16px Franklin Gothic Medium",
    letterSpacing: "0px",
    color: "#6c6869",
    opacity: "1",
  },
  "& .MuiFormLabel-asterisk": {
    color: "#d32f2f",
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f",
    font: "normal normal normal 14px/15px Franklin Gothic Medium",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #C4C4C4",
      "& legend": {
        minWidth: "fit-content",
        padding: "0px 15px 0px 5px",
      },
    },
    "&:hover fieldset": {
      border: "1px solid #e25928",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #e25928",
      "& legend": {
        minWidth: "fit-content",
        padding: "0px 15px 0px 5px",
      },
    },
    "&.Mui-disabled fieldset": {
      border: "1px solid #C4C4C4",
    },
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "#6c6869",
  },
  "& .MuiOutlinedInput-input.MuiSelect-select": {
    whiteSpace: "normal",
  },
}));

export const SelectorStyledDashBoardTextField = styled(SelectorStyledTextField)({
  "& .MuiInputBase-input": {
    width: "100%", // 184px
    minWidth: "139px",
  },
});
