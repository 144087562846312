import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import SubHeader from '../../../components/SubHeader';
import StyledDialog from '../../../components/Dialog';
import SelectedDataRow from '../../../components/elements/SelectedDataRow';
import { tokenRequest } from "../../../auth/authConfig";
import { GetConflictedInteractionById, GetDcpUnConflictedClientFiles, ResolveConflictedInteraction } from "../../../services/interaction-conflict-dashboard.service";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FetchLookupData } from "../../../services/client-file.service";
import Badge from '@mui/material/Badge';
import Backdrop from '../../../components/elements/Loader';
import _ from "lodash";
import * as Constants from "../../../helper/constants";
import { toast } from "react-toastify";
import StyledButton from '../../../components/elements/StyledButton';
import { CreateClientFile, CheckDuplicateClients, GetClientFileFromCrm } from "../../../services/client-file.service";
import { format } from 'date-fns';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

export default function ResolveInteractionConflicts() {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [dcpClientFiles, setDcpClientFiles] = useState([]);
    const [interactions, setInteractions] = useState([]);
    const [clientFileDetails, setClientFileDetails] = useState([]);
    const [crmClientFile, setCrmClientFile] = useState([]);
    const [crmRecord, setCrmRecord] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);

    const [selectedInteractionRow, setSelectedInteractionRow] = useState(null);
    const [selectedDcpRow, setSelectedDcpRow] = useState(null);
    const [selectedDcpClientFile, setSelectedDcpClientFile] = useState(null);

    const [gridColumns, setGridColumns] = useState([]);
    const [gridColumnsInteraction, setInteractionGridColumns] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstNameQueryParamValue, setFirstNameQueryParamValue] = useState(null);
    const [lastNameQueryParamValue, setLastNameQueryParamValue] = useState(null);
    const [dateOfBirthQueryParamValue, setDateOfBirthQueryParamValue] = useState(null);
    const [idQueryParamValue, setIdQueryParamValue] = useState(searchParams.get("interactionId"));


    const [openUnconflictedDcpClientFile, setOpenUnconflictedDcpClientFile] = useState(false);
    const [menu2Visible, setMenu2Visible] = useState(true);
    const [menu3Visible, setMenu3Visible] = useState(true);
    const [openInteraction, setOpenInteraction] = useState(true);

    const [openCRMClientFile, setOpenCRMClientFile] = useState(false);
    const [markAsReviewed, setMarkAsReviewed] = useState(false);
    const [createClientFileAndMarkAsReviewed, setCreateClientFileAndMarkAsReviewed] = useState(false);

    const [openDcpClientFile, setOpenDcpClientFile] = useState(false);
    const [openMergeFileDialog, setOpenMergeFileDialog] = useState(false);

    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();
    const navigate = useNavigate();
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Resolve Interaction Conflicts : ", trackEvents);

    useEffect(() => {
        LoadInitialData();
    }, [instance]);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

    const LoadInitialData = async () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    setInitialGridColumns();
                    setInitialInteractionGridColumns();
                    if (idQueryParamValue) {

                        await GetConflictedInteractionById(parseInt(idQueryParamValue), response.accessToken).then(async (data) => {
                            setInteractions(data);
                            if (data.length > 0 && data[0].clientFileCrmId != null) {
                                await GetClientFileFromCrm(data[0].clientFileCrmId, true, response.accessToken).then((rowData) => {
                                    if (rowData) {
                                        setCrmClientFile(rowData);
                                    }
                                })
                            }

                            if (data[0] && data[0].clientFileId) {
                                setMarkAsReviewed(false);
                            }
                            else {
                                setMarkAsReviewed(true);
                            }
                        });

                        setCreateClientFileAndMarkAsReviewed(true);

                        await GetDcpUnConflictedClientFiles(response.accessToken, parseInt(idQueryParamValue)).then((rowData) => {
                            if (rowData && rowData != null) {
                                if (rowData.dcpClientFiles && rowData.dcpClientFiles != null) { setDcpClientFiles(rowData.dcpClientFiles); }
                                if (rowData.clientFileDetails && rowData.clientFileDetails != null) { setClientFileDetails(rowData.clientFileDetails); }
                                if (rowData.crmClientFile && rowData.crmClientFile != null) { setCrmRecord(rowData.crmClientFile); }
                            }
                            else {
                                setMenu2Visible(false);
                                setMenu3Visible(false);
                                setOpenInteraction(false);
                            }
                        })
                        await FetchLookupData(response.accessToken).then((data) => {
                            if (data)
                                setContactMethods(data.contactMethods);
                                setIndigenousAncestries(data.indigenousAncestries);
                        })

                    }
                }
            }).then(() => {setIsDataReady(true)});
        }
    }

    const setInitialInteractionGridColumns = () => {
        const columns = [];

        const columnHeaders = [{ headerName: 'Interaction ID', width: 160, renderCell: true, field: 'id', editable: false },
        { headerName: 'Docket number', width: 250, field: 'docketNumber', editable: false },
        { headerName: 'Court location', width: 250, field: 'courtLocation', editable: false },
        { headerName: 'Upcoming court date', type: 'date', width: 150, field: 'upcomingCourtDate', renderDate: true, editable: false },
        { headerName: 'Court room', width: 180, field: 'courtRoom', editable: false },
        { headerName: 'Assigned To', width: 180, field: 'assignedTo', editable: false },
        { headerName: 'Outcome Status', width: 180, field: 'outcomeStatus', editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => (columnHeader.renderCell ? <> {params.value} {params.value.split("-")[1] == idQueryParamValue ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null}</> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null)),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setInteractionGridColumns(columns);
    }

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 175, field: 'id', renderCell: true, editable: false },
        { headerName: 'First Name', width: 250, field: 'firstName', editable: false },
        { headerName: 'Last Name', width: 225, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 110, field: 'dateOfBirth', renderDate: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => (columnHeader.renderCell ? <> {params.value} {params.value == idQueryParamValue ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null}</> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null)),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }

    const onInteractionRowClick = (item, event) => {
        if (openInteraction) {
            setSelectedInteractionRow(item.row);
            handleAutoScroll();
            setOpenCRMClientFile(Boolean(event.currentTarget));
        }
    };

    const onDcpRowClick = (item, event) => {
        setSelectedDcpRow(item.row);
        const selectedDcpRecord = _.filter(clientFileDetails, { 'clientFileId': parseInt(item.row.id.split("-")[1]) });
        setSelectedDcpClientFile(selectedDcpRecord[0]);
        setOpenUnconflictedDcpClientFile(Boolean(event.currentTarget));

        handleAutoScroll();
    };

    const handleAutoScroll = () => {
        const element = document.getElementById('scrollByClickedItem1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const [openConfirmMarkAsUnconflictedDcpConflictedDialog, setOpenConfirmMarkAsUnconflictedDcpConflictedDialog] = useState(false);
    const onConfirmMarkAsUnconflictedDcpFileClickOpen = () => {
        setOpenConfirmMarkAsUnconflictedDcpConflictedDialog(true);
    };
    const onConfirmMarkAsUnconflictedDcpFileClickClose = () => {
        setOpenConfirmMarkAsUnconflictedDcpConflictedDialog(false);
    };

    const onMarkAsUnconflictedDcpUnConflictedClicked = async (event) => {
        setTrackEvents("Resolving Interaction Conflict By Interaction Id : " + idQueryParamValue);
        appInsights.trackTrace({ message: "Resolving Interaction Conflict By Interaction Id : " + idQueryParamValue, severityLevel: SeverityLevel.Information });
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await ResolveConflictedInteraction(response.accessToken, parseInt(idQueryParamValue), 0, false).then((response) => {

                        if (response) {
                            toast.success("Interaction merged successfully.", { position: "top-center", autoClose: 5000, theme: "colored" });

                            navigateToPage();
                        }
                    });
                }
            });
        }
    }

    const [openConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog, setOpenConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog] = useState(false);
    const onConfirmCreateClientMarkAsUnconflictedDcpFileClickOpen = () => {
        setOpenConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog(true);
    };
    const onConfirmCreateClientMarkAsUnconflictedDcpFileClickClose = () => {
        setOpenConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog(false);
    };

    const getContactMethod = (crmId) => {
        var result = 1;
        if ((null != contactMethods) && (!isNaN(crmId))) {
            var contactMethod = contactMethods.find(c => c.contactMethodCrmValue == crmId);
            if (null != contactMethod) {
                result = contactMethod.contactMethodId
            }
        }
        return result
    }

    const getIndigenousAncestry = (crmId) => {
        var result = 1;
        if ((null != indigenousAncestries) && (!isNaN(crmId))) {
            var indigenousAncestry = indigenousAncestries.find(c => c.indigenousAncestryCrmValue == crmId);
            if (null != indigenousAncestry) {
                result = indigenousAncestry.indigenousAncestryId
            }
        }
        return result
    }

    const onCreateClientFileClicked = async (isDcpConflicted) => {
        setOpenConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog(false);
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    var hasDuplicateClients = await CheckDuplicateClients(crmRecord.clientBasicInformation.firstName, crmRecord.clientBasicInformation.lastName, crmRecord.clientBasicInformation.dateOfBirth, 0, response.accessToken);
                    if (hasDuplicateClients) {
                        // Duplicate records exist
                        // Get Confirmation
                        onCreateDuplicateClientConfirmClickOpen();
                    } else {
                        // No Duplicate records
                        // Create a Non DCP Conflicted Client File
                        saveClientDetails(true, true);
                    }
                }

            });
        }

    }

    const saveClientDetails = async (isDcpConflicted, isCreate) => {
        var clientFileCrmId = crmRecord.clientFileCrmId;
        var isCrmConflicted = null;
        var updatedDateOfBirth = new Date(crmRecord.clientBasicInformation.dateOfBirth).setHours(0, 0, 0);
        var newClientDetails = {
            "ClientFileId": 0,
            "clientFileCrmId": clientFileCrmId,
            "isCrmConflicted": isCrmConflicted,
            "isDcpConflicted": isDcpConflicted,
            "crmClientId" : crmRecord.crmClientId,
            "clientBasicInformation": {
                "clientBasicInformationId": 0,
                "firstName": crmRecord.clientBasicInformation.firstName,
                "middleName": crmRecord.clientBasicInformation.middleName,
                "lastName": crmRecord.clientBasicInformation.lastName,
                "dateOfBirth": new Date(updatedDateOfBirth),
                "clientAlias1": crmRecord.clientBasicInformation.clientAlias1,
                "clientAlias2": crmRecord.clientBasicInformation.clientAlias2,
                "clientAlias3": crmRecord.clientBasicInformation.clientAlias3,
                "indigenousAncestryId": getIndigenousAncestry(Number(crmRecord.clientBasicInformation.indigenousAncestry?.indigenousAncestryCrmValue)),
                "createdOnInCRM": (null == crmRecord.clientBasicInformation.createdOnInCRM || "" == crmRecord.clientBasicInformation.createdOnInCRM) ?  null : new Date(crmRecord.clientBasicInformation.createdOnInCRM)
            },
            "clientContactDetails": {
                "clientContactInfoId": 0,
                "email": crmRecord.clientContactDetails.email,
                "phoneNumber": crmRecord.clientContactDetails.phoneNumber,
                "mobileNumber": crmRecord.clientContactDetails.mobileNumber,
                "PreferredMethodOfContactId": getContactMethod(Number(crmRecord.clientContactDetails.preferredMethodOfContact?.contactMethodCrmValue))
            },
            "ClientFinancialInformation": {
                "clientFinancialInformationId": 0,
                "financiallyEligible": Boolean(crmRecord.clientFinancialInformation.financiallyEligible),
                "familySize": (crmRecord.clientFinancialInformation.familySize && crmRecord.clientFinancialInformation.familySize != '') ? crmRecord.clientFinancialInformation.familySize : null,
                "last12MonthsIncome": crmRecord.clientFinancialInformation.last12MonthsIncome != '' ? crmRecord.clientFinancialInformation.last12MonthsIncome : null,
                "last30DaysIncome": crmRecord.clientFinancialInformation.last30DaysIncome != '' ? crmRecord.clientFinancialInformation.last30DaysIncome : null,
                "totalEquityValue": crmRecord.clientFinancialInformation.totalEquityValue != '' ? crmRecord.clientFinancialInformation.totalEquityValue : null,
                "lastAssessmentDate": (null == crmRecord.clientFinancialInformation.lastAssessmentDate || "" == crmRecord.clientFinancialInformation.lastAssessmentDate) ? null : new Date(crmRecord.clientFinancialInformation.lastAssessmentDate)
            }
        };

        setTrackEvents(JSON.stringify(newClientDetails));
        appInsights.trackTrace({ message: JSON.stringify(newClientDetails), severityLevel: SeverityLevel.Information });

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {

                    await CreateClientFile(newClientDetails, response.accessToken).then(async (data) => {
                        if (data && Number(data) && Number(data) > 0) {
                            await ResolveConflictedInteraction(response.accessToken, searchParams.get("interactionId"), data, false).then((response) => {

                                if (response) {
                                    toast.success("Interaction linked successfully.", { position: "top-center", autoClose: 5000, theme: "colored" });
                                }
                            });
                            navigateToPage();
                        }
                    });
                }
            });
        }
    }


    const onCancelClicked = () => {
        navigateToPage() ;
    };
    const onMergeFileClickOpen = () => {
        setOpenMergeFileDialog(true);
    };
    const onMergeFileClickClose = () => {
        setOpenMergeFileDialog(false);
    };

    function navigateToPage() {
        navigate(`${prepareNavigationParameters(null, null)}`);
    }
  

    const resolveInteractionConflicts = () => {
        if (selectedDcpRow != null & selectedInteractionRow != null) {
            var resolveInteractionConflictsParams = {
                "interactionId" : selectedInteractionRow.id.split("-")[1],
                "dcpId" : selectedDcpRow.id.split("-")[1]
            }
            setTrackEvents("Resolving Interaction Conflicts by : " + JSON.stringify(resolveInteractionConflictsParams));
            appInsights.trackTrace({ message: "Resolving Interaction Conflicts by: " + JSON.stringify(resolveInteractionConflictsParams), severityLevel: SeverityLevel.Information });
            
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        await ResolveConflictedInteraction(response.accessToken, selectedInteractionRow.id.split("-")[1], selectedDcpRow.id.split("-")[1], false).then((response) => {

                            if (response) {
                                toast.success("Interaction merged successfully.", { position: "top-center", autoClose: 5000, theme: "colored" });

                                navigateToPage();
                            }
                        });
                    }
                });
            }
        }
    };

    const [openCreateDuplicateClientConfirmDialog, setOpenCreateDuplicateClientConfirmDialog] = useState(false);

    const onCreateDuplicateClientConfirmClickOpen = () => {
        setOpenCreateDuplicateClientConfirmDialog(true);
    };

    const onCreateDuplicateClientConfirmClickClose = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File Aborted
        toast.success("Create Client File aborted.", { position: "top-center", autoClose: 5000, theme: "colored" });
    };

    const onCreateDuplicateClientConfirmClicked = () => {
        // Create a DCP Conflicted Client File
        saveClientDetails(true, true);
    };


    if (isDataReady) {
        return (<>
            {(canRead("interaction-conflict-dashboard", null)) &&
                <Grid container style={{ padding: "5px 20px 0px 20px", margin: "80px 0px", scrollMarginTop: "80px" }}>
                    <SubHeader
                        title="Review Interactions"
                        menuItemOneText=""
                        onMenuItemOneClicked=""
                        isMenuItemOneVisible={false}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText="Mark as Reviewed"
                        onMenuItemTwoClicked={onConfirmMarkAsUnconflictedDcpFileClickOpen}
                        isMenuItemTwoVisible={menu2Visible}
                        menuItemTwoType="CrmUnConflicted"
                        isMenuItemTwoDisabled={markAsReviewed}
                        menuItemThreeText="Mark as Reviewed and Create Client File"
                        onMenuItemThreeClicked={onConfirmCreateClientMarkAsUnconflictedDcpFileClickOpen}
                        isMenuItemThreeVisible={menu3Visible}
                        menuItemThreeType="CrmUnConflicted"
                        isMenuItemThreeDisabled={false}
                        navItemOneText="Link Files"
                        onNavItemOneClicked={onMergeFileClickOpen}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={!(openCRMClientFile && openUnconflictedDcpClientFile)}
                        navItemTwoText="Cancel"
                        onNavItemTwoClicked={onCancelClicked}
                        isNavItemTwoVisible={true}
                        isNavItemTwoDisabled={false}
                        navItemThreeText=""
                        onNavItemThreeClicked=""
                        isNavItemThreeVisible={false}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />
                    <StyledDialog title="Mark as Reviewed" open={openConfirmMarkAsUnconflictedDcpConflictedDialog} onClose={onConfirmMarkAsUnconflictedDcpFileClickClose}
                        message={"Do you really want to mark the I-" + idQueryParamValue + " as reviewed?"}
                        onConfirmed={onMarkAsUnconflictedDcpUnConflictedClicked} confirmationText="Mark as Reviewed" isCancelRequired={true}/>

                    <StyledDialog title="Create Client File & Mark as Reviewed" open={openConfirmCreateClientMarkAsUnconflictedDcpConflictedDialog} onClose={onConfirmCreateClientMarkAsUnconflictedDcpFileClickClose}
                        message={"Do you really want to create a client file and mark the I-" + idQueryParamValue + " as reviewed?"}
                        onConfirmed={onCreateClientFileClicked} confirmationText="Create Client File & Mark as Reviewed" isCancelRequired={true}/>

                    <StyledDialog title="Client File Already Exists!"
                        open={openCreateDuplicateClientConfirmDialog}
                        onClose={onCreateDuplicateClientConfirmClickClose}
                        message={"There is already a DC Portal Client File with the same First and Last names and date of birth. Are you sure you want to create a new file for this client?"}
                        onConfirmed={onCreateDuplicateClientConfirmClicked}
                        confirmationText="Create Client" isCancelRequired={true} />

                    <StyledDialog title="Link DC Portal Client File with the Interaction" open={openMergeFileDialog} onClose={onMergeFileClickClose}
                        message={"Are you sure you want to update the merging for this Client File?"}
                        onConfirmed={resolveInteractionConflicts} confirmationText="Confirm" isCancelRequired={true}/>


                    <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}>
                            <Grid item xs={12} md={12} style={{
                                textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                            }}>Interaction</Grid>


                            {openCRMClientFile === true ? (<>
                                <SelectedDataRow title={`CRM Client File ${crmClientFile?.crmClientId} : ${crmClientFile?.clientBasicInformation.firstName}  ${crmClientFile?.clientBasicInformation.lastName} ${new Date(crmClientFile?.clientBasicInformation.dateOfBirth).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-')} `} clientFile={crmClientFile} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} onClose={() => { setOpenCRMClientFile(false) }} />
                            </>) : (<>
                                <DataTable columns={gridColumnsInteraction} rows={interactions} disableSelection={false} onRowClick={onInteractionRowClick} />
                            </>)}

                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                        }}>Portal Client File</Grid>
                        {openUnconflictedDcpClientFile === true ? (<>
                            <SelectedDataRow title={`Reviewed DC Portal Client File ${selectedDcpRow?.id} : ${selectedDcpRow?.firstName}  ${selectedDcpRow?.lastName} ${new Date(selectedDcpRow?.dateOfBirth).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-')} `} clientFile={selectedDcpClientFile} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} isResolveUnconflictedDcpConflicts={true} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} onClose={() => { setOpenUnconflictedDcpClientFile(false) }} />
                        </>) : (<>
                            <DataTable columns={gridColumns} rows={dcpClientFiles} disableSelection={false} onRowClick={onDcpRowClick} />
                        </>)}

                    </Grid>
                </Grid>}
        </>
        );
    } else {
        return (
            <Backdrop />
        );
    }
};
