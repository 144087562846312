import appconfig from '../appConfig'
import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";

const SERVICENAME = "storage/";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const STORAGEACCOUNTNAME = appconfig.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;

export async function GetAttachments(storageContainerName, recordId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return fetch(serviceUrl, options)
        .then(response => {

            var data = response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function DeleteAttachment(storageContainerName, recordId, filename, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    //This should be reverted back
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId + "/" + filename;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {

            if (response.ok) {
                return true;
            }
            // check for error response
            else {
                return false;
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });

}

export async function UploadAttachment(storageContainerName, recordId, file, token, submittedById, submittedByName) {
    try {
        const uploadToStorageResponse = await UploadToStorage(
            storageContainerName,
            recordId,
            file,
            token,
            submittedById,
            submittedByName
        );

        if (uploadToStorageResponse._response.status == '201') {
            UpdateEntityMetadata(storageContainerName, recordId, token);
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        throw error;
    }
}

export async function DownloadAttachments(storageContainerName, recordId, filename, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    let response = await DownloadFromStorageAPI(storageContainerName, recordId, filename, token);

    return response;
}

export async function UploadToStorage(storageContainerName, recordId, file, token, submittedById, submittedByName) {

    try {
        let sasToken = await GetSASTokenFor(storageContainerName, 'Upload', token);
        let uploadUrlWithSasToken = `https://${STORAGEACCOUNTNAME}.blob.core.windows.net${sasToken}`;

        let blobService = new BlobServiceClient(uploadUrlWithSasToken);

        let containerClient =
            blobService.getContainerClient(storageContainerName);

        let filename = `${recordId}/${file.name}`;

        let blobClient = containerClient.getBlockBlobClient(filename);

        let metaData = {
            externalReferenceEntityName: `${storageContainerName}_Attachment`,
            externalReferenceRecordId: `${recordId}_${file.name}`,
            externalReferenceActivityType: 'Create',
            externalReferenceActivityBy: `${submittedById}`,
            externalReferenceActivityByName: `${submittedByName}`,
            externalReferenceActivityOn: new Date().toISOString(),
            externalReferenceHasThisBeenAddedToAuditLog: 'False'
        }

        let options = {
            blobHTTPHeaders: { blobContentType: file.type },
            metadata: metaData
        };

        let uploadBlobResponse = await blobClient.uploadData(file, options);

        return uploadBlobResponse;
    }
    catch (error) {
        throw error;
    }
}

export async function GetSASTokenFor(storageContainerName, action, token) {

    try {
        if (token == null) {
            toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
            return;
        }
        let serviceUrl = `${appconfig.API_SERVER_URL}${SERVICENAME}${storageContainerName}?action=${action}`;

        let headers = new Headers();
        let bearer = BEARER + token;
        headers.append(AUTHORIZATION, bearer);

        let options = {
            method: 'GET',
            headers: headers
        }

        return fetch(serviceUrl, options)
            .then(response => {
                var data = response.text();
                if (response.ok) return data;
                // check for error response
                else {
                    // get error message from body or default to response statusText
                    let error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                //console.error('Error occurred.', error);
                return Promise.reject(error);
            });
    }
    catch (error) {
        throw error;
    }
}

export async function UpdateEntityMetadata(storageContainerName, recordId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = `${appconfig.API_SERVER_URL}${SERVICENAME}${storageContainerName}/${recordId}`;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);

    const options = {
        method: 'POST',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            if (response.ok) { return true; }
            else { return false; }
        })
        .catch(error => {
            //console.error('Error occurred.', error);
            return Promise.reject(error);
        });
}

export async function DownloadFromStorageAPI(storageContainerName, recordId, filename, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        // alert(INVALIDTOKEN);
        return;
    }

    let sasToken = await GetSASTokenFor(storageContainerName, 'Download',token);
    let uploadUrlWithSasToken = `https://${STORAGEACCOUNTNAME}.blob.core.windows.net/${storageContainerName}/${recordId}/${filename}${sasToken}`;

    window.open(uploadUrlWithSasToken);
}