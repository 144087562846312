import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/interactions";
const UPDATESERVICEDECISION = "api/crmServiceDecisionDutyCounselTriages";
const GETMETADATAREQUESTNAME = "/metadata";
const GETFORCLIENTIDREQUESTNAME = "/forclient";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const UPDATESUCCESS = "Interaction successfully updated.";
const UPDATEFAILED = 'Update Interaction Failed.';

const SUBMITFAILED = 'Submit Interaction Failed.';
const UPDATESERVICEDECISIONSUCCESS = "CRM service decision successfully updated." ;
const UPDATESERVICEDECISIONFAILED = "Update CRM service decision Failed." ;
const MARKCONFLICTEDSUCCESS = "Interaction successfully marked for Review.";
const MARKCONFLICTEDFAILED = 'Failed to Mark Interaction for Review.';
const MARKUNCONFLICTEDSUCCESS = "Interaction successfully Cleared from Review.";
const MARKUNCONFLICTEDFAILED = 'Failed to Clear Interaction from Review.';
const DELETESUCCESS = "Interaction successfully deleted.";
const DELETEFAILED = 'Delete Interaction Failed.';


const SUBMITSUCCESSNOTIFICATIONSENT = "Interaction successfully submitted and notification sent.";
const SUBMITSUCCESSNOTIFICATIONNOTSENT = "Interaction successfully submitted and notification was not sent.";
const UPDATESUCCESSNOTIFICATIONSENT = "Interaction successfully updated and notification sent.";
const UPDATESUCCESSNOTIFICATIONNOTSENT = "Interaction successfully updated and notification was not sent.";

export async function GetInteractionMetaData(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETMETADATAREQUESTNAME;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
};

export async function GetInteractionById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetAllInteractions(clientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETFORCLIENTIDREQUESTNAME + "?clientFileId=" + clientFileId + '&onlyLastTwoYears=true';

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
    .then(async response => {
        var data = await response.json();
        if (response.ok) return data
        // check for error response
        else {
            // get error message from body or default to response statusText
            const error =  response.statusText;
            return Promise.reject(error);
        }

    })
    .catch(error => {
        console.log( error);
    });
}

export async function SubmitInteraction(interaction, token) {
    return UpdateInteractionInternal(interaction, token, true);
}

export async function MarkInteractionForReview(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/SetConflicted?interactionId=" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(MARKCONFLICTEDSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error(MARKCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error(MARKCONFLICTEDFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function UpdateInteraction(interaction, token, isBackground) {
    return UpdateInteractionInternal(interaction, token, false, isBackground);
}

export async function UpdateInteractionInternal(interaction, token, isSubmit, isBackground) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + interaction.interactionId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(interaction)
    }

    var result = await fetch(serviceUrl, options).then(async response => {
        var data = await response.json();
        if (response.ok) {
            if (data != 0 && isBackground) { return true; }
            if (data == 0) {
                toast.error((isSubmit) ?SUBMITFAILED: UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});              
            }
            else if (data == 1 || data == 3) {
                toast.success((isSubmit) ?SUBMITSUCCESSNOTIFICATIONNOTSENT: UPDATESUCCESSNOTIFICATIONNOTSENT,{position: "top-center",autoClose: 5000,theme: "colored"});               
            }
            else if (data == 2) {
                toast.success((isSubmit) ?SUBMITSUCCESSNOTIFICATIONSENT: UPDATESUCCESSNOTIFICATIONSENT,{position: "top-center",autoClose: 5000,theme: "colored"});              
            }           
            return true;
        } else {
            if (!isBackground) {
                toast.error((isSubmit) ? SUBMITFAILED : UPDATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });          
            }
        }      
    }).catch(error => {
        toast.error((isSubmit) ? SUBMITFAILED : UPDATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function UpdateCrmServiceDecisionDutyCounselTriage(serviceDecisionDetails, decisionByUserId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + UPDATESERVICEDECISION +  "?decisionByUserId=" + decisionByUserId ; 

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(serviceDecisionDetails)
    }

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success( UPDATESERVICEDECISIONSUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
            return response.json();
        } else {
            toast.error( UPDATESERVICEDECISIONFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    }).catch(error => {
        toast.error( UPDATESERVICEDECISIONFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });

    return result;
}

export async function DeleteInteraction(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) {
                toast.success(DELETESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert(DELETESUCCESS)
                return data;
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                toast.error(DELETEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert(DELETEFAILED)
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}