import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthBlock from "../auth/AuthBlock";
import {
  HeaderBar,
  CRMDownBar,
  CRMDownBarText,
  ImageContainer,
  TitleContainer 
} from "../components/styled/Header.styled";
import { BsFillExclamationOctagonFill } from "react-icons/bs";
import { GetCrmStatus } from "../services/crm-status-service";
import {useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";

export default function Header(props) {
  const navigate = useNavigate();
  const [isCRMDown, setIsCRMDown] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLogoClickDisabled, setIsLogoClickDisabled] = useState(false);

  useEffect(() => {
    if (window.location.pathname == "/accessdenied") {
      setIsLogoClickDisabled(true);
    }
    if (window.location.pathname == "/clientfiles/search" || window.location.pathname == "/" || window.location.pathname == "/reviewdashboard") {        
      const fetchCrmStatus= async (token) => {
        await GetCrmStatus(token).then((status) => {
          if (status.isPlannedOutage == true || status.crmStatus == 0) {
            setIsCRMDown(true);
          } else {
            setIsCRMDown(false);
          }      
      });
      }
      if (account) {
          instance.acquireTokenSilent(
              tokenRequest
          ).then((response) => {
              if (response) {
                fetchCrmStatus(response.accessToken);
              }
          });
      }
    }
  }, [window.location.pathname]);

  const handleLogoClick = () => {
    if (!isLogoClickDisabled) navigate('/');
  }
  
  return (
    <HeaderBar>
    <ImageContainer>
      <img src={"../images/logo.png"} alt="logo" height="90%" onClick={handleLogoClick}/>
    </ImageContainer>
    <TitleContainer>Duty Counsel Portal</TitleContainer>
    <AuthBlock />
    {isCRMDown && <><CRMDownBar> <CRMDownBarText><b><BsFillExclamationOctagonFill size={16} style={{paddingBottom : "2px"}}/></b> CRM and some DC Portal functionalities are currently unavailable. You can continue to work and the client information will auto sync once CRM is available.</CRMDownBarText> </CRMDownBar></>}
    </HeaderBar>
  );
}

