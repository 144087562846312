import { useAccount, useMsal } from "@azure/msal-react";
import { ChangeEvent, useEffect, useState } from "react";
import { tokenRequest } from "../../../auth/authConfig";
import AttachmentRow from "./attachment-row";
import { GetAttachments, UploadAttachment } from "../../../services/storage-service";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { amber } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import UploadIcon from '@mui/icons-material/Upload';

function AttachmentList(props: any) {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [attachments, setAttachments] = useState(props.attachments);
    const [file, setFile] = useState<File>();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles = () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }

    const fetchInitialData = async (token: string) => {
        if (props.recordId && props.recordId > 0) {
            await GetAttachments(props.containerName, props.recordId, token).then((data) => {
                setAttachments(data);
            });
        }
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
        var isExists = false;
        if (attachments && attachments.length > 0) {
            attachments.forEach((attached: any) => {
                if (attached.name == file.name) {
                    isExists = true;
                }
            });
        }
        if (file.size == 0) {
            toast.error("Uploading file cannot be empty.", {
                position: "top-center",
                autoClose: 5000,
                theme: "colored"
            });
            //alert("Uploading file cannot be empty.");
            return;
        }
        if (!isExists) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        try {
                            setLoading(true);
                            const status = await UploadAttachment(props.containerName, props.recordId, file, response.accessToken, account.localAccountId, account.name);
                            if (status) {
                                setLoading(false);
                                toast.success("File successfully uploaded.", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    theme: "colored"
                                });
                                setIsFilePicked(false);
                                loadFiles();
                            }
                            else {
                                setLoading(false);
                                toast.error("File did not upload. Please try again.", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    theme: "colored"
                                });
                            }
                        }
                        catch (error) {
                            setLoading(false);
                            toast.error(
                                'An error occurred. Please try again later.',
                                {
                                    position: "top-center",
                                    autoClose: 5000,
                                    theme: "colored"
                                }
                            );
                        }
                    }
                });
            }
        }
        else {
            toast.error("File with the same name already exists.", {
                position: "top-center",
                autoClose: 5000,
                theme: "colored"
            });
        }
    };


    return (
        <>
            <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 15px/18px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 0px", padding: "0px 10px 0px 10px"
            }}>{props?.title ? props.title : "Attachments"}
                <Grid container style={{ marginTop: "10px", border: "1px solid #C4C4C4", borderRadius: "6px", padding: "5px" }}>
                    {/* Upload file here */}
                    <Box tabIndex={0} sx={{ display: 'flex', alignItems: 'center' }}>
                        <div className="d-inline p-1">
                            <Button
                                variant="contained"
                                component="label"
                                disabled={loading}
                                style={{ textTransform: 'none', fontWeight: 'bolder', font: "normal normal normal 16px/24px Franklin Gothic Medium", opacity : props.isDisabled ? 0.5 : 1 }}
                                size="small"
                                color="primary">
                                Choose File
                                <input type="file" hidden disabled={props.isDisabled} onChange={handleFileChange} />
                            </Button>
                        </div>

                        {isFilePicked ? (
                            <>
                                <div className="d-inline p-1">
                                    <Box sx={{ m: 1, position: 'relative' }}>
                                        <Fab
                                            aria-label="upload"
                                            color="primary"
                                            // sx={buttonSx}
                                            size="large"
                                            disabled={loading}
                                            onClick={handleUploadClick}
                                        >
                                            <UploadIcon />
                                        </Fab>
                                        {loading && (
                                            <CircularProgress
                                                size={68}
                                                sx={{
                                                    color: amber[500],
                                                    position: 'absolute',
                                                    top: -6,
                                                    left: -6,
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </Box>
                                </div>
                            </>) : (<>  </>)
                        }
                    </Box>

                    <div className="p-1 m-1 fw-light fst-italic w-100">
                        {isFilePicked ? (
                            <>
                                <div style={{ font: "normal normal normal 14px/16px Franklin Gothic Medium", color: '#7F8284' }}>{file && `${file.name}`}</div>
                            </>

                        ) : (
                            <>
                                <div style={{ font: "normal normal normal 14px/16px Franklin Gothic Medium", color: '#7F8284' }}>Choose a file</div>
                            </>

                        )}
                    </div>

                </Grid>
                {
                    (attachments && attachments.length > 0) && (
                        <Grid container style={{ marginTop: "10px", border: "1px solid #C4C4C4", borderRadius: "6px", padding: "5px" }}>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Uploaded By</th>
                                        <th>Uploaded On</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Row goes here */}
                                    {attachments ? attachments.map((attachment: any, i: string) => (
                                        <AttachmentRow attachment={attachment} containerName={props.containerName} recordId={props.recordId} setAttachment={setAttachments} isDisabled={props.isDisabled} />
                                    )) : null}
                                </tbody>
                            </table>
                        </Grid>
                    ) 
                }
            </Grid>
        </>
    );
}

export default AttachmentList;