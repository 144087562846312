import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../../../client-basic-info";
import ClientContactInfo from "../../../client-contact-info";
import Select from 'react-select';
import appconfig from '../../../appConfig'
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import { useValidation } from "../../../hooks/useValidation";
import { CreateAgencyRequest, GetAgencyRequestById, UpdateAgencyRequest, GetAgencyRequestMetaData, DeleteAgencyRequestById } from "../../../services/agency-request-service";
import { GetUserDetailsByRole } from "../../../services/graph-service";
import { GetCourtRooms } from "../../../services/court-room-service";
import { GetClientDetailNotes } from "../../../services/note-service";

import * as Constants from "../../../helper/constants";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../auth/authConfig";
import AttachmentList from '../../attachments/components/attachment-list';
import NoteTable from "../../../notes";
import SubHeader from "../../../components/SubHeader";
import ScreenSideBar from "../../../components/ScreenSideBar";
import StyledDialog from '../../../components/Dialog';
import { Grid, MenuItem } from "@mui/material";
import TextField from "../../../components/TextField";
import CustomDatePicker from "../../../components/DatePicker";
import SelectorTextField from "../../../components/SelectorTextField";
import Backdrop from '../../../components/elements/Loader';
import { toast } from "react-toastify";
import _ from "lodash";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FetchLookupData } from "../../../services/client-file.service";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";


function AgencyRequest() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const currentAccount = instance.getActiveAccount();
    const [isValid] = useValidation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [agencyRequestId, setAgencyRequestId] = useState(searchParams.get("agencyrequestid"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [pageId, setPageId] = useState(searchParams.get("page"));
    const [hasAllEditAfterClosedPermission, setHasAllEditAfterClosedPermission] = useState("");
    const [userId, setUserId] = useState("");

    const [docketNumber, setDocketNumber] = useState("");
    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [showDate, setShowDate] = useState("");
    const [courtLocation, setCourtLocation] = useState("");
    const [courtRoom, setCourtRoom] = useState("");


    const [instructionsText, setInstructionsText] = useState("");
    const [outcomeStatus, setOutcomeStatus] = useState("");
    const [completionNoteText, setCompletionNoteText] = useState("");
    const [requestor, setRequestor] = useState("");
    const [nextCourtDate, setNextCourtDate] = useState("");
    const [showNextCourtDate, setShowNextCourtDate] = useState("");

    const [requestorError, setRequestorError] = useState(false);
    const [isReadOnlyDetail, setIsReadOnlyDetail] = useState(false);
    const [isReadOnlyOutcomeDetail, setIsReadOnlyOutcomeDetail] = useState(false);

    const [canChangeAssignedTo, setCanChangeAssignedTo] = useState(true);
    const [canChangeRequestor, setCanChangeRequestor] = useState(false);

    const [contactMethods, setContactMethods] = useState([]);

    const [docketNumberError, setDocketNumberError] = useState(false);
    const [docketMaxLengthError, setDocketMaxLengthError] = useState(false);
    const [upcomingCourtDateError, setUpcomingCourtDateError] = useState(false);
    const [isUpcomingCourtDateInvalid, setIsUpcomingCourtDateInvalid] = useState(false);
    const [upcomingCourtDateCurrentDateError, setUpcomingCourtDateCurrentDateError] = useState(false);
    const [nextCourtDateError, setNextCourtDateError] = useState(false);
    const [isNextCourtDateInvalid, setIsNextCourtDateInvalid] = useState(false);
    const [nextCourtDateCurrentDateError, setNextCourtDateCurrentDateError] = useState(false);
    const [requestorAndAssignedToSameError, setRequestorAndAssignedToSameError] = useState(false);

    const [saveClicked, setSaveClicked] = useState(false);
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [courtRoomError, setCourtRoomError] = useState(false);
    const [instructionsTypeError, setInstructionsTypeError] = useState(false);
    const [instructionsMaxLengthError, setInstructionsMaxLengthError] = useState(false);
    const [courtLocations, setCourtLocations] = useState([]);
    const [courtRooms, setCourtRooms] = useState([]);
    const [allCourtRoom, setAllCourtRooms] = useState([]);
    const [courtRoomsLocationMapping, setCourtRoomsLocationMapping] = useState([]);
    const [outcomeStatuses, setOutcomeStatuses] = useState([]);

    const [disableNoteCreate, setDisableNoteCreate] = useState(false);

    const [assignedOn, setAssignedOn] = useState("");
    const [originalAssignedTo, setOriginalAssignedTo] = useState("");
    const [assignedTo, setAssignedTo] = useState("");
    const [assignedToError, setAssignedToError] = useState(false);
    const [assignedToDetails, setAssignedToDetails] = useState([]);
    const [requestorDetails, setRequestorDetails] = useState([]);

    const SELECTINDEX = "Select..";
    const NAVIGATEURL = "/client-file?id=";
    const apiServerUrl = appconfig.API_SERVER_URL;

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();

    const [clientDetailNotes, setClientDetailNotes] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const screenSideBarList = ['Client Basic Information', 'Client Contact Information', 'Agency Request Information'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);

    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [pagecount, setPagecount] = useState(searchParams.get("pagecount"));

    const [topmyfilesduedate, setTopmyfilesduedate] = useState(searchParams.get("topmyfilesduedate"));
    const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate"));
    const [topassignedfilesduedate, setTopassignedfilesduedate] = useState(searchParams.get("topassignedfilesduedate"));
    const [myfilesduedate, setMyfilesduedate] = useState(searchParams.get("myfilesduedate"));
    const [filestobeassignedduedate, setFilestobeassignedduedate] = useState(searchParams.get("filestobeassignedduedate"));
    const [assignedfilesduedate, setAssignedfilesduedate] = useState(searchParams.get("assignedfilesduedate"));
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);
    const [createdOn, setCreatedOn] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [trackEvents, setTrackEvents] = useState("");
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Agency Request : ", trackEvents);

    // #region PageLoad
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents]);

    useEffect(() => {
        if (currentAccount) {
            setUserId(currentAccount.localAccountId);
        }
        const fetchClientDetailNotes = async (token) => {
            await GetClientDetailNotes(agencyRequestId, 4, 'false', token).then((data) => {
                setClientDetailNotes(data);
            });
        }

        if (agencyRequestId != null && agencyRequestId > 0) {

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        fetchClientDetailNotes(response.accessToken);
                    }
                });
            }
        }

        const fetchInitialData = async (token) => {

            const metaData = await GetAgencyRequestMetaData(token);

            if (metaData) {
                setCourtLocations(metaData.courtLocations);
                setAllCourtRooms(metaData.courtRooms);
                setOutcomeStatuses(metaData.outcomeStatuses);
                setCourtRoomsLocationMapping(metaData.courtRoomAndLocationMapping);
                setRequestorDetails(metaData.requestorUsers);
            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
                appInsights.trackException({ error: new Error('An error occurred while loading data.'), severityLevel: SeverityLevel.Error });
                //alert("An error occurred while loading data.");
            }

            await GetUserDetailsByRole(token).then((data) => {

                const currentAccount = instance.getActiveAccount();

                setAssignedToDetails(data);
                setRequestorPermissions(metaData.requestorUsers);
                setCanChangeRequestor((currentAccount.idTokenClaims.roles[0].toString() == 'LegalAdministrativeAssisstant') ? true : false);
            });
            if (agencyRequestId && agencyRequestId > 0) {
                var data = await GetAgencyRequestById(agencyRequestId, token);
                setInitialAgencyRequest(data, metaData.courtLocations, metaData.courtRooms, metaData.courtRoomAndLocationMapping);
                setTrackEvents(JSON.stringify(data));
                appInsights.trackTrace({ message: JSON.stringify(data), severityLevel: SeverityLevel.Information });
            }

        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                    await FetchLookupData(response.accessToken).then((data) => {
                        if (data) setContactMethods(data.contactMethods);
                    });
                }
            }).then(() => { setIsDataReady(true) });
        }

    }, [account, instance, isAuthenticated]);

    function FindRoomsBasedOnLocation(locationId, courtRoomsLocationMapping, allCourtRoom) {
        setCourtRooms(null);
        let courtRoomsNeedsToAdd = [];
        const selectedCourtRooms = [];
        if (locationId > 0)
            courtRoomsNeedsToAdd = _.filter(courtRoomsLocationMapping, { 'value': locationId.toString() });

        allCourtRoom.forEach(element => {
            let courtRoom = _.find(courtRoomsNeedsToAdd, { 'label': element.value.toString() });
            if (courtRoom) selectedCourtRooms.push(element);
        });

        setCourtRooms(selectedCourtRooms);
    }

    const setInitialAgencyRequest = (agencyRequest, courtLocationList, courtRoomList, courtRoomLocatiionMap) => {
        setDocketNumber(agencyRequest.docketNumber);
        setUpcomingCourtDate(Date.parse(agencyRequest.upcomingCourtDate));
        setShowDate(new Date(agencyRequest.upcomingCourtDate).toLocaleDateString());
        if (agencyRequest.assignedTo)
            setAssignedTo(JSON.parse(agencyRequest.assignedTo));

        setOriginalAssignedTo(agencyRequest.assignedTo != null ? JSON.parse(agencyRequest.assignedTo) : "")
        setAssignedOn(agencyRequest.assignedOn != null ? agencyRequest.assignedOn : "")

        setRequestor(JSON.parse(agencyRequest.requestor));
        setCreatedOn(agencyRequest.createdOn);
        setCreatedBy(agencyRequest.createdBy);
        if (agencyRequest.courtLocationId > 0) {
            let selectedCourtLocation = _.filter(courtLocationList, { 'value': agencyRequest.courtLocationId.toString() });
            setCourtLocation(selectedCourtLocation[0]);

            FindRoomsBasedOnLocation(agencyRequest.courtLocationId, courtRoomLocatiionMap, courtRoomList);
        }
        if (agencyRequest.courtRoomId > 0) {
            let selectedCourtRoom = _.filter(courtRoomList, { 'value': agencyRequest.courtRoomId.toString() });
            setCourtRoom(selectedCourtRoom[0]);
        }

        setInstructionsText(agencyRequest.instructions);
        if (agencyRequest.outcomeStatus == 1) {
            setOutcomeStatus({ id: 1, label: 'Adjourned' });
        } else if (agencyRequest.outcomeStatus == 2) {
            setOutcomeStatus({ id: 2, label: 'Resolved' });
        } else if (agencyRequest.outcomeStatus == 3) {
            setOutcomeStatus({ id: 3, label: 'Went to Warrant' });
        }

        if (agencyRequest.nextCourtDate)
            setNextCourtDate(agencyRequest.nextCourtDate != null ? Date.parse(agencyRequest.nextCourtDate) : null);
        setShowNextCourtDate(agencyRequest.nextCourtDate != null ? new Date(agencyRequest.nextCourtDate).toLocaleDateString() : null);
        //#region SetPermissions
        var hasAllEditAfterClosedPermission = false;
        var hasAllEditOpenPermission = false;
        var hasAllEditLawyerPermission = false;
        var hasAllEditReassignPermission = false;
        var hasEditOutcomeRoleDetails = false;

        const editClosedRoleDetails = appconfig.AGENCYREQUEST_EDIT_AFTER_CLOSED_ROLES.split(";");
        if (editClosedRoleDetails) {
            editClosedRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditAfterClosedPermission = true;
                }
            });
        }
        setHasAllEditAfterClosedPermission(hasAllEditAfterClosedPermission);

        const editOpenRoleDetails = appconfig.AGENCYREQUEST_EDIT_OPEN_ROLES.split(";");
        if (editOpenRoleDetails) {
            editOpenRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditOpenPermission = true;
                }
            });
        }

        const editReassignRoleDetails = appconfig.AGENCYREQUEST_EDIT_REASSIGN_ROLES.split(";");
        if (editReassignRoleDetails) {
            editReassignRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditReassignPermission = true;
                }
            });
        }

        const editEditLawyerRoleDetails = appconfig.AGENCYREQUEST_EDIT_LAWYER_ROLES.split(";");
        if (editEditLawyerRoleDetails) {
            editEditLawyerRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditLawyerPermission = true;
                }
            });
        }

        const editOutcomeRoleDetails = appconfig.AGENCYREQUEST_EDIT_OUTCOME_ROLES.split(";");
        if (editOutcomeRoleDetails) {
            editOutcomeRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasEditOutcomeRoleDetails = true;
                }
            });
        }

        setIsReadOnlyDetail(true);
        setIsReadOnlyOutcomeDetail(true);
        setCanChangeAssignedTo(false);
        setCanChangeRequestor(false);
        setDisableNoteCreate(true);

        //Lawyers
        if (hasAllEditLawyerPermission) {
            //if the logged in user is the assigned to
            if (agencyRequest.assignedTo && agencyRequestId && account.localAccountId == JSON.parse(agencyRequest.assignedTo).value) {
                setIsReadOnlyDetail(false);
                setCanChangeAssignedTo(false);
                setDisableNoteCreate(false);
            }
        }

        //If logged in user = requestor
        if (account.localAccountId == JSON.parse(agencyRequest.requestor).value) {
            setIsReadOnlyDetail(false);
            setCanChangeAssignedTo(true);
            setDisableNoteCreate(false);
        }

        if (agencyRequest && !agencyRequest.outcomeStatus) {
            //Assistants, LSM, DC Triage
            if (hasAllEditOpenPermission) {
                setIsReadOnlyDetail(false);
                setCanChangeAssignedTo(false);
                setDisableNoteCreate(false);
                setIsReadOnlyOutcomeDetail(false);
            }

            //LSM, Roster Lawyer, DC Triage
            if (hasAllEditReassignPermission) {
                setIsReadOnlyDetail(false);
                setCanChangeAssignedTo(true);
                setDisableNoteCreate(false);
            }


        }

        if (agencyRequest && agencyRequest.outcomeStatus && agencyRequest.outcomeStatus > 0) {

            setIsReadOnlyDetail(true);
            setIsReadOnlyOutcomeDetail(true);
            setCanChangeAssignedTo(false);
            setCanChangeRequestor(false);
            setDisableNoteCreate(true);

            if (hasAllEditAfterClosedPermission) {
                setIsReadOnlyDetail(false);
                setIsReadOnlyOutcomeDetail(false);
                setCanChangeAssignedTo(true);
                setDisableNoteCreate(false);
            }
        }

        if (agencyRequest.requestor && agencyRequestId && account.localAccountId == JSON.parse(agencyRequest.requestor).value) {
            setRequestorPermissions(true);
            setDisableNoteCreate(false);
        }

        if (hasEditOutcomeRoleDetails) {
            setIsReadOnlyOutcomeDetail(false);
            setDisableNoteCreate(false);
        }
        else {
            setIsReadOnlyOutcomeDetail(true);
        }
        //check if the logged in user is the assigned to
        if (agencyRequest.assignedTo && agencyRequestId && account.localAccountId == JSON.parse(agencyRequest.assignedTo).value) {
            setIsReadOnlyOutcomeDetail(false);
            setDisableNoteCreate(false);
            setCanChangeAssignedTo(true);
        }
        if (agencyRequest && agencyRequest.outcomeStatus && agencyRequest.outcomeStatus > 0 && agencyRequest.assignedTo && agencyRequest.assignedTo != null) {
            if ((currentAccount.idTokenClaims?.roles[0].toString() == 'DutyCounselTriageLead')) {
                setIsReadOnly(false);
            } else {
                setIsReadOnly(true);
            }
        }
    }

    const setRequestorPermissions = (data) => {
        if (!agencyRequestId) {
            //set requestor by userRole
            if (data) {
                data.forEach(element => {
                    if (element.value == account.localAccountId) {
                        setRequestor(element);
                        return;
                    }
                });
            }

        }
    }

    const createAgencyRequestObject = () => {
        var now = new Date();
        var updatedUpcomingCourtDate = new Date(upcomingCourtDate).setHours(0, 0, 0);
        var updatedNextCourtDate = new Date(nextCourtDate).setHours(0, 0, 0);
       
        var newAssignedOn =assignedOn;
        if(!assignedTo || assignedTo == '' )
        {
            newAssignedOn = null;
        }
        else if(assignedTo && originalAssignedTo.value != assignedTo.value){
            newAssignedOn = now;
        }

        const agencyRequest = {
            clientFileId: parseInt(clientFileId),
            docketNumber: docketNumber,
            upcomingCourtDate: new Date(updatedUpcomingCourtDate),
            courtLocationId: parseInt(courtLocation.value),
            courtRoomId: parseInt(courtRoom.value),
            instructions: instructionsText,
            Requestor: JSON.stringify(requestor),
            outcomeStatus: (outcomeStatus != null ? JSON.stringify(outcomeStatus).id : null),
            createdOn: createdOn && createdOn != "" ? new Date(createdOn) : now,
            createdBy: createdBy && createdBy != "" ? createdBy : "System",
            assignedTo: assignedTo ? JSON.stringify(assignedTo) : null,
            assignedOn: newAssignedOn? new Date(newAssignedOn): null,
            modifiedBy: "System",
            modifiedOn: now,
        }
        

        //Set the Assigned On after checking changes from backend
        if (agencyRequestId != null && agencyRequestId > 0) {
            agencyRequest.agencyRequestId = parseInt(agencyRequestId);
            agencyRequest.outcomeStatus = outcomeStatus?.id > 0 ? parseInt(outcomeStatus?.id) : null;
            if (outcomeStatus.id == 1) {
                agencyRequest.nextCourtDate = new Date(updatedNextCourtDate);
            }
            else {
                agencyRequest.nextCourtDate = null;
            }


        }

        return agencyRequest;
    }

    //#endregion

    //#region OnChangeEvents

    const onUpcomingCourtDateChanged = (date) => {
        setShowDate(date);
        if (date != null) {
            let today = new Date();
            if ((new Date(date)).toString() === "Invalid Date" || (new Date(date) < new Date(today.setDate(today.getDate() - 1)))) {
                setIsUpcomingCourtDateInvalid(true);
            } else {
                setUpcomingCourtDate(Date.parse(date));
                setUpcomingCourtDateError(false);
                setIsUpcomingCourtDateInvalid(false);
            }
        } else {
            setUpcomingCourtDate(date);
            setUpcomingCourtDateError(true);
            setIsUpcomingCourtDateInvalid(false);
        }
    };

    const onNextCourtDateChanged = (date) => {
        setShowNextCourtDate(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date" || (new Date(date) < new Date())) {
                setIsNextCourtDateInvalid(true);
            } else {
                setNextCourtDate(Date.parse(date));
                setNextCourtDateError(false);
                setIsNextCourtDateInvalid(false);
            }
        } else {
            setNextCourtDate(date);
            setNextCourtDateError(true);
            setIsNextCourtDateInvalid(false);
        }
    };

    const today = new Date();
    const minNextCourtDate = new Date(today.setDate(today.getDate() + 1));

    const onCourtLocationChanged = (event) => {
        const selectedCourtLocation = courtLocations.find(
            (object) => object?.label === event.target.value
        );
        setCourtLocation(selectedCourtLocation);

        let courtLocationBasedOnId = _.filter(courtLocations, { 'label': event.target.value.toString() });
        FindRoomsBasedOnLocation(courtLocationBasedOnId[0].value, courtRoomsLocationMapping, allCourtRoom);
        setCourtRoom(null);
        if (selectedCourtLocation)
            setCourtLocationError(false);
    };

    const onCourtRoomChanged = (event) => {
        const selectedCourtRoom = courtRooms.find(
            (object) => object?.label === event.target.value
        );
        setCourtRoom(selectedCourtRoom);
        if (selectedCourtRoom)
            setCourtRoomError(false);
    };

    const onAssignedToChanged = (event) => {
        const selectedAssignedTo = assignedToDetails.find(
            (object) => object?.label === event.target.value
        );
        setAssignedTo(selectedAssignedTo);
        if (selectedAssignedTo && selectedAssignedTo != "") setAssignedToError(false);
        if (requestor && event.target.value == requestor.label) {
            setRequestorAndAssignedToSameError(true);
        }
        else {
            setRequestorAndAssignedToSameError(false);
        }
    };

    const onRequestorChanged = (event) => {
        const selectedRequestor = requestorDetails.find(
            (object) => object?.label === event.target.value
        );
        setRequestor(selectedRequestor);
        if (event && event.value)
            setRequestorError(false);

        if (requestor && event.target.value == requestor.label) {
            setRequestorAndAssignedToSameError(true);
        }
        else {
            setRequestorAndAssignedToSameError(false);
        }
    };

    const onOutcomeStatusChanged = (event) => {
        const selectedOutcomeStatus = outcomeStatuses?.find(
            (object) => object?.label === event.target.value
        );
        setOutcomeStatus(selectedOutcomeStatus);
    };
    const onCompletionNoteTextChanged = (event) => {
        setCompletionNoteText(event.target.value);
    };
    const onDocketNumberChanged = (event) => {
        if (event.target.value.length < 101) {
            setDocketMaxLengthError(false);
            setDocketNumber(event.target.value);
            if (event.target.value.length == 0)
                setDocketNumberError(false);
            else {
                const nameFieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true, maxLength: 100 };
                setDocketNumberError(!isValid(nameFieldModal));
            }
        } else {
            setDocketMaxLengthError(true);
        }
    };
    const onInstructionsTextChanged = (event) => {
        if (event.target.value.length < 2501) {
            setInstructionsMaxLengthError(false);
            setInstructionsText(event.target.value);
            if (event.target.value.length == 0)
                setInstructionsTypeError(false);
            else {
                const noteFieldModal = { name: Constants.NOTE, value: event.target.value, required: true };
                setInstructionsTypeError(!isValid(noteFieldModal));
            }
        } else {
            setInstructionsMaxLengthError(true);
        }
    };

    //#endregion

    //#region ButtonClickEvents

    const onSaveClicked = async (option) => {

        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInformation();
            if (isValidData) {
                var agencyRequestDetails = createAgencyRequestObject();
                setTrackEvents(JSON.stringify(agencyRequestDetails));
                appInsights.trackTrace({ message: JSON.stringify(agencyRequestDetails), severityLevel: SeverityLevel.Information });
                if (agencyRequestId != null && agencyRequestId > 0) {
                    if ((outcomeStatus && outcomeStatus != "") && (!assignedTo || assignedTo == "") && (clientDetailNotes?.length != 0) && (currentAccount.idTokenClaims?.roles[0].toString() != 'DutyCounselTriageLead')) { setIsReadOnly(true); }
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async (response) => {
                            if (response) {
                                await UpdateAgencyRequest(agencyRequestDetails, response.accessToken).then((response) => {
                                    if(response)
                                    {
                                        setOriginalAssignedTo(agencyRequestDetails.assignedTo != null ? JSON.parse(agencyRequestDetails.assignedTo) : "")
                                        setAssignedOn(agencyRequestDetails.assignedOn != null ? agencyRequestDetails.assignedOn : "")
                                    }
                                    if (option == 1 && response) {
                                        navigateToPage();
                                    }
                                });

                            }
                        });
                    }
                }

                else {
                    setSaveClicked(true);
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async (response) => {
                            if (response) {
                                await CreateAgencyRequest(agencyRequestDetails, response.accessToken).then((response) => {
                                    if (option == 0 && response) {
                                        navigate(`${prepareNavigationParameters(Constants.AGENCYREQUEST, new Array(response, clientFileId), null)}`);

                                        window.location.reload();
                                    }
                                    else if (option == 1 && response) {
                                        navigateToPage();
                                    }
                                });


                            }
                        });
                    }
                }
            }

        }
        else {
            toast.error("Cannot create an agency request. Agency request needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
            // alert("Cannot create an agency request. Agency request needs to have an associated client file.");
            appInsights.trackException({ error: new Error('Cannot create an agency request. Agency request needs to have an associated client file.'), severityLevel: SeverityLevel.Error });
        }
    }

    const validateInformation = () => {
        var status = true;
        if (!docketNumber) { setDocketNumberError(true); status = false; }
        if (!upcomingCourtDate) { setUpcomingCourtDateError(true); status = false; }
        if (isUpcomingCourtDateInvalid) { status = false; }
        if (!courtLocation) { setCourtLocationError(true); status = false; }
        if (!requestor) { setRequestorError(true); status = false; }
        if (!courtRoom) { setCourtRoomError(true); status = false; }
        if (!instructionsText) { setInstructionsTypeError(true); status = false; }
        if (instructionsMaxLengthError) { status = false; }

        if (upcomingCourtDate) {
            const newDate = new Date(upcomingCourtDate);
            setUpcomingCourtDateCurrentDateError(false);

            if (!agencyRequestId && isInThePast(newDate)) {
                setUpcomingCourtDateCurrentDateError(true);
                status = false;
            }
        }

        if (outcomeStatus && outcomeStatus?.id == 1 && nextCourtDate) {
            if (nextCourtDateError || isNextCourtDateInvalid) { status = false; }
        }

        if (outcomeStatus && outcomeStatus?.id == 1 && !nextCourtDate) {
            setNextCourtDateError(true);
            status = false;
        }

        if (assignedTo && assignedTo?.label == requestor?.label) {
            setRequestorAndAssignedToSameError(true);
            status = false;
        }
        else {
            setRequestorAndAssignedToSameError(false);
        }
        if (outcomeStatus && outcomeStatus != "" && clientDetailNotes?.length == 0) {
            status = false;
            toast.error("At least one completion note is required.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
        if ((outcomeStatus && outcomeStatus != "") && (!assignedTo || assignedTo == "") && (clientDetailNotes?.length != 0)) { setAssignedToError(true); status = false; }
        return status;
    }

    function isInThePast(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    }

    const canSave = () => {
        var result = false;

        if (null != agencyRequestId && agencyRequestId > 0) {
            result = canUpdate("agency-request", (null != assignedTo) ? assignedTo.value : null);
        }

        return result;
    };

    const onDeleteClicked = async () => {
        if (agencyRequestId != null && agencyRequestId > 0) {
            setTrackEvents("Deleting Agency Request By Id : " + agencyRequestId.toString());
            appInsights.trackTrace({ message: "Deleting Agency Request By Id : " + agencyRequestId.toString(), severityLevel: SeverityLevel.Information });
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteAgencyRequestById(agencyRequestId, response.accessToken);
                    }
                });
            }
        }
        navigateToPage();
        window.location.reload();
    };

    const onCancelClicked = () => {

        navigateToPage();
    };

    function navigateToPage() {
        if (searchParams.get("backto") && backPage == Constants.INTERACRTION)
            navigate(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(searchParams.get("interactionId"), clientFileId), null, "page=" + searchParams.get("backto"))}`);
        else if (searchParams.get("backto") && backPage == Constants.JPBAILOUTCOME)
            navigate(`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(searchParams.get("jpBailOutcomeId"), clientFileId), null, "page=" + searchParams.get("backto"))}`);
        else if (searchParams.get("backto"))
            navigate(`${prepareNavigationParameters(null, null, null, "page=" + searchParams.get("backto"))}`);
        else if (backPage == Constants.INTERACRTION)
            navigate(`${prepareNavigationParameters(Constants.INTERACRTION, new Array(searchParams.get("interactionId"), clientFileId), null, "page=" + Constants.CLIENTFILEVIEWDETAILS)}`);
        else if (backPage == Constants.CLIENTFILEVIEWDETAILS)
            navigate(`${prepareNavigationParameters(null, null, null, "page=" + Constants.CLIENTFILEDASHBOARD)}`);
        else if (backPage == Constants.JPBAILOUTCOME)
            navigate(`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(searchParams.get("jpBailOutcomeId"), clientFileId), null, "page=" + Constants.CLIENTFILEVIEWDETAILS)}`);
        else
            navigate(`${prepareNavigationParameters(null, null, null)}`);
    }


    const canDeleteAgencyRequest = () => {
        const hasDelete = ((null != agencyRequestId) && (agencyRequestId > 0) && hasAllEditAfterClosedPermission);
        return hasDelete;
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    function Save() {

        onSaveClicked(0);
    }

    function SaveAndClose() {

        onSaveClicked(1);
    }


    //#endregion

    if (canRead("agency-request", null)) {
        if (isDataReady) {
            return (
                <>
                    <Grid container style={{ padding: "10px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                        {isAuthenticated && (
                            <>
                                <StyledDialog title="Delete Agency Request" open={openDeleteDialog} onClose={onDeleteClickClose}
                                    message={"Do you really want to delete the Agency Request : AR-" + agencyRequestId + " ? This process cannot be undone."}
                                    onConfirmed={onDeleteClicked} confirmationText="Delete" />
                                <SubHeader
                                    title="Agency Request Details"
                                    menuItemOneText="Delete Agency Request"
                                    onMenuItemOneClicked={onDeleteClickOpen}
                                    isMenuItemOneVisible={canDeleteAgencyRequest()}
                                    isMenuItemOneDisabled={false}
                                    menuItemTwoText=""
                                    onMenuItemTwoClicked=""
                                    isMenuItemTwoVisible={false}
                                    isMenuItemTwoDisabled={false}
                                    navItemOneText="Save"
                                    onNavItemOneClicked={Save}
                                    isNavItemOneVisible={!disableNoteCreate}
                                    isNavItemOneDisabled={saveClicked}

                                    navItemTwoText="Save & Close"
                                    onNavItemTwoClicked={SaveAndClose}
                                    isNavItemTwoVisible={!disableNoteCreate}
                                    isNavItemTwoDisabled={saveClicked}

                                    navItemThreeText="Cancel"
                                    onNavItemThreeClicked={onCancelClicked}
                                    isNavItemThreeVisible={true}
                                    isNavItemThreeDisabled={false}
                                    navItemFourText=""
                                    onNavItemFourClicked=""
                                    isNavItemFourVisible={false}
                                    isNavItemFourDisabled={false} />
                                {/* <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} /> */}


                                <Grid item xs={12} md={12}>
                                    <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}><ClientBasicInfo isReadOnly={true} clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={false} /></div>
                                    <div id="scrollByClickedItem2" style={{ scrollMarginTop: "161px" }}><ClientContactInfo isReadOnly={true} clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} /></div>
                                </Grid>
                                <div id="scrollByClickedItem3" style={{ scrollMarginTop: "161px", width: "100%" }}>


                                    <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", margin: "10px 0px 60px 0px", borderRadius: "8px", backgroundColor: isReadOnly ? "#ececec" : null }}>
                                        <Grid item xs={12} md={12} style={{
                                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                                        }}>Agency Request Information</Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <TextField label="Docket Number" placeholder="Docket Number" required={true}
                                                    multiline
                                                    error={docketMaxLengthError ? true : (docketNumberError ? true : null)}
                                                    value={docketNumber}
                                                    handleChange={onDocketNumberChanged}
                                                    disabled={isReadOnly || isReadOnlyDetail}
                                                    helperText={docketMaxLengthError ? "Max. 100 characters are allowed." : (docketNumberError ? Constants.DOCKETNUMBERREQUIREDMESSAGE : null)} /></div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <CustomDatePicker label="Upcoming Court Date" placeholder="Upcoming Court Date" required={true} disablePast={true}
                                                    error={isUpcomingCourtDateInvalid ? true : (upcomingCourtDateError ? true : null)}
                                                    value={showDate}
                                                    disabled={isReadOnly || isReadOnlyDetail}
                                                    handleOnChange={onUpcomingCourtDateChanged}
                                                    helperText={isUpcomingCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (upcomingCourtDateError ? Constants.UPCOMINGCOURTDATEREQUIREDMESSAGE : null)} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <SelectorTextField label="Court Location" placeholder="Court Location" required={true}
                                                select
                                                error={courtLocationError ? true : null}
                                                disabled={isReadOnly || isReadOnlyDetail}
                                                value={courtLocation ? courtLocation?.label : ""}
                                                handleChange={onCourtLocationChanged}
                                                helperText={courtLocationError ? Constants.COURTLOCATIONREQUIREDMESSAGE : null}
                                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                                {courtLocations.map((option) => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>))}
                                            </SelectorTextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <SelectorTextField label="Court Room" placeholder="Court Room" required={true}
                                                select
                                                error={courtRoomError ? true : null}
                                                disabled={isReadOnly || isReadOnlyDetail}
                                                value={courtRoom ? courtRoom?.label : ""}
                                                handleChange={onCourtRoomChanged}
                                                helperText={courtRoomError ? Constants.COURTROOMREQUIREDMESSAGE : null}
                                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                                {courtRooms.map((option) => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>))}
                                            </SelectorTextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <SelectorTextField label="Requestor" placeholder="Requestor" required={true} style={{ backgroundColor: canChangeRequestor ? "" : "#ececec" }}
                                                select
                                                error={requestorError ? true : null}
                                                disabled={isReadOnly || !canChangeRequestor}
                                                value={requestor ? requestor?.label : ""}
                                                handleChange={onRequestorChanged}
                                                helperText={requestorError ? Constants.REQUESTORREQUIREDMESSAGE : null}
                                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                                {requestorDetails.map((option) => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>))}
                                            </SelectorTextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <SelectorTextField label="Agency Assigned To" placeholder="Agency Assigned To"
                                                    select
                                                    error={requestorAndAssignedToSameError ? true : (assignedToError ? true : false)}
                                                    disabled={isReadOnly || !canChangeAssignedTo}
                                                    value={assignedTo ? assignedTo?.label : ""}
                                                    handleChange={onAssignedToChanged}
                                                    helperText={requestorAndAssignedToSameError ? Constants.REQUESTORASSIGNEDTONOTSAMEMESSAGE : (assignedToError ? "Agency Assigned To is required." : null)}
                                                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                                    {assignedToDetails.map((option) => (
                                                        <MenuItem key={option.value} value={option.label}>
                                                            {option.label}
                                                        </MenuItem>))}
                                                </SelectorTextField>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                            <TextField isNote={true} label="Instructions" placeholder="Instructions" multiline rows={8} sx={{ width: "98%" }} required={true}
                                                error={instructionsMaxLengthError ? true : (instructionsTypeError ? true : null)}
                                                value={instructionsText}
                                                disabled={isReadOnly || isReadOnlyDetail}
                                                handleChange={onInstructionsTextChanged}
                                                helperText={instructionsMaxLengthError ? "Max. 2500 characters are allowed." : (instructionsTypeError ? Constants.INSTRUCTIONSREQUIREDMESSAGE : null)} />
                                        </Grid>
                                        {(agencyRequestId != null && agencyRequestId > 0) && <>
                                            <Grid container style={{ padding: "5px 20px 15px 10px", border: "1px solid #7F8284", margin: "5px 0px 0px 0px", borderRadius: "8px" }}>
                                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium", letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "5px" }}>
                                                    Agency Request Completion
                                                </Grid>
                                                <Grid item xs={12} md={12} style={{ marginTop: "-5px" }}>
                                                    <NoteTable title="Completion Notes" hideBorder notes={clientDetailNotes} clientFileId={clientFileId} relatedId={agencyRequestId} noteTypeId={4} pageId={backPage} queryParams={''} isDisabled={disableNoteCreate || isReadOnly} />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 0px 0px" }}>
                                                    <SelectorTextField label="Outcome Status" placeholder="Outcome Status"
                                                        select
                                                        disabled={isReadOnly || isReadOnlyOutcomeDetail}
                                                        value={outcomeStatus ? outcomeStatus?.label : ""}
                                                        handleChange={onOutcomeStatusChanged}
                                                        SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                                        {outcomeStatuses.map((option) => (
                                                            <MenuItem key={option.id} value={option.label}>
                                                                {option.label}
                                                            </MenuItem>))}
                                                    </SelectorTextField>
                                                </Grid>
                                                {outcomeStatus?.id == "1" && (<>
                                                    <Grid item xs={12} sm={6} md={4} lg={4} style={{ margin: "10px 0px 0px 0px" }}>
                                                        <div style={{ maxWidth: "275px" }}>
                                                            <CustomDatePicker label="Next Court Date" placeholder="Next Court Date" required={true} minDate={minNextCourtDate}
                                                                error={isNextCourtDateInvalid ? true : (nextCourtDateError ? true : null)}
                                                                value={showNextCourtDate}
                                                                disabled={isReadOnly || isReadOnlyOutcomeDetail}
                                                                handleOnChange={onNextCourtDateChanged}
                                                                helperText={isNextCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (nextCourtDateError ? Constants.NEXTCOURTDATEREQUIREDMESSAGE : null)} />
                                                        </div>
                                                    </Grid>
                                                </>)}
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                                <AttachmentList containerName="agency-requests" recordId={agencyRequestId} isDisabled={disableNoteCreate || isReadOnly} />
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </>
                        )}
                    </Grid>
                </>
            )
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                    }}
                >
                    Agency Request
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                    }}
                >
                    ** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}



export default AgencyRequest;