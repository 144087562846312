import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "ReferralService";
const CREATEREQUESTNAME = "/CreateReferral";
const UPDATEREQUESTNAME = "/UpdateReferral";
const DELETEREQUESTNAME = "/DeleteReferral?id=";
const GETMETADATAREQUESTNAME = "/GetReferralLookupData";
const GETFORCLIENTIDREQUESTNAME = "/GetClientReferrals";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const CREATESUCCESS = "Referral successfully created.";
const CREATEFAILED ="Create Referral was not succesful.";
const UPDATESUCCESS = "Referral successfully updated.";
const UPDATEFAILED = "Update Referral Failed.";
const DELETESUCCESS = "Referral successfully deleted.";
const DELETEFAILED = "Delete Referral was not succesful.";

export async function GetReferralById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/GetReferral?id="+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
};

export async function CreateReferral(referral, token) {
    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(referral)
    }
    
    var referralId = "";
    var result = await fetch(serviceUrl, options).then(async response => {
        referralId = await response.json();
        if (response.ok) {
            toast.success(CREATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
        } else {
            throw new Error('Something went wrong ...');
        }
    })
    .then()
    .catch((error) => {
        toast.error(CREATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //alert("Create Referral was not succesful.")
    });

    return  referralId;
};

export async function UpdateReferral(referral, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + UPDATEREQUESTNAME ;  

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(referral)
    }   

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(UPDATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(UPDATESUCCESS);
            return response.json();
        } else {
            toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(UPDATEFAILED);
        }
    }).catch(error => { toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(UPDATEFAILED); 
    //console.log(error); 
});

    return result;
};

export async function DeleteReferral(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + DELETEREQUESTNAME + id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if (response.ok) {
                toast.success(DELETESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
                //alert(DELETESUCCESS)
                return data;
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                toast.error(DELETEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
                //alert(DELETEFAILED)
                return Promise.reject(error);
            }
             
        })
      .catch(error => {
            //console.error('Error occurred.', error);
        });
};

export async function GetReferralLookupData( token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETMETADATAREQUESTNAME;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {             
            var data = await response.json();           
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
};

export async function GetAllClientReferrals(clientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETFORCLIENTIDREQUESTNAME + "?clientFileId=" + clientFileId + '&onlyLastTwoYears=true';   
    
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
};