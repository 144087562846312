import  React, {useState} from 'react';
import dayjs from 'dayjs';
//import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import {DateTimePickerTextField, LabelWrappedDateTimePickerTextField} from "./styled/DateTimePicker.styled";
import { RiInformationFill } from "react-icons/ri";


export default function CustomDateTimePicker({
    ampm,
    label,
    disableFuture,
    disablePast,
    value,
    handleOnChange,
    required,
    disabled,
    placeholder,
    error,
    isInfoVisible,
    infoMessage,
    helperText,
    isLabelWrapped
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack style={{width: "300px"}} direction="row" spacing={2}>
      {isLabelWrapped == true ? <DateTimePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled} 
        label={label}
        value={value}
        openTo="day"
        views={['year','month','day','hours','minutes']}
        onChange={handleOnChange}
        autoComplete='off'
        inputFormat="DD-MMM-YYYY HH:mm"
        ampm={ampm}
        renderInput={(params) => <LabelWrappedDateTimePickerTextField {...params} required={required} disabled={disabled} error={error} placeholder={placeholder} helperText={helperText} size="small" autoComplete='off' />}
        /> :
        <DateTimePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled} 
        label={label}
        value={value}
        openTo="day"
        views={['year', 'month', 'day', 'hours', 'minutes']}
        onChange={handleOnChange}
        autoComplete='off'
        inputFormat="DD-MMM-YYYY HH:mm"
        ampm={ampm}
        renderInput={(params) => <DateTimePickerTextField {...params} required={required} disabled={disabled} error={error} placeholder={placeholder} helperText={helperText} size="small" autoComplete='off'/>}
        />}
      {isInfoVisible && <RiInformationFill title={infoMessage} size="21px" color="lightblue" /> }
    </Stack>  
  </LocalizationProvider>
);
}