import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import DataTable from '../components/DataTable';
import StyledButton from '../components/elements/StyledButton';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { Button } from "react-bootstrap";
import { useNavigationParameters } from "../hooks/useNavigationParameters";
import * as Constants from "../helper/constants";

export default function ServiceDecision(props) {
    const navigate = useNavigate();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();

    const columnHeaders = [{ field: 'number', width: 180, headerName: 'Number', editable: false },
    { field: 'status', width: 150, headerName: 'Status', editable: false },
    { field: 'stage', width: 180, headerName: 'Stage', editable: false },
    { field: 'eligibleForRepresentation', width: 200, headerName: 'Eligible For Representation', editable: false },
    { field: 'areaofLaw', width: 120, headerName: 'Area of Law', editable: false },
    { field: 'serviceType', width: 180, headerName: 'Service Type', editable: false },
    { field: 'createdDdate', type: 'date', width: 150, headerName: 'Created Date', renderDate: true, editable: false },
    { field: 'specialization', width: 150, headerName: 'Specialization', editable: false },
    { field: 'matterType', width: 120, headerName: 'Matter Type', editable: false },
    { field: 'viewServiceDecision', width: 180, headerName: 'View Service Decision', renderButton: true, editable: false },
    { field: 'id', width: 180, headerName: 'Service Decision Id', editable: false, hide: true }
    ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => ((columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={() => {
                    navigate(`${prepareNavigationParameters(Constants.SERVICEDECISION, new Array(props.clientFileId, params.row.id), null, "page="+Constants.CLIENTFILEVIEWDETAILS)}`);
                }}>View Details</StyledButton> :
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null))),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    const rows = [];
    if (props.serviceDecisions && props.serviceDecisions.length > 0) {
        props.serviceDecisions.forEach((serviceDecision) => {
            rows.push(
                {
                    id: serviceDecision.id,
                    number: serviceDecision.serviceDecisionNumber,
                    stage: serviceDecision.stage,
                    status: serviceDecision.status,
                    eligibleForRepresentation: serviceDecision.eligibleForRepresentation,
                    areaofLaw: serviceDecision.areaOfLaw,
                    serviceType: serviceDecision.serviceType,
                    createdDdate: serviceDecision.createdOn != null ? serviceDecision.createdOn : null,
                    specialization: serviceDecision.specialization,
                    matterType: serviceDecision.matterType,
                },
            );
        });
    };

    return (
        <Grid container style={{ padding: "10px 20px 20px 20px" }}>
            {!props.hideHeader && <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
            }}>{props.title}
            </Grid>}
            <DataTable columns={columns} rows={rows} dataGridtitle={props.dataGridtitle ? props.dataGridtitle : ""} />
        </Grid>
    );

};