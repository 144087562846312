import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {IoClose}  from "react-icons/io5";
import Typography from '@mui/material/Typography';
import CustomButton from './Button';
import Stack from '@mui/material/Stack';
import {device} from '../utils/deviceBreakpoints';

function StyledDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, 
      [`@media ${device.mobileS}`] : {
        font: 'normal normal normal 17px/21px Franklin Gothic Medium'
      },
      [`@media ${device.mobileM}`] : {
        font: 'normal normal normal 17px/21px Franklin Gothic Medium'
      },
      [`@media ${device.mobileL}`] : {
        font: 'normal normal normal 17px/21px Franklin Gothic Medium'
      },
      [`@media ${device.tablet}`]  :{
        font: 'normal normal normal 18px/22px Franklin Gothic Medium'
      },
      [`@media ${device.laptop}`] : {
        font: 'normal normal normal 20px/25px Franklin Gothic Medium'
      }  
    }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

StyledDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default function StyledDialog({
    open,
    onClose,
    title,
    message,
    onConfirmed,
    confirmationText,
    ...props
}) {
  

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={props.maxWidth ? props.maxWidth : "sm"}
        fullWidth={true}
      >
        <StyledDialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </StyledDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom sx={{
            [`@media ${device.mobileS}`] : {
            fontSize: "0.8rem", lineHeight: "1.2"
          },
          [`@media ${device.mobileM}`] : {
            fontSize: "0.8rem", lineHeight: "1.2"
          },
          [`@media ${device.mobileL}`] : {
            fontSize: "0.8rem", lineHeight: "1.2"
          },
          [`@media ${device.tablet}`]  :{
            fontSize: "0.98rem", lineHeight: "1.3"
          },
          [`@media ${device.laptop}`] : {
            fontSize: "1rem", lineHeight: "1.5"
          } 
          }}>
          {message}
          </Typography>
          {props.secondMessage && (<Typography gutterBottom style={{marginTop: "15px"}}>
          {props.secondMessage}
          </Typography>)}
        </DialogContent>
        <Stack direction="row-reverse" spacing={0}>
        {props.isCancelRequired === true && <DialogActions>
          <CustomButton variant="contained" label="Cancel" size="small"  autoFocus onClick={onClose}/>
        </DialogActions>}
        <DialogActions>
          <CustomButton variant="contained" label={confirmationText} size="small"  autoFocus onClick={onConfirmed}/>
        </DialogActions>
        </Stack>
      </Dialog>
    </div>
  );
}