import styled from "styled-components";

export const PageNotFoundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
`;

export const NotFoundContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 30%;
  font-family: 'Montserrat', sans-serif;
  opacity: 1;
  transform: translate(-50%, -50%);
  font-size: 44px;
  font-weight: 900;
  margin: 0px;
  color: #D41F44;
  text-transform: uppercase;
  letter-spacing: 0px;
}
`;

export const TextContainer = styled.div`
position: absolute;
left: 25%;
right:25%;
top: 30%;
margin-top: 30px;
font-family: Franklin Gothic Medium;
font-size: 22px;
font-weight: 700;
text-transform: uppercase;
color: #D41F44;
}
`;

export const AccesDeniedTextContainer = styled.div`
position: absolute;
left: 25%;
right:25%;
top: 30%;
margin-top: 30px;
font-family: Franklin Gothic Medium;
font-size: 20px;
font-weight: 700;
color: #D41F44;
}
`;