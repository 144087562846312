import React, { useEffect, useState, useRef } from 'react';
import appconfig from '../../../appConfig'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetConflictDashboardCounts } from '../../../services/crm-conflict-dashboard.service'
import { tokenRequest } from "../../../auth/authConfig";
import TopCrmConflictedClientFiles from '../top-crm-conflicted-client-files';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from '../../../hooks/useAuthorization';
import { Grid } from '@mui/material';
import InfoCard from '../../../components/Card';
import BackDrop from '../../../components/elements/Loader';
import TopDcpConflictedClientFiles from '../top-dcp-conflicted-client-files';
import TopConflictedInteractions from '../top-conflicted-interactions';
import {device} from '../../../utils/deviceBreakpoints';
import { GetCrmStatus } from "../../../services/crm-status-service";

function ConflictDashboard() {

  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  
  const [dcpConflictedClientFileCount, setDcpConflictedClientFileCount] = useState(0);
  const [crmConflictedClientFileCount, setCrmConflictedClientFileCount] = useState(0);
  const [conflictedInteractionsCount, setConflictedInteractionsCount] = useState(0);

  const [isDcpConflictedClientFile, setIsDcpConflictedClientFile] = useState(false);
  const [isCrmConflictedClientFile, setIsCrmConflictedClientFile] = useState(false);
  const [isCrmConflictedInteraction, setIsCrmConflictedInteraction] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardKey, setCardKey] = useState(searchParams.get("cardKey") ? searchParams.get("cardKey") : "1");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isCRMDown, setIsCRMDown] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      LoadInitialData();
    }
  }, [account, instance]);

  useEffect(() => {
    function handleSelect(card1: boolean, card2: boolean, card3: boolean) {
      setIsDcpConflictedClientFile(card1);
      setIsCrmConflictedClientFile(card2);
      setIsCrmConflictedInteraction(card3);
    };
    if (cardKey == "1") {
      handleSelect(true, false, false);
    } else if (cardKey == "2") {
      handleSelect(false, true, false);
    }
    else if (cardKey == "3") {
      handleSelect(false, false, true);
    }
  }, [cardKey]);

  function LoadInitialData() {
    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then((response) => {
        if (response) {
          setInitialTopCountData(response.accessToken).then(async () => {
          })
          fetchCrmStatus(response.accessToken);
        }
      });
    }
  }

  const setInitialTopCountData = async (token: string) => {

    await GetConflictDashboardCounts(token).then((countDetails) => {
      countDetails.forEach((element: any) => {
        if (element.label == "CrmConflictedClientFileCount") {
          setCrmConflictedClientFileCount(element.value);
        }
        else if (element.label == "ConflictedInteractionsCount") {
          setConflictedInteractionsCount(element.value)
        }
        else if (element.label == "DcpConflictedClientFileCount") {
          setDcpConflictedClientFileCount(element.value)
        }
      });
    }).then(() => {setIsLoading(false)});

  }

  const fetchCrmStatus= async (token : string) => {
    await GetCrmStatus(token).then((status) => {
      if (status.isPlannedOutage == true || status.crmStatus == 0) {
        setIsCRMDown(true);
      } else {
        setIsCRMDown(false);
      }      
    });
  }


  if (canRead("crm-conflict-dashboard", null)) {
    return (<>
      {isLoading ? <BackDrop /> :
        <>
          {isAuthenticated && (canRead("crm-conflict-dashboard", null)) && (
            <Grid container direction="row" alignItems="flex-start" style={{
              padding: "0px 20px 0px 20px",
              background: "#F0F0F0 0% 0% no-repeat padding-box"
            }} >
              <Grid item xs={12} md={12} sx={{textAlign: "left", letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 20px 10px",
              [`@media ${device.mobileS}`] : {
                font: "normal normal bold 17px/19px Franklin Gothic Medium"
              },
              [`@media ${device.mobileM}`] : {
                font: "normal normal bold 19px/22px Franklin Gothic Medium"
              },
              [`@media ${device.mobileL}`] : {
                font: "normal normal bold 22px/25px Franklin Gothic Medium"
              },
              [`@media ${device.tablet}`]  :{
                font: "normal normal bold 26px/29px Franklin Gothic Medium"
              },
              [`@media ${device.laptop}`] : {
                font: "normal normal bold 32px/37px Franklin Gothic Medium"
              }
              }}>Review Dashboards</Grid>

              <Grid item xs={12} sm={6} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="1" title="Duplicate Portal Files" subtitle="" count={dcpConflictedClientFileCount} isClicked={isDcpConflictedClientFile} />
              </Grid>

              {!isCRMDown && <Grid item xs={12} sm={6} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="2" title="CRM-Portal Linking" subtitle="" count={crmConflictedClientFileCount} isClicked={isCrmConflictedClientFile} />
              </Grid>}

              <Grid item xs={12} sm={6} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="3" title="Interactions" subtitle="" count={conflictedInteractionsCount} isClicked={isCrmConflictedInteraction} />
              </Grid>
              {cardKey === "1" &&
                <>
                  <Grid item xs={12} md={12} sx={{textAlign: "left", letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px",
                    [`@media ${device.mobileS}`] : {
                      font: "normal normal bold 15px/17px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileM}`] : {
                      font: "normal normal bold 16px/19px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileL}`] : {
                      font: "normal normal bold 18px/21px Franklin Gothic Medium"
                    },
                    [`@media ${device.tablet}`]  :{
                      font: "normal normal bold 20px/23px Franklin Gothic Medium"
                    },
                    [`@media ${device.laptop}`] : {
                      font: "normal normal bold 24px/29px Franklin Gothic Medium"
                    }
                  }}>Duplicate Portal Files to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopDcpConflictedClientFiles totalCount={dcpConflictedClientFileCount}></TopDcpConflictedClientFiles> </Grid>
                </>}
              {cardKey === "2" && !isCRMDown &&
                <>
                  <Grid item xs={12} md={12} sx={{textAlign: "left", letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px",
                    [`@media ${device.mobileS}`] : {
                      font: "normal normal bold 15px/17px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileM}`] : {
                      font: "normal normal bold 16px/19px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileL}`] : {
                      font: "normal normal bold 18px/21px Franklin Gothic Medium"
                    },
                    [`@media ${device.tablet}`]  :{
                      font: "normal normal bold 20px/23px Franklin Gothic Medium"
                    },
                    [`@media ${device.laptop}`] : {
                      font: "normal normal bold 24px/29px Franklin Gothic Medium"
                    }
                  }}>CRM-Portal Linkings to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopCrmConflictedClientFiles totalCount={crmConflictedClientFileCount}></TopCrmConflictedClientFiles> </Grid>
                </>}
              {cardKey === "3" &&
                <>
                  <Grid item xs={12} md={12} sx={{textAlign: "left", letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px",
                    [`@media ${device.mobileS}`] : {
                      font: "normal normal bold 15px/17px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileM}`] : {
                      font: "normal normal bold 16px/19px Franklin Gothic Medium"
                    },
                    [`@media ${device.mobileL}`] : {
                      font: "normal normal bold 18px/21px Franklin Gothic Medium"
                    },
                    [`@media ${device.tablet}`]  :{
                      font: "normal normal bold 20px/23px Franklin Gothic Medium"
                    },
                    [`@media ${device.laptop}`] : {
                      font: "normal normal bold 24px/29px Franklin Gothic Medium"
                    }
                  }}>Interactions to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopConflictedInteractions totalCount={conflictedInteractionsCount}></TopConflictedInteractions> </Grid>
                </>}
              <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
            </Grid>)
          }

        </>}
    </>);
  } else {
    return (
      <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
          <Grid item xs={12} md={12} style={{
              textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
              letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
          }}>Review Dashboards
          </Grid>
          <Grid item xs={12} md={12} style={{
              textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
              letterSpacing: "0px", color: " #000000", opacity: "1",
          }}>** You are not authorized to view these details **
          </Grid>
      </Grid>
  );
  }


}

export default ConflictDashboard;