import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { RetrieveConflictedClientFiles } from "../../../services/crm-conflict-dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import Backdrop from '../../../components/elements/Loader';
import StyledButton from '../../../components/elements/StyledButton';
import * as Constants from "../../../helper/constants";
import { format } from 'date-fns';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";

function TopCrmConflictedClientFiles(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [showViewAll, setShowViewAll] = useState(true);
    const crmConflictResolutionPage = Constants.CRMCONFLICTRESOLUTIONPAGEURL + "?page=topcrmconflictedclientfiles";
    const navigate = useNavigate();
    const [isDataReady, setIsDataReady] = useState(false);

    // #region PageLoad
    useEffect(() => {
        LoadInitialData();
    }, [instance]);

    function LoadInitialData() {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken);
                }
            });
        }
    }
    function GetSearchBy() {
        const firstNameSearch = { columnName: "FirstName", searchValue: '' };
        const middleNameSearch = { columnName: "MiddleName", searchValue: '' };
        const lastNameSearch = { columnName: "LastName", searchValue: '' };
        const dateOfBirthSearch = { columnName: "DateOfBirth", searchValue: "" };

        return [firstNameSearch, middleNameSearch, lastNameSearch, dateOfBirthSearch];
    }
    const setInitialGridData = async (token) => {

        await RetrieveConflictedClientFiles(token, GetSearchBy(), 0, 5).then((rowData) => {
            setInitialGridColumns();
            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        }).then(() => {setIsDataReady(true)});
    }

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 90, field: 'id', editable: false },
        { headerName: 'First Name', width: 250, field: 'firstName', editable: false },
        { headerName: 'Middle Name', width: 250, field: 'middleName', editable: false },
        { headerName: 'Last Name', width: 250, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 150, field: 'dateOfBirth', renderDate: true, editable: false },
        { headerName: 'Review Client File', width: 180, field: 'button', renderButton: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => ((columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={(event) => {
                        navigate(`${prepareNavigationParameters(Constants.RESOLVECRMCONFLICT, new Array(params.row.id.split("-")[1]), null)}&page=${Constants.TOPCRMREVIEWDASHBOARD}`);
                    }}>Review</StyledButton> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null))),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }

    //#endregion  


    if (canRead("crm-conflict-dashboard", null)) { //ToDo - Change this role
        if (isDataReady) {
            return (<>
                <DataTable columns={gridColumns} rows={gridRows} totalCount={props.totalCount} />
                {showViewAll && <div align="center" style={{ marginTop: "10px" }}><StyledButton variant={"outlined"} color="error" onClick={() => navigate(`${prepareNavigationParameters(Constants.CRMREVIEWDASHBOARD, null, null)}`)} >View All</StyledButton></div>}
            </>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>CRM Portal Linking
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view these details **
                </Grid>
            </Grid>
        );
    }
}

export default TopCrmConflictedClientFiles;