export const EMAIL = "EMAIL";
export const PHONENUMBER = "PHONENUMBER";
export const NAME = "NAME";
export const DOCKETNUMBER = "DOCKETNUMBER";
export const NOTE = "NOTE";
export const OTHER = "OTHER";

//#region ValidationMessages

export const DOCKETNUMBERREQUIREDMESSAGE = "Docket Number is required.";
export const UPCOMINGCOURTDATEREQUIREDMESSAGE ="Upcoming Court Date is required.";
export const UPCOMINGCOURTDATEINFUTUREMESSAGE ="Upcoming Court Date must be in the future or a current date.";
export const COURTLOCATIONREQUIREDMESSAGE ="Court Location is required.";
export const COURTROOMREQUIREDMESSAGE ="Court Room is required.";
export const INSTRUCTIONSREQUIREDMESSAGE ="Instructions are required.";
export const REQUESTORREQUIREDMESSAGE = "Requestor is required.";
export const REQUESTORASSIGNEDTONOTSAMEMESSAGE ="Requestor and Assigned To cannot be the same person.";
export const NEXTCOURTDATEREQUIREDMESSAGE = "Next Court Date  is required.";
export const NEXTCOURTDATEINFUTUREMESSAGE ="Next Court Date must be in the future or a current date.";
export const DUTYCONSELTYPEREQUIREDMESSAGE = "Duty Counsel Type is required."

//#endregion

//#region NavigationDetails

//navigation level 1
export const TOPMYFILES="topmyfiles";
export const TOPASSIGNEDFILES="topassignedfiles";
export const TOPFILESTOASSIGN="topfilestobeassigned";
export const SEARCHCLIENTS="searchresults";
export const TOPDCPREVIEWDASHBOARD="topdcprdb";
export const TOPCRMREVIEWDASHBOARD="topcrmrdb";
export const TOPINTREVIEWDASHBOARD="topintrdb";

export const DASHBOARDPAGEURL ="/";
export const SEARCHRESULTSPAGEURL ="/clientfiles/search";
export const REVIEWDASBOARDPAGEURL = "/reviewdashboard";

//navigation level 2
export const MYFILES="myfiles";
export const ASSIGNEDFILES="assignedfiles";
export const FILESTOASSIGN="filestobeassigned";
export const DCPREVIEWDASHBOARD="dcprdb";
export const CRMREVIEWDASHBOARD="crmrdb";
export const INTREVIEWDASHBOARD="intrdb";

export const MYFILESPAGEURL ="/myfiles";
export const FILESTOBEASSIGNEDPAGEURL ="/filestobeassigned";
export const ASSIGNEDFILESPAGEURL ="/assignedfiles";
export const DCPCONFLICTEDCLIENTFILESPAGEURL = "/reviewdashboard/dcpclientfiles";
export const CRMCONFLICTDASHBOARDPAGEURL = "/reviewdashboard/crmclientfiles";
export const INERACTIONCCONFLICTEDFILESPAGEURL = "/reviewdashboard/interactions";

//navigation level 3
export const CLIENTFILEDASHBOARD="clientfiledashboard";
export const RESOLVEDCPCONFLICTS="redcpcon";
export const RESOLVECRMCONFLICT="recrmcon";
export const RESOLVEINTERACTIONCONFLICT="reintcon";

export const CLIENTFILEDASHBOARDPAGEURL ="/client-dashboard";
export const  DCPCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvedcpconflicts";
export const  CRMCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvecrmconflicts";
export const  INERACTIONCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvedinteractionconflicts";

//navigation level 4
export const CLIENTFILEVIEWDETAILS="clientfile";

export const CLIENTFILEVIEWDETAILSPAGEURL = "/client-file";

//navigation level 5

export const JPBAILOUTCOME="jp";
export const INTERACRTION="interaction";
export const NOTEDETAILS="note";
export const REFFERRAL="ref";
export const AGENCYREQUEST="ar";
export const SERVICEDECISION="sd";
export const CLIENTGATEWAY="cg";

export const JPBAILOUTCOMEPAGEURL = "/jpbailoutcome";
export const INTERACTIONPAGEURL = "/interaction";
export const NOTEDETAILSPAGEURL = "/note";
export const REFERRALDETAILSPAGEURL = "/referral";
export const AGENCYREQUESTDETAILSPAGEURL = "/agencyrequest";
export const SERVICEDECISIONDETAILSPAGEURL = "/service-decision";
export const CLIENTGATEWAYPAGEURL = "/client-gateway";

//#endregion

//#region QUERYPARAMETERS

export const PAGEQUERYSTRINGPARAM = "page";
export const IDQUERYSTRINGPARAM = "id";
export const CLIENTFILEIDQUERYSTRINGPARAM ="clientFileId";
export const AGENCYREQUESTIDQUERYSTRINGPARAM ="agencyrequestid";
export const CLIENTGATEWAYIDQUERYSTRINGPARAM ="clientgatewayid";
export const RELATEDIDQUERYSTRINGPARAM ="relatedId";
export const NOTETYPEIDQUERYSTRINGPARAM ="noteTypeId";
export const INTERACTIONIDQUERYSTRINGPARAM ="interactionId";
export const SIMPLECLIENTFILEIDQUERYSTRINGPARAM = "clientfileid";
export const TOPMYFILESDUEDATEQUERYSTRINGPARAM = "topmyfilesduedate";
export const TOPFILESTOBEASSIGNEDDUEDATEQUERYSTRINGPARAM = "topfilestobeassignedduedate";
export const TOASSIGNEDFILESDUEDATEQUERYSTRINGPARAM = "topassignedfilesduedate";
export const JOBAILOUTCOMEIDQUERYSTRINGPARAM ="jpBailOutcomeId";
export const CRMIDQUERYSTRINGPARAM = "crmId";
//#endregion