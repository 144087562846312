import { Button } from "@mui/material";
import styled from "styled-components";

const ButtonStyle = styled(Button)`
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  text-transform: none !important;
`;

const CustomButtonStyle = styled(Button)`
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
  margin-left: 0px;
  margin-top: 10px;
  margin-right: 0px;
  text-transform: none !important;
`;

const ButtonText = styled.div`
  white-space: nowrap;
  margin-right: 5px;
  margin-left: 5px;
  text-align: left;
  font: normal normal bold 14px/17px Franklin Gothic Medium;
  letter-spacing: 0px;
  color: #d41f44;
  opacity: 1;
`;

const CustomButtonText = styled.div`
  white-space: nowrap;
  margin-right: 0px;
  margin-left: 0px;
  padding : 0px -5px 0px -5px;
  text-align: left;
  font: normal normal bold 13px/15px Franklin Gothic Medium;
  letter-spacing: 0px;
  color: #d41f44;
  opacity: 1;
`;

const IconHolder = styled.div`
font: normal normal bold 14px/17px Franklin Gothic Medium;
  margin-right: 2px;
  margin-left: 2px;
`;

export default function StyledButton({
  variant,
  color,
  iconLeft,
  iconRight,
  label,
  disabled,
  isViewDetails,
  ...props
}) {
  return (
    (isViewDetails ? 
      <CustomButtonStyle
      variant={variant}
      color={color}
      disabled={disabled}
      {...props}
      style={{padding: "4px 5px 4px 5px"}}
    >
      <CustomButtonText style={{color: disabled? "#585454" :"#d41f44"}}>{label || props.children}</CustomButtonText>
    </CustomButtonStyle> :
    <ButtonStyle
      variant={variant}
      color={color}
      disabled={disabled}
      {...props}
    >
      <IconHolder>{iconLeft}</IconHolder>
      <ButtonText style={{color: disabled? "#585454" :"#d41f44"}}>{label || props.children}</ButtonText>
      <IconHolder>{iconRight}</IconHolder>
    </ButtonStyle>)
  );
};


