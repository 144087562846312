import styled from "styled-components";

export const SideBarContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 0px;
  width: 50px;
  height: 100vh;
  background: ${props => props.initial ? "#F0F0F0 0% 0% no-repeat padding-box" : "#7F8284 0% 0% no-repeat padding-box"};
  box-shadow: ${props => props.initial ? "0px 0px 0px #00000029" : "0px 3px 6px #00000029"};
  opacity: 1;
  z-index: ${props => props.initial ? 0 : 51};
`;

export const SideBarItem = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  padding-top: 10px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  opacity: 0.5;
  &:hover {
    cursor: pointer;
    opacity: 1 !important;
  }
`;