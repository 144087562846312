import React, { useEffect, useState, useRef } from 'react';
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthorization } from "../../../hooks/useAuthorization";
import {Grid} from '@mui/material';
import DataTable from '../../../components/DataTable';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";
import * as Constants from "../../../helper/constants";

function JPBailOutcomeList(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters,  removeDuplicatedNavigationParameters , prepareNavigationParameters] = useNavigationParameters();
    const navigate = useNavigate();
    const columnHeaders =[  {field:'id', width: 90, headerName:'ID', renderCell: true, editable: false, hide: true }, 
                            {field: 'DocketNumber', width: 150, headerName:'Docket Number', editable: false }, 
                            {field: 'TimeofShift', width: 110, headerName:'Time of Shift', editable: false}, 
                            {field: 'Detachment', width: 250, headerName:'Detachment', editable: false}, 
                            {field: 'HasDelayed', width: 110, headerName:'Has Delayed', editable: false}, 
                            {field: 'HearingConducted', width: 150, headerName:'Hearing Conducted', editable: false}, 
                            {field: 'HearingResults', width: 320, headerName:'Hearing Results', editable: false}];

    const rows = [];
    if (props.jpBailOutcomes && props.jpBailOutcomes.length > 0) {
        props.jpBailOutcomes.forEach((jpBailOutcome) => {
            // if (canRead("jp-bail-outcome", null)) {
            rows.push(
                {   id: `JPBO-${jpBailOutcome.jpBailOutcomeId}`, 
                    DocketNumber: jpBailOutcome.docketNumber,
                    TimeofShift: jpBailOutcome.timeOfShift ? jpBailOutcome.timeOfShift.label: null, 
                    Detachment: jpBailOutcome.detachment ? jpBailOutcome.detachment.label: null,
                    HasDelayed: jpBailOutcome.matterDelayedOrExceed24Hrs ? "Yes": "No",
                    HearingConducted:jpBailOutcome.wasAHearingReachedOrConducted ? "Yes": "No",
                    HearingResults: jpBailOutcome.hearingResult ? jpBailOutcome.hearingResult.label : "",
                },
            );
        // } else {
        //     return (<table><tbody><tr><td colSpan={7}>You are not authorized to read this record.</td></tr></tbody></table>);
        // };
        });
    };
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {   field: columnHeader.field, 
                renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                renderCell: columnHeader.renderCell ? (params) =>  ( <a href={`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(params.value.split("-")[1],props.clientFileId) , null, "page=clientfile")}`}>{params.value}</a>) : null,
                width: columnHeader.width,
                editable : columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });
    
    const onRowClicked = (item) => {
        navigate(`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(item.row.id.split("-")[1],props.clientFileId) , null, "page=clientfile")}`); 
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px", backgroundColor: (canCreate("jp-bail-outcome", null)) ? null : "#ececec" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
                }}>
                    {props.title}
                    {(canCreate("jp-bail-outcome", null)) ? (
                        <Button
                            style={{font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44" , position: "absolute", right: "40px"}}
                            size="sm"
                            variant={(canCreate("jp-bail-outcome", null)) ? "primary" : "secondary"}
                            disabled={(canCreate("jp-bail-outcome", null)) ? "" : "disabled"}
                            className="ml-auto"
                            href={`${prepareNavigationParameters(Constants.JPBAILOUTCOME, new Array(null,props.clientFileId) , null, "page=clientfile")}`}
                        >
                            New JP Bail Outcome
                        </Button>
                    ) : null}
                </Grid>
                <Grid>

                </Grid>
                <DataTable columns={columns} rows={rows} onRowDoubleClick={(canCreate("jp-bail-outcome", null)) ? onRowClicked : undefined} style={{ backgroundColor: (canCreate("jp-bail-outcome", null)) ? null : "#ececec" }}/>
            </Grid>
        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium", letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px" }} >
                    {props.title}
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}> ** Save the new Client File to add new JP Bail Outcome **
                </Grid>            
            </Grid>
        );
    }
};

export default JPBailOutcomeList;
