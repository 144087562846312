import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/clientgateway";
const GETMETADATAREQUESTNAME = "/metadata";

const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const CREATEFAILED = 'Create Client Gateway Failed.';
const CREATESUCCESS = "Client Gateway is successfully created.";
const CREATEREQUESTNAME = "/create";

export async function GetClientGatewayMetaData(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETMETADATAREQUESTNAME;
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}


export async function CreateClientGatewayRequest(clientgateway, token) {
    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(clientgateway)
    }

    var result = await fetch(serviceUrl, options).then(async (response) => {

        if (response.ok) {
            toast.success(CREATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
        } else {
            toast.error(CREATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        }
        return await response.json();
    }).catch(error => {
        toast.error(CREATEFAILED, { position: "top-center", autoClose: 5000, theme: "colored" });
        //console.log(error);
    });
return result;

}

export async function GetClientGatewayById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + id;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.error('Error occurred.', error);
        });
}

export async function GetAllClientGateways(clientFileId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "?clientFileId=" + clientFileId + '&onlyLastTwoYears=true';

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.log(error);
        });
}


export async function GetClientGatewayFormSources(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        //toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + "api/clientgatewayformsources";

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();
            if (response.ok) return data
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            //console.log(error);
        });
}